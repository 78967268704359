import {
  enforceLowercase_parse,
  validateDisplayGroupName,
} from '../common_validations';

import { default_experience_key } from '../../../../constants';

export default ({
  arms,
  initialValues,
  nodeSlugs,
  experience = default_experience_key,
}) => {
  return [
    [
      {
        name: `content`,
        input: [
          {
            name: `dashboard_heading_settings`,
            label: `Dashboard`,
            interface: `settings_heading`,
          },
          {
            name: `study_name`,
            label: `Displayed Project Name`,
            description: `This appears on the landing page, under the logo, and throughout the Participant Experience.`,
            interface: `text_horizontal`,
          },
          {
            name: `dashboard_header`,
            label: `Dashboard Header`,
            description: `Rename the dashboard, typically this is "Project Dashboard"`,
            interface: `text_horizontal`,
          },
          {
            name: 'default_display_group_name',
            label: 'Default Display Group Name',
            description: 'Rename the default display group on the dashboard.',
            interface: 'text_horizontal',
            defaultValue: 'My Activities',
          },
          {
            name: 'completed_display_group_name',
            label: 'Completed Display Group Name',
            description:
              'Rename the display group for completed contributions on the dashboard.',
            interface: 'text_horizontal',
            defaultValue: 'Completed Activities',
          },
          {
            name: `show_informed_consent_download_link`,
            label: 'Informed Consent Link',
            description: `Allows participants to download the informed consent release forms. Disable in cases where the Informed Consent is not needed.`,
            interface: 'toggle_horizontal',
          },
          {
            name: 'allow_email_login',
            label: 'Allow Email Login',
            interface: 'toggle_horizontal',
          },
          {
            name: 'allow_phone_login',
            label: 'Allow Phone Login',
            interface: 'toggle_horizontal',
          },
          {
            name: `enrollment_appx_time`,
            label: `Approximate Time to Enroll (Minutes)`,
            description: `Approximate time it takes progress through the screening process. Is displayed on the progress bar.`,
            interface: `number_horizontal`,
          },
          {
            name: `progress_display`,
            label: `Display progress`,
            description: `Do not display progress, display only descriptive text, display progress bar and descriptive text`,
            interface: `select_horizontal`,
            options: ['None', 'Text', 'Visual'],
          },
          {
            name: `connected_apps_header`,
            label: `Connected Apps Header`,
            description: `Displays in the participants Account Settings. Default is "Connected Apps"`,
            interface: `text_horizontal`,
          },
          {
            name: `connected_apps_description`,
            label: `Connected Apps Description`,
            description: `Displays beneath the Connected Apps Header and describes this section in the Participants Account Settings.`,
            interface: `textarea_horizontal`,
          },
          {
            name: `enrollment_closed_content`,
            label: `Enrollment Closed Message`,
            description: `This message is displayed on the Education Page when the status of project is set to "Enrollment Closed".`,
            interface: `textarea_horizontal`,
          },
          {
            name: `study_closed_content`,
            label: `Project Closed Message`,
            description: `This message is displayed on the Education Page when the status of project is set to "Completed".`,
            interface: `textarea_horizontal`,
          },
          {
            name: `study_withdrawal_heading_settings`,
            label: `Project Withdrawal`,
            interface: `settings_heading`,
          },
          {
            name: `self_withdrawal_allowed`,
            label: `Allow participants to self withdraw`,
            interface: `toggle_horizontal`,
          },
          {
            name: `dashboard_withdrawal_label`,
            label: `Dashboard Withdrawal Label`,
            interface: `text_horizontal`,
            description: `This is the label for the withdrawal process.`,
          },
          {
            name: `dashboard_withdrawal_link`,
            label: `Dashboard Withdrawal Link Label`,
            interface: `text_horizontal`,
            description: `The label for the link that submits the form.`,
          },
          {
            name: `dashboard_withdrawal_modal_header`,
            label: `Dashboard Withdrawal Modal Header`,
            interface: `text_horizontal`,
            description: `This displays as the header of the withdrawl modal.`,
          },
          {
            name: `dashboard_withdrawal_modal_paragragh_1`,
            label: `Dashboard Withdrawal Modal Paragraph 1`,
            interface: `textarea_horizontal`,
            description: `This is the first paragraph in the withdrawal modal.`,
          },
          {
            name: `dashboard_withdrawal_modal_reason`,
            label: `Dashboard Withdrawal Modal Reason`,
            interface: `text_horizontal`,
            description: `This displays over the "Reason" field in the withdraw modal.`,
          },
          {
            name: `dashboard_withdrawal_modal_paragragh_2`,
            label: `Dashboard Withdrawal Modal Paragraph 2`,
            interface: `textarea_horizontal`,
            description: `This is the second paragraph of the withdrawl modal and displays beneath the withdrawl link.`,
          },
          {
            name: `study_withdrawal_completion_settings`,
            label: `Project Completion`,
            interface: `settings_heading`,
          },
          {
            label: `Project Completion Message`,
            interface: `text_horizontal`,
            name: `study_completion_message`,
            description: `This text displays when the project is completed.`,
          },
          {
            name: `participant_events_heading_settings`,
            label: `Participant Events`,
            interface: `settings_heading`,
          },

          {
            label: `Enrollment Completed Event`,
            interface: `text_horizontal`,
            name: `enrollment_completed_event`,
            description: `This event indicates to the project that a participant is completed enrollment.`,
          },

          {
            label: `Project Completed Event`,
            interface: `text_horizontal`,
            name: `study_completed_event`,
            description: `This event indicates to the project that a participant has completed the project.`,
          },

          {
            name: `withdrawing_event`,
            label: `Alternative Route Withdrawing Event`,
            interface: `text_horizontal`,
            description: `This event triggers the withdrawl Alternative Route.`,
            placeholder: `Example: opt_out_validated`,
            parse: enforceLowercase_parse,
          },
          {
            name: `withdrawn_event`,
            label: `Alternative Route Withdrawn Event`,
            interface: `text_horizontal`,
            description: `This event indicates to the project that the participant has finished withdrawal.`,
            placeholder: `Example: withdraw_steps_completed`,
            parse: enforceLowercase_parse,
          },
          // Display Groups section
          {
            name: 'display_groups_heading_settings',
            label: 'Display Groups',
            interface: 'settings_heading',
            description: 'Group activities on Dashboard.',
          },
          {
            className: 'displayGroups',
            name: 'display_groups',
            interface: 'array',
            addButtonLabel: 'Add Display Group',
            draggable: true,
            isOrdered: true,
            items: [
              {
                className: 'displayGroup name',
                interface: 'text',
                name: 'name',
                label: 'Please enter your display group name',
                validate: [validateDisplayGroupName],
                required: true,
              },
              {
                className: 'displayGroup layout',
                interface: 'select',
                name: 'layout',
                label: 'Please select the display group layout',
                options: [
                  {
                    label: '1 Column',
                    value: '1-column',
                  },
                  {
                    label: '2 Columns',
                    value: '2-columns',
                  },
                ],
                defaultValue: '1-column',
                omitPlaceholder: true,
              },
              {
                name: 'enable_progress_tracker_panel',
                label: 'Display Progress Indicator',
                interface: 'checkbox',
              },
            ],
          },
          {
            name: 'reconsent_modal_settings',
            label: 'Re-consent Modal',
            interface: 'settings_heading',
          },
          {
            name: 'reconsent_modal_title',
            label: 'Title',
            interface: 'rte',
          },
          {
            name: 'reconsent_modal_body',
            label: 'Body content',
            interface: 'rte',
          },
        ],
      },
    ],
  ];
};
