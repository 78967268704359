import { connect } from 'react-redux';
import { loadGraph } from '../../../../actions/combination';
import { Button } from '@evidation/form-elements';
import { apiGraph } from '../../../../api';
import { toastr } from 'react-redux-toastr';
import { logError } from '../../../../utils/errorLogger';
import type { DuplicateReminderProps } from './types';
import { getRelatedReminderId } from 'src/utils/reminders';
import { RootState } from 'src/reducers';

export const text = {
  duplicate: 'Duplicate',
  error: 'There was a problem duplicating the reminder.',
  success: 'Reminder successfully duplicated.',
};

const DuplicateReminder: React.FC<DuplicateReminderProps> = ({
  selectedReminder,
  tiles,
  graphId,
  toggleLoader,
  loadGraph,
}) => {
  const { sequence_uuid, reminder_uuid, uuid: tileId } = selectedReminder;

  const handleClick = async () => {
    try {
      toggleLoader.showLoader();
      const delayId = getRelatedReminderId({
        reminderId: reminder_uuid,
        tiles,
      });

      await apiGraph.reminders.duplicateReminder({
        graphId,
        sequenceId: sequence_uuid,
        tileId,
        reminderId: reminder_uuid,
        delayId,
      });
      toastr.success(text.success, '');
      await loadGraph(graphId);
    } catch (error: any) {
      toastr.error(text.error, '');
      logError(error);
    } finally {
      toggleLoader.hideLoader();
    }
  };

  return (
    <Button id="duplicate-reminder" onClick={handleClick}>
      {text.duplicate}
    </Button>
  );
};

export default connect(
  ({ tiles }: RootState) => ({
    tiles,
  }),
  { loadGraph: loadGraph as any },
)(DuplicateReminder);
