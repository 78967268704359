import {
  max_threshold,
  more_than_value,
} from '../../../compensation/CompensationLanguageParser';

import { BLACKLIST_COMPENSATION_STUDY_SLUGS } from '../../../compensation/constants';
import _ from 'lodash';
import { graphTranslationFields } from '../../utils/translationFieldConstants';
import { modifyTranslatedFields } from '../common_outgoing';

const comp_values_parsed_as_numbers = [
  `amount`,
  `max_amount`,
  `unit_amount`,
  `max_threshold`,
  `min_threshold`,
];

const comm_comp_defaults = {
  name: '',
  currency: 'usd',
  visibility_depends_on: [],
  earned_depends_on: [],
  payment_depends_on: [],
};

const parseAsNumber = (key, value) => {
  if (_.includes(comp_values_parsed_as_numbers, key)) {
    // ensuring numbers should be parsed as numbers
    return parseInt(value, 10);
  }

  return value;
};

const compen_comp_defaults = {
  completion: (obj) =>
    _.defaults(obj, {
      ...comm_comp_defaults,
      strategy: 'completion',
      amount: 0,
    }),
  per_instance: (obj) =>
    _.defaults(obj, {
      ...comm_comp_defaults,
      strategy: 'per_instance',
      unit_amount: 0,
      max_amount: 0,
    }),
  bucketed: (obj) =>
    _.defaults(obj, {
      ...comm_comp_defaults,
      strategy: 'bucketed',
      max_amount: 0,
      buckets: [],
    }),
};

const comm_comp_config = [
  'name',
  'slug',
  'currency',
  'strategy',
  'enforce_uniqueness',
  'visibility_depends_on',
  'earned_depends_on',
  'payment_depends_on',
  'tooltip',
];

const comp_config_allowed_properties = {
  completion: [...comm_comp_config, `amount`],
  per_instance: [...comm_comp_config, `max_amount`, `unit_amount`],
  bucketed: [...comm_comp_config, `max_amount`, `buckets`],
};

const generate_external_id_config = (client) => {
  switch (client.external_id_format) {
    case `prefix_range`:
      return {
        type: client.type,
        name: client.name,
        external_id_format: client.external_id_format,
        external_id_prefix: client.external_id_prefix,
        external_id_prefix_range_start: client.external_id_prefix_range_start,
        external_id_prefix_range_end: client.external_id_prefix_range_end,
      };
    case `prefix_random`:
      return {
        type: client.type,
        name: client.name,
        external_id_format: client.external_id_format,
        external_id_prefix: client.external_id_prefix,
        external_id_prefix_n_random_digits:
          client.external_id_prefix_n_random_digits,
      };
    default:
      return {
        type: client.type,
        name: client.name,
        external_id_format: client.external_id_format,
      };
  }
};

const GraphOutgoing = ({ primaryLocale, currentLocale, ...props }) => {
  // Manage Translation content before submission
  if (primaryLocale && currentLocale && currentLocale !== primaryLocale) {
    props.content = modifyTranslatedFields(
      currentLocale,
      props.content,
      graphTranslationFields,
    );
    props['translation_locale'] = currentLocale;
  }

  // Recurring Jobs
  if (_.has(props.content, 'recurring_jobs')) {
    props.content.recurring_jobs = _.reduce(
      props.content.recurring_jobs,
      (acc, { tolerance, termination_value, recurrence_interval, ...rest }) => {
        return [
          ...acc,
          {
            tolerance: parseInt(tolerance, 10),
            recurrence_interval: parseInt(recurrence_interval, 10),
            termination_value: parseInt(termination_value, 10),
            ...rest,
          },
        ];
      },
      [],
    );
  }

  // EVENT DELAY
  if (_.has(props.content, 'event_delay_config')) {
    props.content.event_delay_config = _.reduce(
      props.content.event_delay_config,
      (acc, { node_slug, ...rest }) => ({
        ...acc,
        [node_slug]: { ...rest },
      }),
      {},
    );
  }

  // DQ Criteria
  if (_.has(props.content, 'disqualification_criteria')) {
    props.content.disqualification_criteria = _.reduce(
      props.content.disqualification_criteria,
      (acc, criterion) => {
        criterion['disqualification_criteria'].forEach((strategy) => {
          acc.push({
            attribute: criterion['attribute'],
            ...strategy,
          });
        });
        return acc;
      },
      [],
    );
  }

  // SITE MAPPING
  if (_.has(props.content, 'site_mapping')) {
    props.content.site_mapping = _.reduce(
      props.content.site_mapping,
      (acc, { site_id, site_prefixes, ...rest }) => {
        site_prefixes = site_prefixes.map((prefix_obj) => {
          return {
            prefix: prefix_obj['prefix'].replace('_', ''),
          };
        });
        return {
          ...acc,
          [site_id]: { site_prefixes, ...rest },
        };
      },
      {},
    );
  }

  if (props.content?.shipping_config) {
    props.content.shipping_config = props.content.shipping_config.map(
      (config) => {
        if (config?.client?.toLowerCase() === '- please select -') {
          delete config.client;
        }
        return config;
      },
    );
  }

  // SETTINGS
  // if there's a way to clear conditional field values from CP then this could be removed
  if (_.has(props.content, 'external_clients')) {
    props.content.external_clients = _.map(
      props.content.external_clients,
      generate_external_id_config,
    );
  }

  // PRODUCTION SUPPORT
  if (_.has(props.content, 'production_support_updateable_fields')) {
    // currently permit production support and owner have the same updateable fields
    props.content.owner_updateable_fields =
      props.content.production_support_updateable_fields;
  }

  //LANGUAGES
  //mapping the secondary_locales to a flat array of strings for backend's ingestion.

  if (props?.secondary_locales) {
    props.secondary_locales = props.secondary_locales.reduce(
      (acc, current_item) => {
        // current_item's shape -> {secondary_locale_value: "en-GB"} ,
        // `secondary_locale_value` is from `study-manager/src/routes/edit/forms/graph/languages.js`
        acc.push(Object.values(current_item)?.[0]);
        return acc;
      },
      [],
    );
  }

  // ENROLLMENT CAP
  // ensuring these are always integers
  if (_.has(props.content, 'enrollment_cap')) {
    props.content.enrollment_cap = parseInt(props.content.enrollment_cap, 10);
  }

  if (_.has(props.content, 'new_participant_enrollment_cap')) {
    props.content.new_participant_enrollment_cap = parseInt(
      props.content.new_participant_enrollment_cap,
      10,
    );
  }

  if (
    // !! Preventing studies that are blacklisted
    // !! from compensation data being transformed
    // !! Mike C - June 22, 2018
    !_.includes(BLACKLIST_COMPENSATION_STUDY_SLUGS, props.content.slug) &&
    // !!!
    _.has(props.content, 'compensation_config') &&
    !_.isEmpty(props.content.compensation_config)
  ) {
    props.content.compensation_config = _.reduce(
      props.content.compensation_config,
      (acc, { strategy = ``, ...event }) => {
        // ensuring we have sane defaults
        const event_defaults = compen_comp_defaults[strategy](event);

        // picking only fields that this type should have access to
        const selected_event_fields = _.pick(
          event_defaults,
          comp_config_allowed_properties[strategy],
        );

        // parsing each field for numbers
        const parsed_event_fields = _.reduce(
          selected_event_fields,
          (acc, value, key) => {
            value = parseAsNumber(key, value);

            if (_.isEqual(key, `buckets`)) {
              // looping over all buckets
              value = _.reduce(
                value,
                (acc, bucket) => {
                  if (_.isEqual(bucket.option, more_than_value)) {
                    // conditionally removing max_threshold
                    _.unset(bucket, max_threshold);
                  }

                  return [
                    ...acc,

                    // Looping over bucketed values to ensure they are numbers
                    _.reduce(
                      bucket,
                      (acc, value, key) => {
                        value = parseAsNumber(key, value);

                        return {
                          ...acc,
                          [key]: value,
                        };
                      },
                      {},
                    ),
                  ];
                },
                [],
              );
            }

            return {
              ...acc,
              [key]: value,
            };
          },
          {},
        );

        return [...acc, parsed_event_fields];
      },
      [],
    );
  }

  // Authenitication Billboard
  if (
    !props.content.authentication_provider ||
    _.toLower(props.content.authentication_provider) === `- please select -`
  ) {
    props.content.authentication_provider = `Achievement`;
  }
  props.authentication_provider = props.content.authentication_provider;
  if (props.content.identity_providers) {
    props.identity_providers = props.content.identity_providers;
  }
  // Convert expirations to hours to seconds
  if (props?.content?.email_update_link_expiration) {
    props.content.email_update_link_expiration =
      Number(props.content.email_update_link_expiration) * 3600;
  }

  // Authenticate customer_service_provider
  if (
    !props.content?.customer_service_provider ||
    props.content?.customer_service_provider?.toLowerCase() ===
      `- please select -`
  ) {
    props.content.customer_service_provider = 'Zendesk';
  }
  _.unset(props, 'content.identity_providers');
  _.unset(props, `content.brand_name`);
  _.unset(props, `content.authentication_provider`);
  _.unset(props, `content.terms_conditions`);
  _.unset(props, `content.privacy_policy`);

  if (
    props?.content.participant_explorer_event_slugs &&
    props.content.participant_explorer_event_slugs.length
  ) {
    const { participant_explorer_event_slugs: p } = props.content;
    props.content.participant_explorer_event_slugs = p.map(
      ({ slug, status }) => `${slug}_${status}`,
    );
  }

  if (props.content?.participant_default_inactivity_timeout) {
    // Convert from hours to seconds
    props.content.participant_default_inactivity_timeout =
      props.content.participant_default_inactivity_timeout * 60 * 60;
  }

  if (props?.content?.display_groups?.length > 0) {
    const checkDefaults = props.content.display_groups.map((group) => {
      const enable_progress_tracker_panel =
        typeof group['enable_progress_tracker_panel'] === 'boolean'
          ? group['enable_progress_tracker_panel']
          : false;
      const layout = group['layout'] || '1-column';
      return { ...group, enable_progress_tracker_panel, layout };
    });
    props.content.display_groups = checkDefaults;
  }

  if (typeof props.content?.max_identical_addresses !== 'undefined') {
    props.content.max_identical_addresses = parseInt(
      props.content.max_identical_addresses,
      10,
    );
  }

  return props;
};

export default GraphOutgoing;
