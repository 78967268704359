import { useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Modal from '../../../../components/modal/SimpleModal';

import LoadingIndicator from '../../../../components/LoadingIndicator';
import { graphQueries } from '../../../../api';
import { toastr } from 'react-redux-toastr';
import { useQuery } from 'react-query';
import { queryStores } from '../../../../constants';
import { e2eTestId } from '../../../../utils/e2eTesting';
import { useForm, Controller } from 'react-hook-form';
import { Input, Button, Chip, Flex } from '@evidation/ui';
import { get } from 'lodash';
import RichTextEditor from 'src/components/fields/RichTextEditor';

const text = {
  title: 'Are you sure you want to publish this study?',
  publishVersionType: 'Version Type',
  versionNameLabel: 'Expected Publish Version',
  currentVersionNameLabel: 'Current Version',
  getChangesLabel: ({ optional = true }) =>
    `Changes ${optional ? '(optional)' : ''}`,
  linkLabel: 'Link (optional)',
  getDescriptionLabel: ({ optional = true }) =>
    `Description ${optional ? '(optional)' : ''}`,
  cancelLabel: 'Cancel',
  publishLabel: 'Publish',
  errorFetchingVersions: 'Something went wrong fetching the versions.',
  errorFetchingICFReconsent:
    'Something went wrong fetching the ICF edited to reconsent.',
  ICFReconsent: 'List of ICF tiles that need reconsent',
  notice: 'Notice: ',
  connectAppNotice:
    'There is a Connect App tile in this study, a connection to Flow will be established.',
};

const publishTestId = 'publish_study';

const testIds = {
  mainContainer: `${publishTestId}_main_container`,
  changesField: `${publishTestId}_changes_field`,
  descriptionField: `${publishTestId}_description_field`,
  buttonCancel: `${publishTestId}_button_cancel`,
  buttonPublish: `${publishTestId}_button_publish`,
};

const fontBaseStyle = css`
  font-family: 'Heebo', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  color: #444444;
`;

const Container = styled.div`
  width: 850px;
  min-height: 276px;
  padding: 30px 50px;
`;

const Title = styled.h2`
  ${fontBaseStyle}
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #1e2834;
  margin-bottom: 16px;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
`;

const NoticeContainer = styled.div`
  border: 1px solid #ae8f13;
  border-radius: 4px;
  background-color: #fffae5;
  padding: 5px;
  margin-bottom: 5px;
`;

const PublishModal = ({
  onClose = () => {},
  onConfirm = () => {},
  graph: { id, status },
  tiles = {},
}) => {
  const {
    data: versions,
    error,
    isLoading,
    refetch,
  } = useQuery(
    [queryStores.studyVersions, String(id)],
    () => graphQueries.getPublishes(id),
    {
      enabled: id ? true : false,
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: editedICF,
    error: errorLoadingEditedICF,
    isLoading: isLoadingEditedICF,
  } = useQuery(
    ['possibleReconsentAnswers', id],
    () => graphQueries.getEditedIcf(id),
    {
      enabled: id ? true : false,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );

  const possibleReconsentAnswers = useMemo(() => {
    return (
      editedICF &&
      editedICF.reduce(
        (acc, { id, title }) => [...acc, { label: title, answer: id }],
        [],
      )
    );
  }, [editedICF]);

  const tileList = Object.values(tiles);
  const connectAppWarning = !!tileList.find(
    (tile) => tile.content.type === 'connect_app',
  );

  useEffect(() => {
    if (error) {
      toastr.error(text.errorFetchingVersions);
    }
    if (errorLoadingEditedICF) {
      toastr.error(text.errorFetchingICFReconsent);
    }
  }, [error, errorLoadingEditedICF]);

  const onSubmit = ({
    title = '',
    link = '',
    description = '',
    tiles_requiring_reconsent,
    publish_version_type,
  }) => {
    onConfirm(
      {
        title,
        link,
        description,
        tiles_requiring_reconsent,
        publish_version_type,
      },
      () => {
        refetch();
      },
    );
  };

  const optionalFields = ['unpublished', 'draft', 'qa'].includes(
    status.toLowerCase(),
  );

  const { register, formState, watch, setValue, handleSubmit, control } =
    useForm({
      defaultValues: {
        publish_version_type: 'patch',
      },
    });

  const selectedVersionType = watch('publish_version_type');
  const latestVersion = versions?.[0];
  const semanticVersion = latestVersion?.semantic_version.split('.');

  const getIncrementedVersion = () => {
    if (semanticVersion && semanticVersion.length === 3) {
      const [major, minor, patch] = semanticVersion;
      if (selectedVersionType === 'patch') {
        return `${major}.${minor}.${Number(patch) + 1}`;
      }
      if (selectedVersionType === 'minor') {
        return `${major}.${Number(minor) + 1}.${patch}`;
      }
      if (selectedVersionType === 'major') {
        return `${Number(major) + 1}.0.0`;
      }
    }
    return '';
  };

  return (
    <Modal onClose={onClose} holderClassName="noMaxWidth" center>
      <Container {...e2eTestId(testIds.mainContainer)}>
        <Title>{text.title}</Title>
        {isLoading || isLoadingEditedICF ? (
          <LoadingContainer>
            <LoadingIndicator data-testid="loadingIndicator" />
          </LoadingContainer>
        ) : (
          <>
            {connectAppWarning && (
              <NoticeContainer>
                <p>
                  <b>{text.notice}</b> {text.connectAppNotice}
                </p>
              </NoticeContainer>
            )}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 8,
                padding: 8,
                background: '#f8f8f8',
                border: '2px solid #e7e7e7',
                borderRadius: 4,
              }}
            >
              <p style={{ marginRight: 8, fontWeight: 'bold' }}>
                {text.currentVersionNameLabel}{' '}
              </p>
              <Chip variant="secondary">{latestVersion?.semantic_version}</Chip>
              <p style={{ marginRight: 8, marginLeft: 8, fontWeight: 'bold' }}>
                {text.versionNameLabel}
              </p>
              <Chip>{getIncrementedVersion()}</Chip>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <fieldset
                disabled={isLoading || isLoadingEditedICF}
                className="space-y-4"
                style={{
                  background: '#f8f8f8',
                  border: '2px solid #e7e7e7',
                  borderRadius: 4,
                  padding: 16,
                }}
              >
                <legend style={{ fontWeight: 'bold' }}>Publish Options</legend>
                <Controller
                  control={control}
                  name="title"
                  rules={{
                    required: {
                      value: !optionalFields,
                      message: 'Changes are required',
                    },
                  }}
                  render={({ field }) => (
                    <div>
                      <label>
                        {text.getChangesLabel({ optional: optionalFields })}
                      </label>
                      <div
                        style={{
                          border: get(formState.errors, 'title.message')
                            ? '1px solid rgb(190, 50, 46)'
                            : '1px solid #ccc',
                          borderRadius: 4,
                          padding: 16,
                          background: '#fff',
                          minHeight: 75,
                        }}
                      >
                        <RichTextEditor label="" meta={{}} input={field} />
                      </div>
                      {get(formState.errors, 'title.message') && (
                        <p
                          style={{
                            color: 'rgb(190, 50, 46)',
                            fontSize: 12,
                            marginTop: 4,
                          }}
                        >
                          {get(formState.errors, 'title.message')}
                        </p>
                      )}
                    </div>
                  )}
                />
                <Input {...register('link')} label={text.linkLabel} />
                <Controller
                  control={control}
                  name="description"
                  rules={{
                    required: {
                      value: !optionalFields,
                      message: 'Description is required',
                    },
                  }}
                  render={({ field }) => (
                    <div>
                      <label>
                        {text.getDescriptionLabel({
                          optional: optionalFields,
                        })}
                      </label>
                      <div
                        style={{
                          border: get(formState.errors, 'description.message')
                            ? '1px solid rgb(190, 50, 46)'
                            : '1px solid #ccc',
                          borderRadius: 4,
                          padding: 16,
                          background: '#fff',
                          minHeight: 75,
                        }}
                      >
                        <RichTextEditor label="" meta={{}} input={field} />
                      </div>
                      {get(formState.errors, 'description.message') && (
                        <p
                          style={{
                            color: 'rgb(190, 50, 46)',
                            fontSize: 12,
                            marginTop: 4,
                          }}
                        >
                          {get(formState.errors, 'description.message')}
                        </p>
                      )}
                    </div>
                  )}
                />
                <p>Publish Version Type</p>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    marginTop: 8,
                  }}
                >
                  <Button
                    variant={
                      selectedVersionType === 'patch' ? 'primary' : 'secondary'
                    }
                    type="button"
                    style={{
                      width: '100%',
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    onClick={() => setValue('publish_version_type', 'patch')}
                  >
                    Patch Version
                  </Button>
                  <Button
                    type="button"
                    variant={
                      selectedVersionType === 'minor' ? 'primary' : 'secondary'
                    }
                    style={{
                      width: '100%',
                      borderRadius: 0,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    onClick={() => setValue('publish_version_type', 'minor')}
                  >
                    Minor Version
                  </Button>
                  <Button
                    type="button"
                    variant={
                      selectedVersionType === 'major' ? 'primary' : 'secondary'
                    }
                    style={{
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    onClick={() => setValue('publish_version_type', 'major')}
                  >
                    Major Version
                  </Button>
                </div>
              </fieldset>
              {possibleReconsentAnswers && possibleReconsentAnswers.length > 0 && (
                <fieldset
                  className="space-y-4"
                  style={{
                    background: '#f8f8f8',
                    border: '2px solid #e7e7e7',
                    borderRadius: 4,
                    padding: 16,
                  }}
                >
                  <legend style={{ fontWeight: 'bold' }}>
                    {text.ICFReconsent}
                  </legend>
                  {possibleReconsentAnswers?.map((answer, id) => (
                    <Flex
                      items="center"
                      key={`possible_reconsent_answer_${id}`}
                    >
                      <input
                        type="checkbox"
                        id={`id_${answer.answer}`}
                        value={answer.answer}
                        {...register('tiles_requiring_reconsent')}
                      />
                      <label htmlFor={`id_${answer.answer}`}>
                        {answer.label}
                      </label>
                    </Flex>
                  ))}
                </fieldset>
              )}
              <Flex items="center" style={{ marginTop: 8 }} justify="flex-end">
                <Button
                  size="large"
                  variant="light"
                  type="button"
                  onClick={onClose}
                  disabled={formState.isSubmitting}
                  {...e2eTestId(testIds.buttonCancel)}
                  style={{
                    marginRight: 8,
                    minWidth: 200,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {text.cancelLabel}
                </Button>
                <Button
                  {...e2eTestId(testIds.buttonPublish)}
                  disabled={formState.isSubmitting}
                  title={text.publishLabel}
                  size="large"
                  style={{
                    minWidth: 200,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {text.publishLabel}
                </Button>
              </Flex>
            </form>
          </>
        )}
      </Container>
    </Modal>
  );
};

PublishModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default PublishModal;
