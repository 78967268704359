import { triageV3 } from './triage';
export type BrandMeta = {
  favicon: string;
  logo: string;
  welcome_text: string;
  login_button_text: string;
};

export type CognitoMeta = {
  user_pool_id: string;
  user_pool_domain: string;
  client_id: string;
  region: string;
  scope: string[];
  response_type: 'code' | 'token';
};

export const getBrandMeta = () =>
  triageV3<BrandMeta>({
    method: 'GET',
    url: 'manager/brand',
  });

export const getCognitoMeta = () =>
  triageV3<CognitoMeta>({
    method: 'GET',
    url: 'manager/auth_config',
  });
