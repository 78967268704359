import React from 'react';

export default (props) => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      role="graphics-symbol"
      {...props}
    >
      <g
        id="Symbols:-Icons"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <g id="icons/tile-types/black/screeners">
          <rect
            id="Container"
            fillOpacity={0}
            fill="#FFFFFF"
            x={0}
            y={0}
            width={24}
            height={24}
          />
          <path
            d="M10.3443206,18.6915348 L1.14503568,3.51844485 L1.14503568,3.51844485 C0.858706499,3.04618005 1.0094371,2.43121812 1.4817019,2.14488893 C1.63802464,2.0501121 1.81733689,2 2.00014675,2 L22.1994446,2 L22.1994446,2 C22.7517294,2 23.1994446,2.44771525 23.1994446,3 C23.1994446,3.18280986 23.1493325,3.36212211 23.0545557,3.51844485 L13.8552708,18.6915348 L13.8552708,21 L13.8552708,21 C13.8552708,21.5522847 13.4075555,22 12.8552708,22 L11.3443206,22 L11.3443206,22 C10.7920359,22 10.3443206,21.5522847 10.3443206,21 L10.3443206,18.6915348 Z"
            id="Combined-Shape"
            fill="#000000"
          />
          <g
            id="Group"
            transform="translate(8.000000, 4.000000)"
            fill="#FFFFFF"
          >
            <path
              d="M5.26631263,10.4661849 C5.26631263,10.5621277 5.23525746,10.641028 5.17365208,10.7026334 C5.11217295,10.7642387 5.03327263,10.7950414 4.93732983,10.7950414 L2.96242315,10.7950414 C2.86648035,10.7950414 2.78758003,10.7642387 2.72584841,10.7026334 C2.6641168,10.641028 2.63331411,10.5621277 2.63331411,10.4661849 L2.63331411,8.49127818 C2.63331411,8.39533539 2.6641168,8.31643506 2.72584841,8.25470345 C2.78758003,8.19322431 2.86648035,8.16242163 2.96242315,8.16242163 L4.93732983,8.16242163 C5.03327263,8.16242163 5.11217295,8.19322431 5.17365208,8.25470345 C5.23525746,8.31643506 5.26631263,8.39533539 5.26631263,8.49127818 L5.26631263,10.4661849 L5.26631263,10.4661849 Z"
              id="Path"
            />
            <path
              d="M7.77067205,4.38757762 C7.68495474,4.64132106 7.58901194,4.84886048 7.48271742,5.00994338 C7.37642291,5.17102628 7.22379812,5.33564392 7.02484306,5.50354381 C6.82626672,5.67156994 6.66846607,5.79162467 6.55169359,5.86370801 C6.43529983,5.93553887 6.26714746,6.03337527 6.04786768,6.15671226 C5.82164467,6.28699247 5.63493494,6.45665973 5.48761226,6.66584027 C5.34016333,6.87489457 5.26643887,7.04468807 5.26643887,7.17484204 C5.26643887,7.27091108 5.2353837,7.3498114 5.17377832,7.41141678 C5.11229919,7.47314839 5.03339887,7.50395108 4.93745607,7.50395108 L2.96254939,7.50395108 C2.86660659,7.50395108 2.78770627,7.47314839 2.72597465,7.41141678 C2.66424304,7.3498114 2.63344035,7.27091108 2.63344035,7.17484204 L2.63344035,6.80445236 C2.63344035,6.34506311 2.81333309,5.91470918 3.17337105,5.51364305 C3.53340902,5.11245068 3.92942553,4.8159117 4.36142058,4.62389987 C4.69040338,4.47316869 4.92356962,4.31877654 5.06066682,4.16110213 C5.1980165,4.00342772 5.26656511,3.79412094 5.26656511,3.53368675 C5.26656511,3.30733749 5.13779978,3.10510018 4.88064784,2.92672233 C4.6234959,2.74847071 4.3306179,2.65934491 4.00138262,2.65934491 C3.64475315,2.65934491 3.34998154,2.74165373 3.1168153,2.90627136 C2.89046605,3.07076276 2.59569444,3.38623782 2.23224798,3.85244406 C2.17051637,3.93475288 2.08479906,3.97603353 1.97509605,3.97603353 C1.89278723,3.97603353 1.82764712,3.95545632 1.77967572,3.91442815 L0.422085176,2.88594664 C0.264410768,2.76248341 0.237016575,2.61856922 0.339776358,2.45395158 C1.21752669,0.993475015 2.48939992,0.263173611 4.1555223,0.263173611 C4.75226123,0.263173611 5.33511371,0.403805549 5.90420597,0.684943186 C6.47329823,0.965954582 6.94808882,1.36373846 7.32883023,1.87804233 C7.70906667,2.39221997 7.89956362,2.95108675 7.89956362,3.55451643 C7.8991849,3.85623127 7.85613688,4.13383417 7.77067205,4.38757762 L7.77067205,4.38757762 Z"
              id="Path"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
