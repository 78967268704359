import { Button, color, Flex } from '@evidation/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { Fragment, useEffect, useMemo } from 'react';

import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';

import stripHtml from 'src/utils/stripHtml';
import ConditionItem from './condition-item';
import EntitySelector from './entity-selector';
import {
  Entity,
  findTileQuestion,
  LogicFormValues,
  useQuestionsInTileSequence,
  validations,
} from '../utils';
import { useTypedSelector } from 'src/redux/utils';

export function LogicForm({
  type,
  entity,
  loading,
}: {
  type: string;
  entity: Entity<true>;
  loading: boolean;
}) {
  const selectedTile = useTypedSelector((state) => state.tiles[entity.tileId]);
  const selectedQuestion = findTileQuestion(selectedTile, entity.entityId)!;
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { fields, append, remove, insert } = useFieldArray({
    control,
    name: `conditions`,
  });
  const questionsToSkipTo = useQuestionsInTileSequence(selectedTile, {
    returnFollowing: true,
    includeCurrent: false,
    componentId: entity.entityId,
  });

  return (
    <div style={{ position: 'relative' }}>
      <fieldset disabled={loading}>
        <legend style={{ display: 'flex', marginBottom: 20, width: '100%' }}>
          {stripHtml(selectedQuestion.form.label)}
        </legend>
        {fields.map((field, id) => (
          <Fragment key={field.id}>
            <ConditionItem
              key={field.id}
              id={id}
              insert={insert}
              append={append}
              targetQuestion={selectedQuestion}
              tile={selectedTile}
              type={type}
              remove={remove}
              name={`conditions.${id}.operator`}
              count={fields.length}
            />
          </Fragment>
        ))}
        {type === 'skip' && (
          <Flex items="center" style={{ marginTop: 40 }}>
            <div
              style={{
                color: color.palette.blue[20],
                marginRight: '.5em',
                textTransform: 'capitalize',
              }}
            >
              {type} To
            </div>

            <EntitySelector
              questions={questionsToSkipTo}
              entityId={watch('targetId')}
              entityType={watch('targetType')}
              tileId={watch('tileId')}
              typeError={errors['targetType']?.message}
              idError={errors['targetId']?.message}
              setEntityType={(v: string) =>
                setValue('targetType', v, {
                  shouldValidate: true,
                  shouldDirty: true,
                })
              }
              setEntityValue={(v: string) =>
                setValue('targetId', v, {
                  shouldValidate: true,
                  shouldDirty: true,
                })
              }
              setTileId={(v: string) =>
                setValue('tileId', v, {
                  shouldValidate: true,
                  shouldDirty: true,
                })
              }
            />
          </Flex>
        )}
      </fieldset>
    </div>
  );
}

export default function LogicFormHandler({
  entity,
  tile,
  type,
  hasChanged,
  onSubmit,
  loading,
  onCancel,
}) {
  const methods = useForm<LogicFormValues>({
    defaultValues: entity?.defaultValues || {
      targetType: 'question',
      targetId: '',
      conditions: [{ depth: 0, conditions: [], parent: null, operator: 'or' }],
    },
    resolver: yupResolver(validations(type)),
  });

  const formValues = methods.watch();
  const isDirty = methods.formState.isDirty;

  const hasUnsavedChanges = useMemo(() => {
    return isDirty;
  }, [isDirty]);

  useEffect(() => {
    hasChanged?.(hasUnsavedChanges);
  }, [hasUnsavedChanges, hasChanged]);

  return (
    <div>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit((v) =>
            onSubmit(v).then(() =>
              // reset the form after submission to reset the dirty state.
              methods.reset(formValues, { keepDirty: false }),
            ),
          )}
        >
          <LogicForm entity={entity} loading={loading} type={type} />
          <Flex
            justify="flex-end"
            style={{
              marginTop: '.5rem',
            }}
          >
            <Button
              onClick={() => onCancel()}
              type="button"
              variant="ghost"
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button
              style={{ minWidth: 150, justifyContent: 'center' }}
              disabled={loading || !hasUnsavedChanges}
            >
              {loading ? 'Saving...' : 'Save'}
            </Button>
          </Flex>
        </form>
      </FormProvider>
    </div>
  );
}
