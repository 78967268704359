import { Select } from '@evidation/ui';
import { Controller } from 'react-hook-form';

export default function RHFSelectWrapper({ control, name, ...rest }) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => {
        return (
          <Select
            {...rest}
            error={fieldState.error?.message}
            onChange={(v) => field.onChange(v.value)}
            name={field.name}
            value={field.value}
          />
        );
      }}
    />
  );
}
