import * as C from '../common';

export default (data) => {
  const working = { ...data };
  for (const header of working?.content?.http_headers || []) {
    // once a secret is set the backend will omit the value field
    // we will reset it to true so we can provide the necessary hook
    // for our edit logic in the custom form.
    if (header.is_secret && !('value' in header)) {
      header.value = false;
    }
  }
  return C.common_incoming(working);
};
