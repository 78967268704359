import { useMemo } from 'react';
import stripHtml from 'src/utils/stripHtml';
import { TileQuestion } from '../utils';
import { Select } from '@evidation/ui';

type EntitySelectorProps = {
  // TODO: Make this more generic so it's not tied directly to questions.
  questions: TileQuestion[];
  entityType: string;
  entityId: string;
  tileId: string;
  setEntityType: (v: string) => any;
  setEntityValue: (v: string) => any;
  setTileId: (v: string) => any;
  typeError?: any;
  idError?: any;
};

export default function EntitySelector({
  questions,
  entityType,
  entityId,
  tileId,
  setEntityType,
  setEntityValue,
  setTileId,
  typeError,
  idError,
}: EntitySelectorProps) {
  const questionOptions = useMemo(() => {
    return questions.map((question) => ({
      label: stripHtml(question.form.label),
      value: `${question.form.component_id!}|${question.tileId}`,
    }));
  }, [questions]);

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <Select
        value={entityType}
        options={[{ label: 'Question', value: 'question' }]}
        label=""
        error={typeError}
        style={{ minWidth: 150, marginRight: '1rem' }}
        onChange={(v) => setEntityType(v.value)}
      />
      <Select
        options={questionOptions}
        value={entityId && tileId ? `${entityId}|${tileId}` : entityId}
        error={idError}
        label=""
        style={{ width: '100%' }}
        onChange={(v) => {
          const [componentId, tileId] = v.value.split('|');
          setEntityValue(componentId);
          if (tileId) {
            setTileId(tileId);
          }
        }}
      />
    </div>
  );
}
