export default {
  label: 'Completed',
  description: (
    <>
      <p>
        The 'Completed' status indicates the project version is finished and
        inactive. Key points:
      </p>
      <ul>
        <li>No further participant access required.</li>
        <li>Billboard access disabled.</li>
        <li>No data CRUD operations permitted.</li>
        <li>All data flows/jobs for the study stop.</li>
      </ul>
    </>
  ),
};
