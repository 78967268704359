//@ts-nocheck
import TransformTree from '../utils/translateToTree';

import { apiGraph } from '../api';
import { toastr } from 'react-redux-toastr';
import types from '../constants';
import { updateGraph } from '../actions/graph_actions';
import { loadGraph } from '../actions/combination';
import { logError } from '../utils/errorLogger';

export default ({ getState, dispatch }: Middleware): any =>
  (next: Dispatch<AnyAction>) =>
  (action: AnyAction) => {
    switch (action.type) {
      case types.graph.persist:
        const state = getState() as RootState;
        const graphId = state.graph.id;
        // we always assume the graph in state is in "sync" with our api
        const payload = TransformTree({
          ...state,
          insert: {},
        });

        apiGraph
          .update(graphId, payload)
          .then((data) => dispatch(updateGraph(data)))
          .then(toastr.success(action.success_message))
          .catch((err) => {
            logError(err);
            dispatch(loadGraph(graphId));
            toastr.error('There was a problem saving your change.');
          });

        return next(action);

      default:
        return next(action);
    }
  };
