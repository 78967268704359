export default {
  label: 'Data Lock',
  description: (
    <>
      <p>The 'Data Lock' status indicates:</p>
      <ul>
        <li>Data cleaning is finished for the project version.</li>
        <li>
          Unplanned data collection/changes require snapshot restore and
          documentation.
        </li>
        <li>No data CRUD operations permitted on data.</li>
        <li>
          Sign off step required before transitioning to 'Database Lock' status
          (e.g., CFR11 e-signature, two-person process).
        </li>
        <li>
          Message sent to Data Platform to take a fully restorable, read-only
          data snapshot.
        </li>
      </ul>
    </>
  ),
  arrow: (
    <ul>
      <li>
        Person, change, reason Send message to Data Platform to take a fully
        restorable data snapshot (read-only)
      </li>
    </ul>
  ),
};
