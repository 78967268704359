import * as C from '../common';

import { default_experience_key } from '../../../../constants';
import { getInputsForExperience } from '../utils';

/**
 * {@deprecated}See Custom Form defined in api-request-form
 * */
export const RedirectUserTileSettings = ({ experience, masonryEnabled }) => ({
  label: 'Settings',
  name: 'content',
  input: getInputsForExperience({
    experience,
    mandatoryInputs: [
      C.experienceTileTitle(masonryEnabled),
      C.experienceTileDescription(masonryEnabled),
      C.optional,
      C.merge_data,
    ],
    optionalInputs: [
      ...C.dependencyCriteria({
        emitEvent: true,
        visibility: true,
        cancellation: true,
      }),
    ],
  }),
});
/**
 * {@deprecated}See Custom Form defined in api-request-form
 */
export default ({ experience = default_experience_key, dataForTile }) => {
  const {
    redirect_user: { masonryEnabled = false },
  } = dataForTile;

  const schema = [
    {
      label: 'Content',
      name: masonryEnabled
        ? `content.experiences.${default_experience_key}.settings`
        : 'content',
      input: [
        C.experienceExternalTitle(masonryEnabled),
        C.experienceExternalDescription(masonryEnabled),
        C.merge_data,
        {
          name: 'redirect_url',
          label: 'Redirect URL',
          interface: 'textinput',
          accepts_data: true,
          value: 'https://example.com',
          required: true,
        },
        {
          name: 'should_complete',
          label: 'Should Complete',
          interface: 'checkbox',
          accepts_data: true,
          value: false,
          required: false,
        }
      ],
    },
  ];

  if (!masonryEnabled) {
    schema.push(
      RedirectUserTileSettings({
        experience,
        masonryEnabled,
      }),
    );
  }
  return schema;
};
