import React from 'react';
import { Button } from '@evidation/form-elements';
import { toastr } from 'react-redux-toastr';
import { apiGraph } from '../../../api';
import generatePayload from '../../../utils/generatePayload';
import TransformTree, { reduceSequences } from '../../../utils/translateToTree';
import clone from '../../../utils/objectClone';
import { logError } from 'src/utils/errorLogger';

const sequence_messages = {
  duplicateSuccess: 'You have successfully duplicate a sequence.',
};

const commonError = (error) => {
  logError(error);
  if (error.response?.data?.errors) {
    for (const errorItem of error.response.data.errors) {
      toastr.error(errorItem);
    }
  }
};

const duplicateTitle = (title) => `*Duplicate of: ${title}`;

const DuplicateSequence = (props) => {
  const {
    graph,
    setGraphSequencesTiles,
    selectedSequences,
    tiles,
    sequences,
    reminders,
  } = props;
  const cloneSequence = (sequence_uuid) => {
    const clonedSequence = clone(
      generatePayload(
        sequence_uuid,
        reduceSequences,
        { sequences: sequences, tiles: tiles, reminders: reminders },
        'sequences',
        {},
      )[0],
    );
    delete clonedSequence.id;
    clonedSequence.content.title = duplicateTitle(clonedSequence.content.title);
    return clonedSequence;
  };

  const saveNewPayload = (newPayload) => {
    apiGraph
      .update(graph.id, newPayload)
      .then(setGraphSequencesTiles)
      .then(() => toastr.success(sequence_messages.duplicateSuccess))
      .catch(commonError);
  };

  const duplicateSequence = () => {
    const clonedSequences = selectedSequences.map((sequence) =>
      cloneSequence(sequence.sequence_uuid),
    );
    const newPayloads = TransformTree({
      ...props,
      insert: {
        target: selectedSequences[0].sequence_uuid,
        content: clonedSequences,
      },
    });
    saveNewPayload(newPayloads);
  };

  return (
    <Button
      id="t-duplicate-sequence"
      onClick={() => {
        duplicateSequence();
      }}
    >
      Duplicate
    </Button>
  );
};

export default DuplicateSequence;
