export default {
  label: 'Data Close',
  description: (
    <>
      <p>The 'Data Close' status indicates</p>
      <ul>
        <li>No further data collection for the project version.</li>
        <li>Changes via Research Manager are denied.</li>
        <li>Outstanding participant contributions are canceled.</li>
        <li>Connected apps/devices are disconnected.</li>
        <li>Participant statuses transitioned:</li>
        <ul>
          <li>Enrolled {'->'} Early Termination (Reason: TBD).</li>
          <li>Others unchanged.</li>
        </ul>
        <li>
          Billboard accessible to registered participants to view dashboard,
          compensation, change settings, download consent PDF.
        </li>
        <li>No configuration changes permitted.</li>
        <li>No data CRUD operations permitted on data.</li>
        <li>All study jobs stop processing.</li>
      </ul>
    </>
  ),
  arrow: (
    <ul>
      <li>
        Auth tokens are expired for all enrollments in a study, all participants
        will not be able to log back in
      </li>
      <li>
        Any active connections to Flow for app data (e.g. Fitbit data) will be
        disconnected.
      </li>
      <li>Ordinal events will not be generated</li>
    </ul>
  ),
};
