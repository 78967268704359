import Settings from './settings';
import incoming from './incoming';
import outgoing from './outgoing';
import ApiRequestForm from './api-request-form';

export default {
  form: {
    // Components: () => [],
    Settings,
  },
  Component: ApiRequestForm,
  incoming,
  outgoing,
};
