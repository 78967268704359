import React from 'react';

export default (props) => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      role="graphics-symbol"
      {...props}
    >
      <g
        id="Symbols:-Icons"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <g id="icons/tile-types/black/registration">
          <rect
            id="Container"
            fillOpacity={0}
            fill="#FFFFFF"
            x={0}
            y={0}
            width={24}
            height={24}
          />
          <rect
            id="Rectangle"
            fill="#000000"
            x="1.00048828"
            y={4}
            width="21.9990234"
            height={15}
            rx={3}
          />
          <g
            id="Group"
            transform="translate(3.000000, 7.000000)"
            fill="#FFFFFF"
          >
            <path
              d="M4.7777603,4.77783203 C5.3973432,4.77783203 5.92619069,4.55126544 6.36437452,4.09828053 C6.8027018,3.64529561 7.02182958,3.09867405 7.02182958,2.4583417 C7.02182958,1.81808348 6.8027018,1.27146192 6.36437452,0.818402867 C5.92611896,0.365417956 5.39719974,0.1389255 4.7777603,0.1389255 C4.15832087,0.1389255 3.62940164,0.365417956 3.19114609,0.818402867 C2.75281881,1.27146192 2.53369103,1.81808348 2.53369103,2.4583417 C2.53369103,3.09867405 2.75281881,3.64529561 3.19114609,4.09828053 C3.62947337,4.55126544 4.15832087,4.77783203 4.7777603,4.77783203 L4.7777603,4.77783203 Z"
              id="Shape"
            />
            <path
              d="M8.78948422,6.56871641 C8.74859949,6.33310494 8.6968839,6.11469356 8.63455263,5.91333398 C8.57222136,5.71204854 8.48844354,5.51573037 8.38321917,5.32445359 C8.27806653,5.13317682 8.15727727,4.9700726 8.0208514,4.83514092 C7.88442553,4.70020925 7.7179458,4.59256046 7.52126876,4.51204629 C7.32444826,4.43153211 7.10725713,4.39120088 6.86962364,4.39120088 C6.83454885,4.39120088 6.75270767,4.43449764 6.62417184,4.52109116 C6.495636,4.60768467 6.35053109,4.70436099 6.18885711,4.81104598 C6.02718313,4.91765683 5.81673439,5.01440729 5.55772608,5.10085253 C5.29864604,5.18744605 5.03863354,5.2307428 4.77754512,5.2307428 C4.51652843,5.2307428 4.25651593,5.18744605 3.99743589,5.10085253 C3.73842757,5.01440729 3.52797884,4.91765683 3.36630486,4.81104598 C3.20463088,4.70436099 3.05952597,4.60768467 2.93099013,4.52109116 C2.80238257,4.43449764 2.72061312,4.39120088 2.68553833,4.39120088 C2.44790484,4.39120088 2.23071371,4.43153211 2.03396494,4.51204629 C1.83721617,4.59256046 1.67066471,4.70028339 1.53431057,4.83514092 C1.39795643,4.9700726 1.27716717,5.13317682 1.17201453,5.32445359 C1.06686189,5.51573037 0.983084066,5.71212268 0.920752793,5.91333398 C0.858421521,6.11469356 0.806777658,6.33310494 0.765892934,6.56871641 C0.724936482,6.80432787 0.697679999,7.02370305 0.684051758,7.2271385 C0.670423516,7.43057395 0.663609396,7.63890253 0.663609396,7.85234664 C0.663609396,8.33550584 0.8058452,8.71709558 1.09024508,8.9969676 C1.37464496,9.27676548 1.75257761,9.41666442 2.22397131,9.41666442 L7.33154929,9.41666442 C7.80294299,9.41666442 8.18080392,9.27676548 8.46527553,8.9969676 C8.74974714,8.71709558 8.89191121,8.33557998 8.89191121,7.85234664 C8.89191121,7.63890253 8.88509709,7.43049981 8.87132539,7.2271385 C8.85762543,7.02377719 8.83036894,6.80432787 8.78948422,6.56871641 Z"
              id="Combined-Shape"
            />
          </g>
          <rect
            id="Rectangle-2"
            fill="#FFFFFF"
            x={14}
            y={9}
            width={7}
            height={1}
          />
          <rect
            id="Rectangle-2-Copy"
            fill="#FFFFFF"
            x={14}
            y={11}
            width={7}
            height={1}
          />
          <rect
            id="Rectangle-2-Copy-2"
            fill="#FFFFFF"
            x={14}
            y={13}
            width={7}
            height={1}
          />
        </g>
      </g>
    </svg>
  );
};
