import _ from 'lodash';
import { default_experience_key, event_statuses } from '../../../../constants';
import Icon from '@evidation/icon';
import React from 'react';

export default (
  {
    roles,
    arms,
    initialValues,
    nodeSlugs,
    experience = default_experience_key,
  },
) => {
  const hasUpdatePermission = _.includes(roles, 'owner') ||
    _.includes(roles, 'production_support');
  return [
    [
      {
        name: `content`,
        input: [
          {
            label: `Updateable Fields`,
            interface: `settings_heading`,
            name: `updateable_fields`,
            description: `These data fields can be updated by users in the Research Manager role.`,
          },
          {
            name: `hor_updateable_fields`,
            label: `Updatable by PE team`,
            interface: `flatarray`,
            type: `settings_textinput`,
            addButtonLabel: `add a field`,
            deleteButtonIcon: <Icon name="close-action-icon" size="25px" />,
            disabled: !hasUpdatePermission,
          },
          {
            name: `production_support_updateable_fields`,
            label: 'Updatable by Production Support And Owner',
            interface: `flatarray`,
            type: `settings_textinput`,
            addButtonLabel: `add a field`,
            deleteButtonIcon: <Icon name="close-action-icon" size="25px" />,
            disabled: !hasUpdatePermission,
          },
          {
            label: `Participant Explorer Custom Slugs`,
            interface: `settings_heading`,
            name: `pe_custom_slugs`,
            description: `Participant Explorer has some slugs that get auto-populated to the event find-as-you-type interface. This allows you to append ad-hoc slugs for third party integrations, routine surveys, and system events. Commonly added fields will be added to the list that gets populated automatically.`,
          },
          {
            name: 'participant_explorer_event_slugs',
            label: 'Non-Tile Event Slugs to Display in Participant Explorer',
            interface: `array`,
            className: 'double_text-toggle-column',
            type: `settings_textinput`,
            addButtonLabel: `add a field`,
            deleteButtonIcon: <Icon name="close-action-icon" size="25px" />,
            disabled: !hasUpdatePermission,
            items: [
              {
                name: 'slug',
                className: 'col-left',
                required: true,
                label: 'Slug',
                interface: 'rawtext',
              },
              {
                name: 'status',
                label: 'Status',
                required: true,
                className: 'col-right',
                interface: 'select',
                options: event_statuses
              }
            ]
          },
        ],
      },
    ],
  ];
};
