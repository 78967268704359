import { useForm } from 'react-hook-form';
import {
  Input,
  Toggle,
  Select,
  Button,
  Flex,
  RadioGroup,
  Divider,
} from '@evidation/ui';
import { StyledForm } from '../components';
import Branching, { Branch } from './Branching';
import { yupResolver } from '@hookform/resolvers/yup';
import validations from './validations';

export type SequenceFormValues = {
  title: string;
  add_to_existing_branch: boolean;
  branching: boolean;
  branches?: Branch[];
  strategy: string;
  condition?: string;
  default_arm: string;
  branch_arms?: string;
  on_cap_overflow?: string;
  archipelago_identifier?: string;
  sequential_surveys: boolean;
  unified_progress_bar: boolean;
};

type SequenceFormProps = {
  defaultValues: any;
  onSubmit: (values: any) => any;
  armOptions: any[];
  loading?: boolean;
};

const capOverflowOptions = [
  {
    label: 'Exclude Branch',
    value: 'exclude_branch',
    description:
      '"Exclude Branch" continues list assignments avoiding the capped branch.',
  },
  {
    label: 'Queue Assignments',
    value: 'queue_assignments',
    description:
      '"Queue assignments" will not assign the full branch until the capped branch is open again.',
  },
];

const SequenceForm: React.FC<SequenceFormProps> = ({
  defaultValues,
  onSubmit,
  armOptions,
  loading,
}) => {
  const { register, watch, handleSubmit, setValue, control, formState } =
    useForm<SequenceFormValues>({
      defaultValues,
      resolver: yupResolver(validations),
    });

  const branchingEnabled = watch('branching');
  const branchingStrategy = watch('strategy');
  const branches = watch('branches');
  const addToExistingBranch = watch('add_to_existing_branch');
  const branchArms = watch('branch_arms');
  const onCapOverflow = watch('on_cap_overflow');
  const default_arm = watch('default_arm');
  const getError = (key: keyof SequenceFormValues) =>
    formState?.errors?.[key] && formState?.errors?.[key]?.message?.toString();
  const sequentialSurveysEnabled = watch('sequential_surveys');
  const unifiedProgressBarEnabled = watch('unified_progress_bar');

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <Input
        {...register('title')}
        label="Title"
        id="id_test"
        error={getError('title')}
        placeholder="Sequence Title"
        required
      />
      <Flex items="flex-end" spaceX={4} spaceY={4}>
        {armOptions.length > 0 && (
          <Toggle
            onChange={(v) => setValue('add_to_existing_branch', v)}
            checked={addToExistingBranch}
            label="Add To Existing Branch"
          />
        )}
        <Toggle
          onChange={(v) => setValue('branching', v, { shouldValidate: true })}
          checked={branchingEnabled}
          label="Branching"
        />
        <Toggle
          label="Sequential Surveys"
          checked={sequentialSurveysEnabled}
          onChange={(v) =>
            setValue('sequential_surveys', v, { shouldValidate: true })
          }
        />
        <Toggle
          label="Unified progress bar for all sequence surveys"
          checked={unifiedProgressBarEnabled}
          onChange={(v) =>
            setValue('unified_progress_bar', v, { shouldValidate: true })
          }
        />
      </Flex>
      {addToExistingBranch && (
        <Select
          required
          name="branch_arms"
          label="Branch Name"
          error={getError('branch_arms')}
          onChange={(option) =>
            setValue('branch_arms', option.value, { shouldValidate: true })
          }
          options={armOptions}
          value={branchArms}
        />
      )}
      {branchingEnabled && (
        <>
          <Select
            required
            name="strategy"
            label="Select Branching Strategy"
            error={getError('strategy')}
            onChange={(option: any) =>
              setValue('strategy', option.value, { shouldValidate: true })
            }
            options={[
              { label: 'Conditional', value: 'conditional' },
              { label: 'List Randomization', value: 'list' },
            ]}
            value={branchingStrategy}
          />
          {branchingStrategy === 'conditional' && (
            <Input
              {...register('condition')}
              label="Condition"
              error={getError('condition')}
              id="id_branching_condition"
              required
              placeholder="Enter the name of screeners, survey questions or user data parameters (e.g sex, medication, utm_campaign)"
            />
          )}
          <Branching
            control={control}
            defaultArm={default_arm}
            setValue={setValue}
            errors={formState.errors}
            branches={branches}
            register={register}
            branchingStrategy={branchingStrategy}
          />
        </>
      )}
      {branchingStrategy === 'list' && (
        <RadioGroup
          value={onCapOverflow}
          name="on_cap_overflow"
          onChange={(value: any) => setValue('on_cap_overflow', value)}
          label="* Cap Full Behavior"
          error={getError('on_cap_overflow')}
          options={capOverflowOptions}
        />
      )}
      <Divider />
      <Flex justify="flex-end" spaceY={8}>
        <Button type="submit" disabled={loading}>
          Save Sequence
        </Button>
      </Flex>
    </StyledForm>
  );
};

export default SequenceForm;
