import React from 'react';

export default (props) => {
  return (
    <svg
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
      role="graphics-symbol"
    >
      <g fill="#000" fillRule="evenodd">
        <path d="M12.959 12.792l-2.02 2.02-1.75-1.75 2.02-2.02a.83.83 0 0 0 0-1.187.83.83 0 0 0-1.187 0l-2.02 2.02-1.23-1.228a.981.981 0 0 0-1.375 0 .981.981 0 0 0 0 1.375l.354.354-.98.979a4.166 4.166 0 0 0-.603 5.104l-1.792 1.792a.981.981 0 0 0 0 1.376c.208.165.457.269.687.269.23 0 .5-.104.688-.291l1.792-1.792a4.142 4.142 0 0 0 5.104-.604l.979-.98.354.355a.981.981 0 0 0 .687.291.981.981 0 0 0 .688-1.667l-1.229-1.228 2.02-2.021a.83.83 0 0 0 0-1.187.849.849 0 0 0-1.187.02zM21.605 2.396a.981.981 0 0 0-1.376 0l-1.792 1.792a4.142 4.142 0 0 0-5.104.603l-.979.98L12 5.415a.981.981 0 0 0-1.375 0 .981.981 0 0 0 0 1.376l6.584 6.583a.981.981 0 0 0 .687.292.981.981 0 0 0 .688-1.667l-.354-.354.979-.979a4.166 4.166 0 0 0 .604-5.104l1.792-1.792c.395-.396.395-1 0-1.375z" />
      </g>
    </svg>
  );
};
