import React from 'react';

export default (props) => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      role="graphics-symbol"
    >
      <title>icons/tile-types/follow_up_survey</title>
      <desc>Created with Sketch.</desc>
      <g
        id="icons/tile-types/follow_up_survey"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <rect
          id="Container"
          fillOpacity="0"
          fill="#FFFFFF"
          x="0"
          y="0"
          width="24"
          height="24"
        />
        <path
          d="M17.0198974,3.3411964 C15.4875581,2.44701219 13.8147385,2 12.0003196,2 C10.1860606,2 8.5127615,2.44701219 6.98074188,3.3411964 C5.44856243,4.23522079 4.23554043,5.4482428 3.34135622,6.98042224 C2.44701219,8.51260168 2,10.1859008 2,12 C2,13.814259 2.44717201,15.4872385 3.3411964,17.0195778 C4.23538061,18.5514376 5.44840261,19.7646194 6.98058206,20.6588036 C8.5127615,21.5529878 10.1859008,22 12.0001598,22 C13.8144188,22 15.487718,21.5529878 17.0197376,20.6588036 C18.5517572,19.7647792 19.7647792,18.5515974 20.6589634,17.0195778 C21.5529878,15.4873983 22,13.8140992 22,12 C22,10.185741 21.5529878,8.51244187 20.6591232,6.98042224 C19.764939,5.44840261 18.551917,4.23538061 17.0198974,3.3411964 Z"
          id="Path"
          fill="#000000"
        />
        <path
          d="M21.053957,12.0187071 C21.053957,12.0100944 21.055693,12.002343 21.055693,11.9937304 L21.053089,11.9937304 C21.0348598,7.03719037 16.9662372,3.01066308 11.9662827,3.01066308 L11.9662827,6.90974908 C14.8082569,6.90974908 17.1206803,9.20082591 17.1251247,12.0188658 C17.1121039,14.7879577 14.8707007,17.039347 12.0929482,17.1072569 C9.49319406,17.0443829 7.36476896,15.0625959 7.09654917,12.5339227 L9.17117476,12.5339227 L5.1138783,8.50827733 L1.05569297,12.5330407 L3.16505157,12.5330407 C3.44369348,17.1692136 7.25790326,20.8676568 11.965416,20.9977439 L11.965416,21.0106631 C12.0079512,21.0106631 12.0504841,21.0046341 12.0930193,21.0037729 C12.1355545,21.0046341 12.1772208,21.0106631 12.2206226,21.0106631 L12.2206226,20.9977439 C17.1034671,20.8625238 21.0347647,16.898015 21.0538757,12.0265829 L21.0547436,12.0265829 C21.0547436,12.0239992 21.0538757,12.0214153 21.0538757,12.0188314 L21.053957,12.0187071 Z"
          id="Path"
          fill="#FFFFFF"
          fillRule="nonzero"
        />
        <g id="Group" transform="translate(9.000000, 8.000000)" fill="#FFFFFF">
          <path
            d="M3.78836298,7.75010788 C3.78836298,7.82298509 3.76477378,7.88291701 3.71797894,7.92971185 C3.67127999,7.97650669 3.61134807,7.99990411 3.53847086,7.99990411 L2.03835099,7.99990411 C1.96547378,7.99990411 1.90554187,7.97650669 1.85865113,7.92971185 C1.8117604,7.88291701 1.78836298,7.82298509 1.78836298,7.75010788 L1.78836298,6.24998801 C1.78836298,6.1771108 1.8117604,6.11717888 1.85865113,6.07028815 C1.90554187,6.0235892 1.96547378,6.00019178 2.03835099,6.00019178 L3.53847086,6.00019178 C3.61134807,6.00019178 3.67127999,6.0235892 3.71797894,6.07028815 C3.76477378,6.11717888 3.78836298,6.1771108 3.78836298,6.24998801 L3.78836298,7.75010788 L3.78836298,7.75010788 Z"
            id="Path"
          />
          <path
            d="M5.69064998,3.13285707 C5.62553995,3.32559812 5.55266274,3.48324304 5.47192246,3.60560004 C5.39118218,3.72795704 5.27524988,3.85299899 5.12412555,3.98053411 C4.97328891,4.10816512 4.85342507,4.19935753 4.76472583,4.25411133 C4.67631427,4.30867335 4.54858737,4.38298892 4.38202458,4.4766745 C4.21018779,4.57563408 4.0683649,4.70451167 3.95646002,4.86340317 C3.84445926,5.02219878 3.78845887,5.15117227 3.78845887,5.25003596 C3.78845887,5.32300906 3.76486967,5.38294098 3.71807483,5.42973582 C3.67137588,5.47662655 3.61144396,5.50002397 3.53856675,5.50002397 L2.03844689,5.50002397 C1.96556967,5.50002397 1.90563776,5.47662655 1.85874702,5.42973582 C1.81185629,5.38294098 1.78845887,5.32300906 1.78845887,5.25003596 L1.78845887,4.96869157 C1.78845887,4.61974397 1.92510364,4.29285132 2.19858497,3.9882054 C2.47206629,3.68346359 2.77287657,3.45821547 3.10101581,3.31236515 C3.35090793,3.19787122 3.52801873,3.08059644 3.63215643,2.9608285 C3.73648591,2.84106056 3.78855476,2.68207316 3.78855476,2.48424989 C3.78855476,2.31231721 3.69074587,2.15869972 3.49541577,2.02320564 C3.30008566,1.88780745 3.07761839,1.82010836 2.82753448,1.82010836 C2.55664222,1.82010836 2.33273657,1.88262933 2.15562577,2.00767129 C1.98369309,2.13261735 1.75978744,2.37224912 1.48371706,2.72637484 C1.43682633,2.78889581 1.37171629,2.82025219 1.28838695,2.82025219 C1.22586598,2.82025219 1.17638619,2.80462195 1.13994758,2.77345735 L0.108735038,1.99223282 C-0.0110329052,1.89845136 -0.0318412669,1.78913554 0.0462140623,1.66409359 C0.712944656,0.554729827 1.67904716,8.8817842e-16 2.94461748,8.8817842e-16 C3.39789455,8.8817842e-16 3.84062361,0.106822649 4.27290055,0.320372057 C4.70517748,0.533825574 5.06582379,0.835978329 5.35503125,1.22663854 C5.64385514,1.61720286 5.78855476,2.04171261 5.78855476,2.50007192 C5.78826709,2.72925157 5.75556824,2.94011603 5.69064998,3.13285707 L5.69064998,3.13285707 Z"
            id="Path"
          />
        </g>
      </g>
    </svg>
  );
};
