import type { WebhookFormValues } from '.';
import { event_statuses } from '../../../constants';
import * as C from '../../../routes/edit/forms/common';

export const schema = [
  {
    label: ' ',
    name: 'content',
    input: [
      {
        name: 'name',
        label: 'Webhook Name',
        interface: 'textinput',
        wrapperClass: 'col-span-4',
        labelTooltip: {
          title: 'Webhook Name',
          content:
            'A friendly name for your webhook that you can easily identify.',
        },
        required: true,
      },
      {
        name: 'target_http_method',
        label: 'HTTP Request Method',
        interface: 'select',
        required: true,
        labelTooltip: {
          title: 'HTTP Request Method',
          content:
            "The type of HTTP request you want your webhook to send. Please refer to the following documentation for general information about  <br /><a href='https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods'>HTTP request methods.</a>",
        },
        options: [
          { label: 'GET', value: 'GET' },
          { label: 'POST', value: 'POST' },
          { label: 'PUT', value: 'PUT' },
          { label: 'DELETE', value: 'DELETE' },
          { label: 'PATCH', value: 'PATCH' },
        ],
      },
      {
        name: 'target_url',
        label: 'URL',
        labelTooltip: {
          title: 'Target URL',
          content: 'The URL you want your webhook to make a request to.',
        },
        interface: 'textinput',
        wrapperClass: 'col-span-2',
        required: true,
      },
      {
        name: 'webhook_event_trigger',
        label: 'Event Trigger',
        interface: 'textinput',
        labelTooltip: {
          title: 'Event trigger',
          content: 'The internal system event that will trigger this webhook.',
        },
        wrapperClass: 'col-span-1',
        required: true,
      },
      {
        name: 'on_webhook_success_event_name',
        label: 'Webhook Success Event Name',
        interface: 'textinput',
        labelTooltip: {
          title: 'Success event name',
          content:
            'If you would like to emit an event when this webhook succeeds, you can supply it in this optional field.',
        },
        wrapperClass: 'col-span-2',
        parse: C.parseSlug,
      },
      {
        name: 'on_webhook_success_event_status',
        label: 'Webhook Success Event Status',
        interface: 'select',
        wrapperClass: 'col-span-2',
        options: event_statuses.map((status) => ({
          label: status,
          answer: status,
        })),
        labelTooltip: {
          title: 'Success event status',
          content:
            'The status of the event you would like to emit upon success. This is required if you supply a success event name.',
        },
        required: true,
        condition: (path: string, { values }: any) => {
          return values?.content?.on_webhook_success_event_name ? true : false;
        },
      },
      {
        name: 'on_webhook_failure_event_name',
        label: 'Webhook Failure Event Name',
        interface: 'textinput',
        wrapperClass: 'col-span-2',
        labelTooltip: {
          title: 'Failure event name',
          content:
            'If you would like to emit an event when this webhook fails, you can supply it in this optional field.',
        },
        parse: C.parseSlug,
      },
      {
        name: 'on_webhook_failure_event_status',
        label: 'Webhook Failure Event Status',
        interface: 'select',
        wrapperClass: 'col-span-2',
        options: event_statuses.map((status) => ({
          label: status,
          answer: status,
        })),
        labelTooltip: {
          title: 'Failure event status',
          content:
            'The status of the event you would like to emit upon failure. This is required if you supply a failure event name.',
        },
        required: true,
        condition: (path: string, { values }: any) => {
          return values?.content?.on_webhook_failure_event_name ? true : false;
        },
      },
      {
        name: 'content_field',
        label: '',
        description: 'Hello World',
        wrapperClass: 'col-span-4',
        interface: 'paragraph',
        content: (
          <div>
            <div className="headers-title">HTTP Headers</div>
            <div className="headers-desc">
              <p>
                Add key value pairs of http headers you would like to attach to
                your request.
              </p>
            </div>
          </div>
        ),
      },
      {
        title: '',
        name: 'http_headers',
        wrapperClass: 'col-span-4',
        interface: 'array',
        labelTooltip: {
          title: 'HTTP Headers',
          content:
            'Key, value pairs of http headers you would like to be added to the webhook request.',
        },
        buttonText: 'Add Header',
        items: [
          {
            name: 'key',
            label: 'Key',
            interface: 'textinput',
          },
          {
            name: 'value',
            label: 'Value',
            interface: 'textinput',
          },
        ],
      },
      {
        name: 'auth_type',
        label: 'Authorization',
        description:
          'Using this field will override any Authorization Headers you may have set in the headers section above. (Select No Auth if you wish to use a header value or no authentication for this request.)',
        interface: 'select',
        wrapperClass: 'col-span-2',
        required: true,
        options: [
          { label: 'No Auth', value: '' },
          { label: 'Basic Auth', value: 'Basic' },
          { label: 'Bearer Auth', value: 'Bearer' },
        ],
      },
      {
        name: 'auth_value',
        label: 'Authorization Value',
        wrapperClass: 'col-span-2',
        interface: 'textinput',
        condition: (
          _path: string,
          { values }: { values: WebhookFormValues },
        ) => {
          const { content: { auth_type = '' } = {} } = values;
          if (
            auth_type === 'Basic' ||
            auth_type === 'Bearer' ||
            auth_type === 'AWS:ARN'
          ) {
            return true;
          }
          return false;
        },
      },
      {
        name: 'body',
        label: 'Body',
        description: 'Specify the data that will be sent with this request',
        interface: 'textarea',
        wrapperClass: 'col-span-4',
        required: true,
        condition: (
          _path: string,
          { values }: { values: WebhookFormValues },
        ) => {
          const { content: { target_http_method } = {} } = values;
          if (target_http_method && target_http_method !== 'GET') {
            return true;
          }
          return false;
        },
      },
    ],
  },
];
