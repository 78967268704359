import React from 'react';

export default (props) => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      role="graphics-symbol"
      {...props}
    >
      <defs>
        <path
          d="M13.8171159,12.9171874 C13.7471159,12.9878124 13.6664909,13.0371874 13.5727409,13.0684374 L9.82274092,14.3184374 C9.59774092,14.3940624 9.35086592,14.3346874 9.18336592,14.1671874 C9.01586592,13.9996874 8.95711592,13.7528124 9.03211592,13.5278124 L10.2821159,9.7778124 C10.3133659,9.6846874 10.3633659,9.6040624 10.4333659,9.5334374 L19.1833659,0.782812405 C19.2016587,0.764519647 19.2208103,0.74759971 19.240692,0.732052593 C19.6796418,0.313171717 20.2420851,0.00181228417 20.8945313,1.656092e-05 C21.4926563,-0.00248343908 22.0920313,0.278141561 22.5964062,0.788141561 C23.0926563,1.27939156 23.3670312,1.86689156 23.3639062,2.45314156 C23.3639062,2.79189156 23.2757813,3.11501656 23.1332813,3.40189156 C22.9901562,3.69001656 22.7945312,3.94501656 22.5670312,4.17189156 C22.5503341,4.1886743 22.5329214,4.20430741 22.5148912,4.21879088 L13.8171159,12.9171874 Z"
          id="path-3"
        />
      </defs>
      <g
        id="Symbols:-Icons"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <g id="icons/tile-types/black/journal">
          <rect
            id="Container"
            fillOpacity={0}
            fill="#FFFFFF"
            x={0}
            y={0}
            width={24}
            height={24}
          />
          <circle id="Oval-3" fill="#000000" cx={12} cy={12} r={10} />
          <g id="Combined-Shape">
            <use fill="#000000" fillRule="evenodd" xlinkHref="#path-3" />
            <path
              stroke="#FFFFFF"
              strokeWidth="1.5"
              d="M13.3073121,12.3663443 L13.3355701,12.3569249 C13.3192739,12.362357 13.2985124,12.3751386 13.2847444,12.3889078 L13.2867669,12.3868763 L13.3073121,12.3663443 Z M14.3484144,13.446549 C14.1959454,13.599951 14.014604,13.7117191 13.8099117,13.7799499 L10.0616883,15.0293552 C9.56662309,15.1957521 9.021785,15.0662666 8.65303584,14.6975175 C8.28466834,14.32915 8.15576635,13.7851529 8.32060345,13.2906416 L9.57108209,9.53921045 C9.63898086,9.33687211 9.75014444,9.1573533 9.9030169,9.00312626 L18.6530358,0.252482319 C18.692747,0.212771148 18.734704,0.1756409 18.7229106,0.189463888 C19.3397102,-0.399135348 20.080194,-0.747744985 20.8913965,-0.749976888 C21.7007539,-0.753359782 22.4885834,-0.387474838 23.1240444,0.255133092 C23.7590302,0.883721121 24.1181441,1.66011375 24.1139062,2.45314156 C24.1139062,2.89750088 24.0045907,3.33369145 23.8049736,3.73555218 C23.629801,4.08819216 23.3912184,4.40917293 23.0987159,4.70086357 C23.0433119,4.7545167 23.0433119,4.7545167 23.0452402,4.74910202 L14.3484144,13.446549 Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
