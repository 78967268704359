import styled, { createGlobalStyle } from 'styled-components';

export const ConditionItemContainer = styled.div<{ depth: number }>`
  display: flex;
  align-items: center;
  margin-left: ${(props) => props.depth * 25}px;
  margin-bottom: 10px;
  padding-top: 20px;
`;

export const LogicModalStyles = createGlobalStyle`
  .logic-modal {
      max-width: 1100px !important;
      }
`;

export const ConfirmationModalStyles = createGlobalStyle`
  .logic-modal {
    max-width: 700px !important;
    max-height: 200px;
  }
`;
