import React from 'react';
import styled from 'styled-components';

export function PlusIcon() {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="graphics-symbol"
    >
      <path
        d="M6.91406 0.96875H8.08594C8.1901 0.96875 8.24219 1.02083 8.24219 1.125V14.875C8.24219 14.9792 8.1901 15.0312 8.08594 15.0312H6.91406C6.8099 15.0312 6.75781 14.9792 6.75781 14.875V1.125C6.75781 1.02083 6.8099 0.96875 6.91406 0.96875Z"
        fill="white"
      />
      <path
        d="M0.9375 7.25781H14.0625C14.1667 7.25781 14.2188 7.3099 14.2188 7.41406V8.58594C14.2188 8.6901 14.1667 8.74219 14.0625 8.74219H0.9375C0.833333 8.74219 0.78125 8.6901 0.78125 8.58594V7.41406C0.78125 7.3099 0.833333 7.25781 0.9375 7.25781Z"
        fill="white"
      />
    </svg>
  );
}

export function CloseIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="graphics-symbol"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.25 9.01953C0.25 4.1875 4.16797 0.269531 9 0.269531C13.832 0.269531 17.75 4.1875 17.75 9.01953C17.75 13.8516 13.832 17.7695 9 17.7695C4.16797 17.7695 0.25 13.8516 0.25 9.01953ZM1.73438 9.01953C1.73438 13.0312 4.98828 16.2852 9 16.2852C13.0117 16.2852 16.2656 13.0312 16.2656 9.01953C16.2656 5.00781 13.0117 1.75391 9 1.75391C4.98828 1.75391 1.73438 5.00781 1.73438 9.01953ZM11.6457 5.22627C11.7707 5.10131 11.9733 5.10131 12.0983 5.22627L12.7737 5.90173C12.8987 6.02669 12.8987 6.22931 12.7737 6.35427L10.128 9L12.7737 11.6457C12.8987 11.7707 12.8987 11.9733 12.7737 12.0983L12.0983 12.7737C11.9733 12.8987 11.7707 12.8987 11.6457 12.7737L9 10.128L6.35427 12.7737C6.22931 12.8987 6.02669 12.8987 5.90173 12.7737L5.22627 12.0983C5.10131 11.9733 5.10131 11.7707 5.22627 11.6457L7.872 9L5.22627 6.35427C5.10131 6.22931 5.10131 6.02669 5.22627 5.90173L5.90173 5.22627C6.02669 5.10131 6.22931 5.10131 6.35427 5.22627L9 7.872L11.6457 5.22627Z"
        fill="#1A1A1A"
      />
    </svg>
  );
}

export const ConnectionsContainer = styled.div`
  margin-bottom: 1rem;
`;

export const AddButton = styled.button`
  background: #35a76e;
  border-radius: 4px;
  border: 0;
  display: flex;
  align-items: center;
  padding: 0.8rem 1rem 0.8rem 1rem;
  font-weight: 600;
  color: #fff;
  & svg {
    margin-right: 1rem;
  }
`;

export const TransparentBtn = styled.button`
  background: transparent;
  border: none;
`;

export const ConnectionContainer = styled.div`
  background: #fff;
  border: 3px solid #e2e2e2;
  margin-bottom: 1rem;
`;

export const Panel = styled.div`
  padding: 0.5rem;
  font-weight: 600;
  background-color: #e6e6e6;
`;

export const Content = styled.div`
  padding: 1rem;
`;

export const FloatRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
