import { Dropdown, Paginator } from '@evidation/ui';
import { ChevronDown } from '@evidation/ui/lib/icons';
import _ from 'lodash';
import { useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, Tile } from 'src/redux/graph';
import LogicSummaryItem from './logic-summary-item';

import { updateTile as updateTileAction } from 'src/actions/tiles_actions';

export default function LogicSummary({
  history,
  match,
  defaultTile,
  ...rest
}: {
  defaultTile: Tile;
  graphId?: string;
  history: any;
  match: any;
}) {
  const store = useStore();
  const state = store.getState();
  const tile = defaultTile
    ? defaultTile
    : state?.tiles?.[match?.params.identifier];
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const pagesWithLogic = tile?.content?.experiences?.default?.form
    ?.map((page, pageId) => {
      const pageConditions = page?.filter((p) => {
        if (p?.display_condition || p?.skip_logic) {
          return true;
        }
        return false;
      });
      return {
        title: `Page ${pageId + 1}`,
        pageId: pageId + 1,
        conditions: pageConditions,
      };
    })
    .filter((p) => p?.conditions?.length > 0);

  const chunks = _.chunk(pagesWithLogic, 5);

  return (
    <div
      style={{
        background: '#F7F7F7',
        height: 'auto',
        minHeight: '100%',
        padding: '1rem',
      }}
    >
      <div
        style={{
          fontSize: '24px',
          fontWeight: 500,
          paddingTop: '1rem',
          paddingBottom: '1rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        Logic
        <Dropdown
          text={
            <>
              Add Logic
              <ChevronDown />
            </>
          }
        >
          <Link className="menu-item" to={`logic/add-edit?type=skip`}>
            Add Skip Logic
          </Link>
          <Link className="menu-item" to={`logic/add-edit?type=display`}>
            Add Display Logic
          </Link>
        </Dropdown>
      </div>
      {chunks?.length === 0 && <div>No logic found on this survey</div>}
      {chunks?.[currentPage]?.map((page: any, pageId: number) => {
        if (page.conditions?.length > 0) {
          return (
            <div key={`logic_summary_page_${pageId}`}>
              <span
                style={{
                  display: 'block',
                  marginTop: '.5rem',
                  marginBottom: '.5rem',
                }}
              >
                {page.title}
              </span>

              {page?.conditions?.map((question: Form, questionId: number) => (
                <div
                  key={`logic_summary_page_${pageId}_question_${questionId}}`}
                  style={{
                    background: '#fff',
                    borderRadius: 4,
                    padding: '1rem',
                  }}
                >
                  <LogicSummaryItem
                    question={question}
                    tile={tile}
                    pageId={page.pageId}
                    updateTile={(v: Tile) => dispatch(updateTileAction(v))}
                    history={history}
                    match={match}
                  />
                </div>
              ))}
            </div>
          );
        }
        return null;
      })}
      <div
        style={{
          padding: '1rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Paginator
          totalCount={chunks?.length * 5 ?? 0}
          pageSize={5}
          getCurrentPage={setCurrentPage}
        />
        <Dropdown
          text={
            <>
              Add Logic
              <ChevronDown />
            </>
          }
        >
          <Link className="menu-item" to={`logic/add-edit?type=skip`}>
            Add Skip Logic
          </Link>
          <Link className="menu-item" to={`logic/add-edit?type=display`}>
            Add Display Logic
          </Link>
        </Dropdown>
      </div>
    </div>
  );
}
