import { Dropdown, Flex } from '@evidation/ui';
import { useState } from 'react';
import { useStore } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Link } from 'react-router-dom';
import { apiGraph } from 'src/api';
import { find_sequence_from_tile } from 'src/middlewares/slate_middleware';
import { Form, Tile } from 'src/redux/graph';
import stripHtml from 'src/utils/stripHtml';

import { OPERATORS_OPTIONS } from '../add-edit/logic-item';
import {
  buildLogicLink,
  findQuestionAnswers,
  findTileQuestion,
  findTileQuestionPosition,
  SimpleCondition,
  transformIncoming,
} from '../utils';

const operatorToHumanReadable = (operator: string) => {
  const operatorOption = OPERATORS_OPTIONS.find((v) => v.value === operator);

  if (operatorOption) {
    return operatorOption.label;
  }
  return operator;
};

const getTargetComponent = (tile: Tile, target_id: string) => {
  return tile.content.experiences?.default.form
    ?.flatMap((page) => page)
    .find((component) => component.component_id === target_id);
};

export default function LogicSummaryItem({
  question,
  tile,
  match,
  updateTile,
  pageId,
}: {
  question: Form;
  tile: Tile;
  history: History;
  match: any;
  updateTile: any;
  pageId: number;
}) {
  const [loading, setLoading] = useState(false);
  const store = useStore();
  const state = store.getState();
  const flattenedConditions = transformIncoming(
    tile,
    question,
    question?.skip_logic ? 'skip' : 'display',
  );

  const handleDeleteLogic = async () => {
    setLoading(true);
    const [pageIdx, itemIdx] = findTileQuestionPosition(
      tile,
      question.component_id!,
    );
    // update the question in place and save.
    const working = { ...tile };
    //@ts-expect-error
    working.content.experiences.default.form[pageIdx][itemIdx] = {
      ...working?.content?.experiences?.default?.form[pageIdx][itemIdx],
      skip_logic: undefined,
      display_condition: undefined,
    };

    // get the sequence id for the api call
    const sequence_uuid = find_sequence_from_tile(
      state,
      'sequences',
      match?.params?.identifier,
    );
    try {
      await apiGraph.update_tile(
        match?.params?.graph_id,
        sequence_uuid,
        match?.params?.identifier,
        working,
      );
      updateTile(working);
      toastr.success('Success', 'Logic rule deleted');
    } catch (err: unknown) {
      toastr.error('Error', 'Unable to delete logic rule');
    } finally {
      setLoading(false);
    }
  };

  const getValueOrAnswerChoice = (condition: SimpleCondition) => {
    if (
      condition.targetType === 'answer_choice' ||
      condition.sourceTargetType === 'answer_choice'
    ) {
      if (Array.isArray(condition.value)) {
        const targetComponent = condition.user_characteristic_target
          ? getTargetComponent(tile, condition.user_characteristic_target)
          : question;
        const answers = condition.value.map(
          (v) =>
            targetComponent?.possible_answers?.find((pa) => v === pa.answer) ||
            targetComponent?.mutually_exclusive_answers?.find(
              (ma) => v === ma.answer,
            ),
        );
        return answers?.map((v) => stripHtml(v?.label))?.join(',');
      }
      const q = findTileQuestion(tile, condition.target_name!);
      const possibleAnswers = findQuestionAnswers(q);
      if (possibleAnswers?.length > 0) {
        const answer = possibleAnswers.find((v) => v.value === condition.value);
        return stripHtml(answer?.label);
      }
    }
    return condition.value;
  };
  return (
    <div>
      <div
        style={{
          fontWeight: 500,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '1rem',
          marginTop: '1rem',
          opacity: loading ? 0.5 : 1,
          pointerEvents: loading ? 'none' : undefined,
        }}
      >
        <Flex items="center">
          {' '}
          {question.skip_logic ? (
            <svg
              width="24"
              height="20"
              viewBox="0 0 24 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              role="graphics-symbol"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.1275 4.3307L21.799 10.8139L16.8303 6.42977L19.0622 5.65923C17.3352 3.63987 14.6781 2.41763 11.8351 2.41763C6.78669 2.41763 2.69484 6.19064 2.69484 10.8139C2.69484 11.2656 2.32286 11.6376 1.87116 11.6376C1.41946 11.6376 1.04748 11.2656 1.04748 10.8139C1.04748 5.28724 5.8833 0.770264 11.8351 0.770264C13.7747 0.770264 15.6612 1.24853 17.3086 2.15193C18.6637 2.8959 19.8328 3.90558 20.7362 5.12782L23.1275 4.3307ZM2.83636 19.2299C4.40284 19.2299 5.67273 17.9601 5.67273 16.3936C5.67273 14.8271 4.40284 13.5572 2.83636 13.5572C1.26988 13.5572 0 14.8271 0 16.3936C0 17.9601 1.26988 19.2299 2.83636 19.2299ZM12 18.0481C12.9138 18.0481 13.6545 17.3074 13.6545 16.3936C13.6545 15.4798 12.9138 14.739 12 14.739C11.0862 14.739 10.3454 15.4798 10.3454 16.3936C10.3454 17.3074 11.0862 18.0481 12 18.0481ZM12 19.2299C13.5665 19.2299 14.8363 17.9601 14.8363 16.3936C14.8363 14.8271 13.5665 13.5572 12 13.5572C10.4335 13.5572 9.16361 14.8271 9.16361 16.3936C9.16361 17.9601 10.4335 19.2299 12 19.2299ZM21.1636 19.2299C22.7301 19.2299 24 17.9601 24 16.3936C24 14.8271 22.7301 13.5572 21.1636 13.5572C19.5971 13.5572 18.3272 14.8271 18.3272 16.3936C18.3272 17.9601 19.5971 19.2299 21.1636 19.2299Z"
                fill="#1A1A1A"
              />
            </svg>
          ) : (
            <svg
              width="18"
              height="22"
              viewBox="0 0 18 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              role="graphics-symbol"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.50769 5.83538C4.79324 5.83538 5.83538 4.79324 5.83538 3.50769C5.83538 2.22214 4.79324 1.18 3.50769 1.18C2.22214 1.18 1.18 2.22214 1.18 3.50769C1.18 4.79324 2.22214 5.83538 3.50769 5.83538ZM7.01538 3.50769C7.01538 5.27785 5.70416 6.74175 4 6.98111V15.0189C5.70416 15.2582 7.01538 16.7222 7.01538 18.4923C7.01538 18.5313 7.01475 18.5701 7.01349 18.6087C8.44968 18.2375 9.91389 17.6704 10.7425 16.8083C11.9874 15.5131 13.0003 14.0298 13.1849 12.1221C11.5637 11.8129 10.3384 10.3879 10.3384 8.67664C10.3384 6.73939 11.9088 5.16895 13.8461 5.16895C15.7833 5.16895 17.3538 6.73939 17.3538 8.67664C17.3538 10.4907 15.9767 11.9832 14.2108 12.1656C13.9952 14.5778 12.4983 16.5018 11.4998 17.5002C10.7465 18.2535 8.70711 19.1257 6.81721 19.6575C6.33682 21.022 5.03647 22 3.50769 22C1.57045 22 0 20.4296 0 18.4923C0 16.7275 1.30334 15.2671 3 15.0211V6.97891C1.30334 6.73293 0 5.27252 0 3.50769C0 1.57045 1.57045 0 3.50769 0C5.44494 0 7.01538 1.57045 7.01538 3.50769ZM16.1738 8.67664C16.1738 9.96219 15.1316 11.0043 13.8461 11.0043C12.5605 11.0043 11.5184 9.96219 11.5184 8.67664C11.5184 7.39109 12.5605 6.34895 13.8461 6.34895C15.1316 6.34895 16.1738 7.39109 16.1738 8.67664Z"
                fill="#1A1A1A"
              />
            </svg>
          )}
          <div style={{ marginLeft: '1rem' }}>{stripHtml(question?.label)}</div>
        </Flex>
        <Dropdown
          variant="ghost"
          text={
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              role="graphics-symbol"
            >
              <path
                d="M3 11.9766C3 12.1735 3.0388 12.3686 3.11418 12.5506C3.18956 12.7326 3.30005 12.8979 3.43934 13.0372C3.57863 13.1765 3.74399 13.287 3.92598 13.3624C4.10796 13.4378 4.30302 13.4766 4.5 13.4766C4.69698 13.4766 4.89204 13.4378 5.07403 13.3624C5.25601 13.287 5.42137 13.1765 5.56066 13.0372C5.69995 12.8979 5.81044 12.7326 5.88582 12.5506C5.9612 12.3686 6 12.1735 6 11.9766C6 11.7796 5.9612 11.5845 5.88582 11.4025C5.81044 11.2205 5.69995 11.0552 5.56066 10.9159C5.42137 10.7766 5.25601 10.6661 5.07403 10.5907C4.89204 10.5154 4.69698 10.4766 4.5 10.4766C4.30302 10.4766 4.10796 10.5154 3.92598 10.5907C3.74399 10.6661 3.57863 10.7766 3.43934 10.9159C3.30005 11.0552 3.18956 11.2205 3.11418 11.4025C3.0388 11.5845 3 11.7796 3 11.9766ZM10.5 11.9766C10.5 12.3744 10.658 12.7559 10.9393 13.0372C11.2206 13.3185 11.6022 13.4766 12 13.4766C12.3978 13.4766 12.7794 13.3185 13.0607 13.0372C13.342 12.7559 13.5 12.3744 13.5 11.9766C13.5 11.5787 13.342 11.1972 13.0607 10.9159C12.7794 10.6346 12.3978 10.4766 12 10.4766C11.6022 10.4766 11.2206 10.6346 10.9393 10.9159C10.658 11.1972 10.5 11.5787 10.5 11.9766ZM18 11.9766C18 12.3744 18.158 12.7559 18.4393 13.0372C18.7206 13.3185 19.1022 13.4766 19.5 13.4766C19.8978 13.4766 20.2794 13.3185 20.5607 13.0372C20.842 12.7559 21 12.3744 21 11.9766C21 11.5787 20.842 11.1972 20.5607 10.9159C20.2794 10.6346 19.8978 10.4766 19.5 10.4766C19.1022 10.4766 18.7206 10.6346 18.4393 10.9159C18.158 11.1972 18 11.5787 18 11.9766Z"
                fill="black"
                fillOpacity="0.88"
              />
            </svg>
          }
        >
          <Link
            to={buildLogicLink(
              match,
              pageId,
              question.name,
              question?.skip_logic,
            )}
            className="menu-item"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              style={{ marginRight: 4 }}
              xmlns="http://www.w3.org/2000/svg"
              role="graphics-symbol"
            >
              <path
                d="M3.0332 12.6875C3.07227 12.6875 3.11133 12.6836 3.15039 12.6777L6.43555 12.1016C6.47461 12.0938 6.51172 12.0762 6.53906 12.0469L14.8184 3.76758C14.8365 3.74951 14.8508 3.72805 14.8606 3.70442C14.8704 3.68079 14.8755 3.65546 14.8755 3.62988C14.8755 3.6043 14.8704 3.57897 14.8606 3.55535C14.8508 3.53172 14.8365 3.51026 14.8184 3.49219L11.5723 0.244141C11.5352 0.207031 11.4863 0.1875 11.4336 0.1875C11.3809 0.1875 11.332 0.207031 11.2949 0.244141L3.01562 8.52344C2.98633 8.55273 2.96875 8.58789 2.96094 8.62695L2.38477 11.9121C2.36577 12.0167 2.37255 12.1244 2.40454 12.2258C2.43654 12.3273 2.49276 12.4193 2.56836 12.4941C2.69727 12.6191 2.85938 12.6875 3.0332 12.6875ZM4.34961 9.28125L11.4336 2.19922L12.8652 3.63086L5.78125 10.7129L4.04492 11.0195L4.34961 9.28125ZM15.1875 14.3281H0.8125C0.466797 14.3281 0.1875 14.6074 0.1875 14.9531V15.6562C0.1875 15.7422 0.257812 15.8125 0.34375 15.8125H15.6562C15.7422 15.8125 15.8125 15.7422 15.8125 15.6562V14.9531C15.8125 14.6074 15.5332 14.3281 15.1875 14.3281Z"
                fill="#1A1A1A"
              />
            </svg>
            Edit Logic
          </Link>
          <button className="menu-item" onClick={handleDeleteLogic}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              style={{ marginRight: 4 }}
              xmlns="http://www.w3.org/2000/svg"
              role="graphics-symbol"
            >
              <path
                d="M7.03125 3.59375H6.875C6.96094 3.59375 7.03125 3.52344 7.03125 3.4375V3.59375H12.9688V3.4375C12.9688 3.52344 13.0391 3.59375 13.125 3.59375H12.9688V5H14.375V3.4375C14.375 2.74805 13.8145 2.1875 13.125 2.1875H6.875C6.18555 2.1875 5.625 2.74805 5.625 3.4375V5H7.03125V3.59375ZM16.875 5H3.125C2.7793 5 2.5 5.2793 2.5 5.625V6.25C2.5 6.33594 2.57031 6.40625 2.65625 6.40625H3.83594L4.31836 16.6211C4.34961 17.2871 4.90039 17.8125 5.56641 17.8125H14.4336C15.1016 17.8125 15.6504 17.2891 15.6816 16.6211L16.1641 6.40625H17.3438C17.4297 6.40625 17.5 6.33594 17.5 6.25V5.625C17.5 5.2793 17.2207 5 16.875 5ZM14.2832 16.4062H5.7168L5.24414 6.40625H14.7559L14.2832 16.4062Z"
                fill="#1A1A1A"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.90625 9.8974V8.39203C8.90625 8.35977 8.83594 8.33337 8.75 8.33337H7.65625C7.57031 8.33337 7.5 8.35977 7.5 8.39203V10.3666V13.436V14.9414C7.5 14.9736 7.57031 15 7.65625 15H8.75C8.83594 15 8.90625 14.9736 8.90625 14.9414V12.9668V9.8974Z"
                fill="#1A1A1A"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.2395 9.8974V8.39203C12.2395 8.35977 12.1692 8.33337 12.0833 8.33337H10.9895C10.9036 8.33337 10.8333 8.35977 10.8333 8.39203V10.3666V13.436V14.9414C10.8333 14.9736 10.9036 15 10.9895 15H12.0833C12.1692 15 12.2395 14.9736 12.2395 14.9414V12.9668V9.8974Z"
                fill="#1A1A1A"
              />
            </svg>
            Remove Logic
          </button>
        </Dropdown>
      </div>
      {flattenedConditions?.conditions?.map((condition, conditionIdx) => {
        return (
          <div
            key={`question_${question.component_id}_complex_condition_${conditionIdx}`}
            style={{ marginLeft: 60 * condition.depth }}
          >
            {question.display_condition && (
              <>
                <span style={{ fontWeight: 700, color: '#017A47' }}>
                  Display
                </span>{' '}
                <span>
                  <strong>Question {stripHtml(question.label)}</strong>{' '}
                </span>
              </>
            )}
            <br />
            If <strong>
              {condition.operator === 'and' ? 'ALL' : 'ANY'}
            </strong>{' '}
            of the following are true
            {condition.conditions?.map(
              (subCondition, subConditionId: number) => {
                return (
                  <div
                    style={{
                      marginLeft:
                        condition.depth > 0 ? 60 * condition.depth : 60,
                      marginTop: 8,
                      marginBottom: 8,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    key={`question_${question.component_id}_complex_condition_${conditionIdx}_simple_condition_${subConditionId}`}
                  >
                    <p>
                      <strong style={{ textTransform: 'capitalize' }}>
                        {subCondition?.sourceTargetType &&
                          subCondition.targetType?.replace('_', ' ')}{' '}
                      </strong>{' '}
                      {subCondition.targetType === 'user-characteristic' && (
                        <span
                          style={{
                            textDecoration: 'underline',
                          }}
                        >
                          {subCondition.target_name}
                        </span>
                      )}{' '}
                      <span style={{ textDecoration: 'underline' }}>
                        {stripHtml(
                          findTileQuestion(tile, subCondition.target_name)?.form
                            ?.label,
                        )}
                      </span>{' '}
                      {operatorToHumanReadable(subCondition?.operator)}{' '}
                      <strong>{getValueOrAnswerChoice(subCondition)}</strong>
                    </p>
                  </div>
                );
              },
            )}
          </div>
        );
      })}
      {question.skip_logic && (
        <>
          <span style={{ fontWeight: 700, color: '#017A47' }}>SKIP TO</span>{' '}
          <span>
            <strong>Question </strong>{' '}
            {question.skip_logic?.skip_to?.component_id &&
              stripHtml(
                findTileQuestion(
                  tile,
                  question.skip_logic?.skip_to?.component_id,
                )?.form?.label,
              )}
          </span>
        </>
      )}
    </div>
  );
}
