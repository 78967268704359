export default {
  label: 'Draft',
  description: (
    <p>
      The 'Draft' status signifies that a new major project version is being
      configured. Changes to the configuration are expected, and Billboard can
      only be accessed as a preview using a preview token. Projects in this
      status cannot be used for recruiting.
    </p>
  ),
  arrow: (
    <ul>
      <li>
        Schedules are activated for the study and participants will start
        receiving scheduled events
      </li>
      <li>
        Only users with evidation.com and evidationtest.com email addresses will
        be allowed to login
      </li>
      <ul>
        <li>Other users will be denied login</li>
      </ul>
      <li>Participant contributions are allowed</li>
      <li>Preview token is needed to access Billboard</li>
      <li>Ordinal events will be generated for Fitbit data</li>
    </ul>
  ),
};
