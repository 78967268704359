import Settings from './settings';
import incoming from './incoming';
import outgoing from './outgoing';
import RedirectUserForm from './redirect_user_form';

export default {
  form: {
    // Components: () => [],
    Settings,
  },
  Component: RedirectUserForm,
  incoming,
  outgoing,
};
