import { AuthWrap } from './auth';
import Icon from '@evidation/icon';
import IconLibrary from './icon/IconLibrary';
import NavIconList from '../assets/NavIconList';
import { object, array } from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { Button } from '@evidation/ui';
import { useFeatureFlags } from 'src/features/workspaces/hooks/useFeatureFlags';

const defaultColor = '#8a8e94';
const selectedColor = '#000000';

export const StyledStudyMenu = styled.div`
  grid-area: sidebar;

  background-color: #f4f4f4;
  border-right: 1px solid #e9e9e9;
  color: #8a8e94;
  font-family: 'BrandonGrotesque';
  font-size: 0.5625rem;
  min-height: 100%;
  position: absolute;
  text-align: center;
  text-transform: uppercase;

  > ul {
    width: 5rem;
    pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
    > li {
      cursor: pointer;
      padding: 0.5rem;
      position: relative;

      > div {
        margin: 1rem auto 0.5rem;

        &.text {
          margin: 0;
          border-bottom: 2px solid #f4f4f4;
        }
      }

      &.selected-true {
        color: #000000;

        > div.text {
          border-bottom: 2px solid #1de7a7;
        }
      }
    }
  }
`;

const renderTitle = (title) => {
  if (!title) {
    return null;
  }

  return <div className="text">{title}</div>;
};

const inArray = (haystack = [], needle) => {
  const needle_list = needle.split('/');
  needle_list.splice(0, 3);
  needle = needle_list.join('/');
  needle = needle.split('?')[0];

  return (
    _.filter(haystack, (path) => {
      return _.startsWith(needle, path);
    }).length > 0
  );
};

const isSelected = (option, currentPath = ``) =>
  inArray(option.activeCheck, currentPath);

const excludedFeatureFlags = ['slate', 'overview', 'settings'];

const StudyMenu = ({ disabled = false, menuOptions, location }) => {
  const { features } = useFeatureFlags();

  return (
    <StyledStudyMenu disabled={disabled}>
      <IconLibrary list={NavIconList}>
        <ul>
          {menuOptions.map((menuOption, key) => {
            const isActive = isSelected(menuOption, location.pathname);
            const isInFeatureFlagList = features.some(
              (feature) =>
                feature.name === menuOption.id ||
                excludedFeatureFlags.includes(menuOption.id),
            );

            if (!isInFeatureFlagList) return null;

            return (
              <AuthWrap
                key={`study-menu-${key}`}
                requiredActions={menuOption.requiredActions}
              >
                <li key={`study-menu-${key}`}>
                  <Button
                    variant="ghost"
                    id={`t-navLink_${_.snakeCase(menuOption.title)}`}
                    onClick={() =>
                      _.has(menuOption, `onClick`)
                        ? menuOption.onClick()
                        : _.noop()
                    }
                    style={{
                      flexDirection: 'column',
                      width: '100%',
                      fontSize: 12,
                    }}
                    className={`${isActive ? 'active' : ''}`}
                  >
                    <Icon
                      name={menuOption.icon}
                      color={isActive ? selectedColor : defaultColor}
                      size="20px"
                    />

                    {renderTitle(menuOption.title)}
                  </Button>
                </li>
              </AuthWrap>
            );
          })}
        </ul>
      </IconLibrary>
    </StyledStudyMenu>
  );
};

StudyMenu.defaultProps = {
  option: { activeCheck: [] },
  menuOptions: [],
};

StudyMenu.propTypes = {
  menuOptions: array,
  location: object.isRequired,
};

export default withRouter(StudyMenu);
