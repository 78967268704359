import { logError } from 'src/utils/errorLogger';
import { Select, Spacer } from '@evidation/ui';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { toastr } from 'react-redux-toastr';

import { SettingsHeading } from 'src/components/fields/components';

import Castor from './castor';
import { IntegrationRef, IntegrationComponent } from './common';

/**
 * Represents all of the possible integrations. Add new ones as needed.
 */
const integrations = ['castor'] as const;
type Integration = typeof integrations[number];

/**
 * Maps integrations to the components that are rendered when that integration
 *   is selected. See the documentation for `IntegrationComponent` for more info
 *   about what an integration component needs to implement.
 */
const componentsByIntegration: Record<Integration, IntegrationComponent> = {
  castor: Castor,
};

/**
 * Maps integrations to their string labels. Used to build the dropdown options.
 */
const integrationLabels: Record<Integration, string> = {
  castor: 'Castor',
};

type IntegrationsProps = {
  onChange?: (data: { hasChanged: boolean }) => void;
};

const Integrations: React.ForwardRefRenderFunction<
  IntegrationRef,
  IntegrationsProps
> = ({ onChange }, ref) => {
  const [selectedIntegration, setSelectedIntegration] =
    useState<Integration>('castor');
  const IntegrationComponent = componentsByIntegration[selectedIntegration];

  const [hasIntegrationChanged, setHasIntegrationChanged] = useState(false);

  useEffect(() => {
    onChange?.({ hasChanged: hasIntegrationChanged });
  }, [hasIntegrationChanged, onChange]);

  const componentRef = useRef<IntegrationRef | null>(null);

  useImperativeHandle(ref, () => ({
    submit: async () => {
      try {
        if (componentRef.current?.submit) {
          await componentRef.current.submit();
        }
      } catch (error) {
        toastr.error('Failed to save integration settings', '');
        logError(error);
      }
    },
  }));

  return (
    <>
      <SettingsHeading>Integrations</SettingsHeading>
      <Select
        label="Integration Setup"
        options={integrations.map((integration) => ({
          label: integrationLabels[integration],
          value: integration,
        }))}
        value={selectedIntegration}
        onChange={(option) => setSelectedIntegration(option.value)}
      />
      <Spacer size={20} />
      <IntegrationComponent
        onChange={({ hasChanged }) => {
          setHasIntegrationChanged(hasChanged);
        }}
        ref={componentRef}
      />
    </>
  );
};

export default () => ({
  Component: forwardRef(Integrations),
  shouldShowSaveButton: true,
});
