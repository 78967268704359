import React from 'react';

export default (props) => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="graphics-symbol"
      {...props}
    >
      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g
          stroke="#000"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <polygon points="20.75 5 12 12.5 3.25 5" />
          <path d="M12.625 17.3h-8.75c-.688 0-1.25-.562-1.25-1.25v-10c0-.687.563-1.25 1.25-1.25h16.25c.688 0 1.25.563 1.25 1.25v5" />
          <polygon points="15.125 19.625 21.375 19.625 21.375 15.25 15.125 15.25" />
          <path d="M16.375 15.072V14a1.876 1.876 0 0 1 3.75 0v.934" />
        </g>
      </g>
    </svg>
  );
};
