export default {
  label: 'Live - Recruitment Closed',
  description: (
    <>
      <p>
        The 'Enrollment Closed' status indicates the project has met enrollment
        targets. It:
      </p>
      <ul>
        <li>Closes the landing page and prevents further enrollment.</li>
        <li>Transitions participant statuses:</li>
        <ul>
          <li>Screening/Rescreening → Screen Failed (Reason: TBD).</li>
          <li>Consented → Early Termination (Reason: TBD).</li>
          <li>Other statuses unchanged.</li>
        </ul>
        <li>
          Removes PII/data for Screening/Rescreening/Screen Failed participants.
        </li>
        <li>Production data flows to the Production folder.</li>
      </ul>
    </>
  ),
  arrow: (
    <ul>
      <li>
        Schedules will be cancelled and participants will not receive scheduled
        events
      </li>
      <li>
        No new contributions will be accepted via externally available
        endpoints. (process_contributions and external_contributions )
      </li>
      <li>Ordinal events will not be generated</li>
    </ul>
  ),
  rightArrow: (
    <ul>
      <li>No caps are reset.</li>
      <li>Landing page opens up for participants to create accounts.</li>
    </ul>
  ),
};
