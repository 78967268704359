import { useState, useEffect, useCallback, useMemo, FC } from 'react';

import { IconContainer } from '../tile/container';
import { Nodes } from '../../../../constants';
import { SequenceAction } from '..';
import { TextInput } from '@evidation/form-elements';
import _ from 'lodash';
import styled from 'styled-components';
import { Flex, LoadingIndicator } from '@evidation/ui';
import { useFeatureFlags } from 'src/features/workspaces/hooks/useFeatureFlags';

const ListOfTiles = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;

const ListItem = styled.li<{ active: boolean }>`
  font-size: 10px;
  color: #111;
  flex: 1 0 50%;
  display: flex;
  align-items: center;
  height: 32px;
  display: flex;
  align-content: center;
  background: ${(props) => (props.active ? `#ececec` : `transparent`)};

  &:last-child {
    align-items: center;
  }

  &:hover {
    background: #ececec;
  }
`;

const Container = styled(SequenceAction)`
  padding: 10px 12px 15px;
  position: absolute;
  background: #fff;
  top: -10px;
  left: -10px;
  bottom: -10px;
  right: -10px;
  border-radius: 5px !important;
  height: max-content;
  box-shadow: 0 0 10px 2px #111;
  margin-bottom: 30px;
  z-index: 1;
  background-color: #fff;
`;

interface SelectTypeProps {
  selectedType: string;
  tileType: { [key: string]: any };
  onSelect: (key: string) => void;
  cancel: () => void;
}

const SelectType: FC<SelectTypeProps> = ({
  selectedType = '',
  tileType = Nodes,
  onSelect,
  cancel,
}) => {
  const [filter, setFilter] = useState(selectedType);
  const [activelySelected, setActivelySelected] = useState(0);

  const { isLoading, tiles } = useFeatureFlags();

  const tilesNames = useMemo(() => {
    return tiles
      .map((tile) => tile.name)
      .filter((key) => {
        if (key !== `emit_event` && key !== 'email_message') {
          const { title = '' } = tileType[key];
          return (
            title.toLowerCase().includes(filter.toLowerCase()) ||
            key.includes(filter.toLowerCase())
          );
        }
        return false;
      })
      .sort();
  }, [tiles, filter, tileType]);

  const onKeyboardAction = useCallback(
    (e) => {
      const currentFilter = tilesNames;

      // esc = 27
      // enter = 13

      // 40 down arrow
      // 38 up arrow

      if (e.keyCode === 40) {
        // down arrow
        const next_activelySelected =
          activelySelected !== currentFilter.length - 1
            ? activelySelected + 1
            : 0;
        setActivelySelected(next_activelySelected);
      }

      if (e.keyCode === 38) {
        // up arrow
        const next_activelySelected =
          activelySelected === 0
            ? currentFilter.length - 1
            : activelySelected - 1;
        setActivelySelected(next_activelySelected);
      }

      if (e.keyCode === 27) {
        cancel();
      }

      if (e.keyCode === 13) {
        if (!_.isEmpty(currentFilter)) {
          onSelect(currentFilter[activelySelected]);
        }
      }
    },
    [activelySelected, cancel, tilesNames, onSelect],
  );

  useEffect(() => {
    window.addEventListener('keyup', onKeyboardAction);
    return () => window.removeEventListener('keyup', onKeyboardAction);
  }, [onKeyboardAction]);

  if (isLoading)
    return (
      <Flex items="center" justify="center" style={{ padding: 20 }}>
        <LoadingIndicator />
      </Flex>
    );

  return (
    <Container data-test="selectingType">
      <TextInput
        data-test="searchTileType"
        value={filter}
        onChange={({ target: { value: filter } }) => setFilter(filter)}
        inputStyle="sm_minimal"
      />
      <ListOfTiles data-test="listOfTyles">
        {tilesNames.map((key, index) => {
          const { title, icon } = tileType[key];
          return (
            <ListItem
              key={key}
              onClick={() => onSelect(key)}
              active={activelySelected === index}
              data-test={_.snakeCase(title).replace(/_/, '-')}
            >
              {icon && (
                <IconContainer style={{ paddingTop: 0 }}>
                  {icon()}
                </IconContainer>
              )}
              <span>{title}</span>
            </ListItem>
          );
        })}
      </ListOfTiles>
    </Container>
  );
};

export default SelectType;
