import * as C from '../common';

import _ from 'lodash';
import { default_experience_key } from '../../../../constants';

export const branchStrategyOptions = [
  { label: `random`, value: `random` },
  { label: `conditional`, value: `conditional` },
  { label: `list randomization`, value: `list` },
];

const onCapOverflowOptions = [
  { label: 'Queue assignments', value: 'queue_assignments' },
  { label: 'Exclude branch', value: 'exclude_branch' },
];

export const branchMapping = _.reduce(
  branchStrategyOptions,
  (r, opt) => ({ ...r, [opt.value]: opt.label }),
  {},
);

export const branchingStrategies = _.map(branchStrategyOptions, 'value');

const DisplayIfStrategy = (path, { values }, strategy) => {
  if (values) {
    const toPath = path
      .slice(0, 1)
      .concat(`strategy`)
      .join(`.`);

    return strategy.includes(_.get(values, toPath));
  }
};

export default ({
  arms,
  initialValues,
  nodeSlugs,
  experience = default_experience_key,
  ...rest
}) => {
  return [
    [
      {
        name: `content`,
        input: [
          C.title,
          {
            name: `strategy`,
            interface: `selectadvanced`,
            inputStyle: `muted`,
            label: `Select Branching Strategy`,
            options: branchStrategyOptions,
          },
          {
            name: `on_cap_overflow`,
            interface: `selectadvanced`,
            inputStyle: `muted`,
            label: `On Cap Overflow`,
            options: onCapOverflowOptions,
            initialValue: onCapOverflowOptions[1],
            condition: (path, state) =>
              DisplayIfStrategy(path, state, [`list`, `random`]),
          },
          {
            name: `condition`,
            interface: `textinput`,
            label: `Condition`,
            required: true,
            condition: (path, state) =>
              DisplayIfStrategy(path, state, [`conditional`]),
          },
          {
            name: `default_arm`,
            label: `Default Arm`,
            interface: `selectinput`,
            options: _.reduce(arms, (acc, { name }) => [...acc, name], []),
            condition: (path, state) =>
              DisplayIfStrategy(path, state, [`conditional`]),
          },
          {
            name: `batch_size`,
            interface: `NumberInput`,
            label: `Batch Size`,
            required: true,
            condition: (path, state) =>
              DisplayIfStrategy(path, state, [`random`]),
          },
          {
            name: `arm_names`,
            interface: `flatarray`,
            label: `Arm Names`,
            required: true,
            condition: (path, state) =>
              DisplayIfStrategy(path, state, [`list`]),
          },
        ],
      },
    ],
  ];
};
