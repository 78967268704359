export default {
  label: 'Live - Recruiting',
  description: (
    <p>
      The 'Recruiting' status signifies the major project version is actively
      recruiting participants (launched). Configuration changes should be rare.
      Billboard access is live without needing a preview token. Transitioning to
      this status marks prior records as test entries and deletes them.
      Production data gets stored in the Production folder on the Data Platform.
    </p>
  ),
  arrow: (
    <ul>
      <li>
        All enrollments in screening and screening complete aasm_status will be
        marked as disqualified
      </li>
      <li>
        Landing page closes and no new enrollments will be allowed to be created
      </li>
      <li>Ordinal events will be generated for Fitbit data</li>
    </ul>
  ),
};
