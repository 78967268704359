import styled from 'styled-components';
// https://achievements.atlassian.net/browse/SPP-1598
import { Button as DefaultButton } from '@evidation/form-elements';

export const Container = styled.div`
  hr {
    border: 0;
    border-bottom: 1px solid #e7e7e7;
  }
`;
export const NoDataMessage = styled.div`
  font-size: 2rem;
  font-weight: 900;
  color: #e7e7e7;
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  /* padding: 1rem 2rem 1rem 2rem; */
  h1 {
    font-size: 1.2rem;
    font-weight: 900;
  }
  .spacer {
    margin-right: auto;
  }
`;
export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const WebhookCard = styled.div`
  box-shadow: 2px 2px 2px 2px #e7e7e7;
  border-radius: 4px;
  .spacer {
    margin-right: auto;
  }

  .btn-transparent {
    background-color: transparent;
    border: 0;
    color: #162d6d;
  }

  .dd-btn {
    background-color: transparent;
    border: 0;
    width: 100%;
  }

  .btn-transparent:hover {
    background-color: #e7e7e7;
    color: #5764a7;
    border-radius: 4px;
  }
  .context-menu {
    display: flex;
    align-items: center;
    padding: 1rem;
  }

  .remove-webhook {
    color: #be322e !important;
  }
  .remove-webhook:hover {
    color: #d06766;
  }

  .heading {
    padding: 1rem;
    border-bottom: 1px solid #e7e7e7;
    display: flex;
    align-items: center;

    .title {
      font-size: 1rem;
      font-weight: 600;
      cursor: pointer;
    }
  }
  .content {
    overflow-wrap: break-word;
    padding: 1rem;

    .text-label {
      font-weight: 600;
      font-size: ${12 / 16}rem;
    }
    div {
      margin-bottom: 0.3rem;
    }
    table {
      width: 100%;
      padding: 0.5rem;
      thead {
        background-color: #e7e7e7;
      }
      tr {
        padding: 1rem;
      }
      td {
        padding: 0.2rem;
      }

      td:not(:last-child) {
        border-right: 1px solid #e7e7e7;
      }
      td:not(:first-child) {
        border-top: 1px solid #e7e7e7;
      }
    }
  }
  .mono {
    font-family: monospace;
    font-size: 12px !important;
  }

  .segment {
    background-color: #e7e7e7;
  }
`;

export const Label = styled.div`
  background-color: #9e47b2;
  color: #fff;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  padding: 0.2rem;
`;

export const Grid = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(3, minmax(0, 1fr));
`;

export const Button = styled(DefaultButton)`
  height: auto;
  margin-top: 1rem;
  background-color: #162d6d !important;
  border-radius: 4px !important;
  color: #fff !important;
  font-size: 0.8rem !important;

  &:hover {
    background-color: #162d6d !important;
  }
`;
export const Alert = styled.div<{ rounded?: boolean }>`
  padding: 1rem;
  color: #fff;
  border-radius: ${(props) => (props.rounded ? '4px' : '0px')};
  background-color: #be322e;
`;

export const Skeleton = styled.div`
  @keyframes pulse {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
  min-height: 300px;
  .bar {
    width: 100%;
    height: 12px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: #e7e7e7;
    border-radius: 4px;
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  .circle {
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    color: #555;
    height: 100px;
    background-color: #e7e7e7;
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    border-radius: 50%;
  }
`;
export const LoadingState = () => {
  return (
    <Skeleton>
      <Grid>
        <WebhookCard>
          <div className="content">
            <div className="circle">Loading</div>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
        </WebhookCard>
        <WebhookCard>
          <div className="content">
            <div className="circle">Loading</div>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
        </WebhookCard>
        <WebhookCard>
          <div className="content">
            <div className="circle">Loading</div>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
        </WebhookCard>
      </Grid>
    </Skeleton>
  );
};
