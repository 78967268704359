import { apiGraph } from '../api';
import { toastr } from 'react-redux-toastr';
import types from '../constants';
import { logError } from '../utils/errorLogger';
import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { GetState } from '../redux/actions-types';
export const startLoadingCohorts = () => ({
  type: types.pe_cohorts.start_loading,
});
export const stopLoadingCohorts = () => ({
  type: types.pe_cohorts.stop_loading,
});

export const setPECohorts = (cohorts: any) => ({
  type: types.pe_cohorts.set,
  payload: cohorts,
});
export const clearPECohorts = () => ({ type: types.pe_cohorts.clear });

const notifyOfErrors = (err: any) => {
  const message =
    'There was an error fetching Cohorts via the API.';
  console.error(message, err);
  toastr.error(message, '');
};

export const loadPECohorts =
  (id: string) => (dispatch: Dispatch<AnyAction>, getState: GetState) => {
    const { graph } = getState();
    dispatch(startLoadingCohorts());

    if (!id) {
      id = graph.id;
    }

    apiGraph
      .get_cohorts(id)
      .then(({ data }) => data)
      .then((results) => {
        dispatch(setPECohorts(results));
        dispatch(stopLoadingCohorts());
      })
      .catch((err) => {
        logError(err);
        notifyOfErrors(err);
        stopLoadingCohorts();
      });
  };

export const deletePECohort =
  (cohort_name: string, id: string) =>
  (dispatch: Dispatch<AnyAction>, getState: GetState) => {
    const { graph } = getState();
    dispatch(startLoadingCohorts());

    if (!id) {
      id = graph.id;
    }

    apiGraph
      .delete_cohort(id, cohort_name)
      .then(({ data }) => data)
      .then((results) => {
        dispatch(stopLoadingCohorts());
        dispatch(setPECohorts(results));
      })
      .catch((err) => {
        logError(err);
        notifyOfErrors(err);
        stopLoadingCohorts();
      });
  };
