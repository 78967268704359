import Branding from './NavIcons/Branding';
import Comp from './NavIcons/Comp';
import Content from './NavIcons/Content';
import Disqual from './NavIcons/Disqual';
import Editor from './NavIcons/Editor';
import EventDelay from './TileTypeIcons/EventDelay';
import ParticipantDetail from './NavIcons/ParticipantDetail';
import Participants from './NavIcons/Participants';
import Permissions from './NavIcons/Permissions';
import Recurring from './NavIcons/Recurring';
import Review from './NavIcons/Review';
import Sequence from './NavIcons/Sequence';
import Settings from './NavIcons/Settings';
import Status from './NavIcons/Status';
import Table from './NavIcons/Table';
import Timeline from './NavIcons/Timeline';
import OutlineIcon from './NavIcons/Outline';

const NavIconList = [
  {
    altText: 'Branding',
    name: 'branding-nav-icon',
    svg: Branding,
  },
  {
    altText: 'Compensation',
    name: 'comp-nav-icon',
    svg: Comp,
  },
  {
    altText: 'Content',
    name: 'content-nav-icon',
    svg: Content,
  },
  {
    altText: 'Disqualification',
    name: 'disqual-nav-icon',
    svg: Disqual,
  },
  {
    altText: 'Editor',
    name: 'editor-nav-icon',
    svg: Editor,
  },
  {
    altText: 'Event Delay',
    name: 'event-delay-tile-icon',
    svg: EventDelay,
  },
  {
    altText: 'Participant Detail',
    name: 'participant-detail-nav-icon',
    svg: ParticipantDetail,
  },
  {
    altText: 'Participants',
    name: 'participants-nav-icon',
    svg: Participants,
  },
  {
    altText: 'Permissions',
    name: 'permissions-nav-icon',
    svg: Permissions,
  },
  {
    altText: 'Sequence',
    name: 'sequence-nav-icon',
    svg: Sequence,
  },
  {
    altText: 'Recurring',
    name: `recurring-nav-icon`,
    svg: Recurring,
  },
  {
    altText: 'Review',
    name: `review-nav-icon`,
    svg: Review,
  },
  {
    altText: 'Settings',
    name: 'settings-nav-icon',
    svg: Settings,
  },
  {
    altText: 'Status',
    name: 'status-nav-icon',
    svg: Status,
  },
  {
    altText: 'Table',
    name: 'table-nav-icon',
    svg: Table,
  },
  {
    altText: 'Timeline',
    name: 'timeline-nav-icon',
    svg: Timeline,
  },
  {
    altText: 'Study Outline',
    name: 'outline-icon',
    svg: OutlineIcon,
  },
];

export default NavIconList;
