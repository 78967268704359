import React from 'react';

export default (props) => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      role="graphics-symbol"
      {...props}
    >
      <g
        id="Symbols:-Icons"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <g id="icons/tile-types/black/message">
          <rect
            id="Container"
            fillOpacity={0}
            fill="#FFFFFF"
            x={0}
            y={0}
            width={24}
            height={24}
          />
          <g id="ic_message_black_24px" fillRule="nonzero" fill="#000000">
            <path
              d="M20,2 L4,2 C2.9,2 2.01,2.9 2.01,4 L2,22 L6,18 L20,18 C21.1,18 22,17.1 22,16 L22,4 C22,2.9 21.1,2 20,2 Z M18,14 L6,14 L6,12 L18,12 L18,14 Z M18,11 L6,11 L6,9 L18,9 L18,11 Z M18,8 L6,8 L6,6 L18,6 L18,8 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
