import { useMutation, useQuery, useQueryClient } from 'react-query';
import { WorkspacesService } from 'src/api/workspaces';
import {
  filterFeatureFlagsByTiles,
  filterFeatureFlagsByComponents,
  filterFeatureFlagsByFeature,
} from '../utils/utils';

interface UseFeatureFlagsConfig {
  _workspacesService?: typeof WorkspacesService;
}

export const useFeatureFlags = (config?: UseFeatureFlagsConfig) => {
  const workspacesService = config?._workspacesService ?? WorkspacesService;

  const { data, ...rest } = useQuery(
    ['feature-flags'],
    workspacesService.getFeatureFlags,
  );

  const components = filterFeatureFlagsByComponents(data);
  const tiles = filterFeatureFlagsByTiles(data);
  const features = filterFeatureFlagsByFeature(data);

  return {
    data,
    components,
    tiles,
    features,
    ...rest,
  };
};

export const useUpdateFeatureFlags = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: WorkspacesService.updateFeatureFlags,
    onSuccess: () => {
      queryClient.fetchQuery('feature-flags');
    },
  });
};
