import { connect } from 'react-redux';
import { loadGraph } from '../../../../actions/combination';
import { Button } from '@evidation/form-elements';
import { apiGraph } from '../../../../api';
import { toastr } from 'react-redux-toastr';
import { logError } from 'src/utils/errorLogger';

import type { DuplicateTileProps } from './types';

export const text = {
  duplicate: 'Duplicate',
  error: 'There was a problem duplicating the tile.',
  success: 'Tile successfully duplicated.',
};

const DuplicateTile: React.FC<DuplicateTileProps> = ({
  selectedTile,
  graphId,
  toggleLoader,
  loadGraph,
}) => {
  const { sequence_uuid, uuid: tileUUID } = selectedTile;
  const handleClick = async () => {
    try {
      toggleLoader.showLoader();
      await apiGraph.duplicateTile(graphId, sequence_uuid, tileUUID);
      toastr.success(text.success, '');
      await loadGraph(graphId);
    } catch (error: any) {
      toastr.error(text.error, '');
      logError(error);
    } finally {
      toggleLoader.hideLoader();
    }
  };

  return (
    <Button id="duplicate-tile" onClick={handleClick}>
      {text.duplicate}
    </Button>
  );
};

export default connect(null, { loadGraph: loadGraph as any })(DuplicateTile);
