import { Button, Input, Select } from '@evidation/ui';
import { CloseCircle } from '@evidation/ui/lib/icons';
import { FormState, useFieldArray, UseFormReturn } from 'react-hook-form';
import { event_statuses } from 'src/constants';
import * as yup from 'yup';
import { get } from 'lodash';
import { Grid } from '.';

export const eventEmissionValidations = yup.array().of(
  yup.object().shape({
    node_status: yup.string().required('Node status is required'),
    event_slug: yup.string().required('Event slug is required'),
    event_status: yup.string().required('Event status is required'),
  }),
);

type EventEmissionForm = {
  node_status: string;
  event_slug: string;
  event_status: keyof typeof event_statuses;
};

export default function EventEmission({
  control,
  register,
  setValue,
  watch,
  errors,
}: Partial<UseFormReturn> & {
  errors: FormState<EventEmissionForm>['errors'];
}) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'content.event_emission',
  });
  if (!register || !watch || !setValue) {
    return (
      <div>
        Missing Required Components to render event emission, you must pass
        register, watch & setValue from react-hook-form
      </div>
    );
  }
  return (
    <fieldset>
      <legend style={{ marginTop: '.4em' }}>Event Emission</legend>
      {fields.map((field, id) => {
        return (
          <div key={field.id}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '.4em',
              }}
            >
              <Button
                type="button"
                onClick={() => remove(id)}
                size="small"
                variant="light"
                style={{ alignSelf: 'flex-start' }}
                title={`Remove Header`}
              >
                <CloseCircle style={{ color: '#FFF' }} />
              </Button>
            </div>
            <Grid cols={3}>
              <Input
                error={get(
                  errors,
                  `content.event_emission.${id}.node_status.message`,
                )}
                label="When this step becomes"
                {...register(`content.event_emission.${id}.node_status`, {
                  required: true,
                })}
                id={`id_event_emission.${id}.node_status`}
              />
              <Input
                error={get(
                  errors,
                  `content.event_emission.${id}.event_slug.message`,
                )}
                label="Emit an event with slug"
                {...register(`content.event_emission.${id}.event_slug`, {
                  required: true,
                })}
                id={`id_event_slug.${id}.event_slug`}
              />
              <Select
                label="And status"
                error={get(
                  errors,
                  `content.event_emission.${id}.event_status.message`,
                )}
                options={event_statuses.map((status) => ({
                  label: status,
                  value: status,
                }))}
                required
                name={`content.event_emission.${id}.event_status`}
                value={watch(`content.event_emission.${id}.event_status`)}
                onChange={(opt) =>
                  setValue(
                    `content.event_emission.${id}.event_status`,
                    opt.value,
                    { shouldValidate: true },
                  )
                }
              />
            </Grid>
          </div>
        );
      })}
      <Button
        variant="secondary"
        style={{ marginTop: '1em' }}
        onClick={() =>
          append({ event_slug: '', event_status: '', node_status: '' })
        }
      >
        Add Event
      </Button>
    </fieldset>
  );
}
