import React from 'react';

export default (props) => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      role="graphics-symbol"
      {...props}
    >
      <g
        id="Symbols:-Icons"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <g id="icons/tile-types/black/milestone">
          <rect
            id="Container"
            fillOpacity={0}
            fill="#FFFFFF"
            x={0}
            y={0}
            width={24}
            height={24}
          />
          <g
            id="Group"
            transform="translate(1.000000, 3.000000)"
            fill="#000000"
          >
            <path
              d="M1.79895479,0.0645396825 C1.35718555,0.0645396825 0.980016807,0.218349206 0.667448571,0.525214286 C0.355033782,0.832380952 0.198672941,1.20303175 0.198672941,1.63716667 C0.198672941,1.9238254 0.271559496,2.18590476 0.41748605,2.42340476 C0.56325916,2.66090476 0.757060672,2.84924603 0.998737143,2.98857937 L0.998737143,18.5421905 C0.998737143,18.6569444 1.03617782,18.7511905 1.11121261,18.8247778 C1.18624739,18.8985159 1.28199731,18.9353095 1.39876924,18.9353095 L2.19883345,18.9353095 C2.31560538,18.9353095 2.41150874,18.8985159 2.48639008,18.8247778 C2.56142487,18.7511905 2.59886555,18.6569444 2.59886555,18.5421905 L2.59886555,2.98857937 C2.84054202,2.84924603 3.03434353,2.66090476 3.18011664,2.42340476 C3.32604319,2.18590476 3.39892975,1.9236746 3.39892975,1.63716667 C3.39892975,1.20318254 3.24272235,0.832531746 2.93030756,0.525214286 C2.61773933,0.218198413 2.24057059,0.0645396825 1.79895479,0.0645396825 L1.79895479,0.0645396825 Z"
              id="Shape"
            />
            <path
              d="M21.4141844,1.74769841 C21.1557824,1.60037302 20.8975338,1.59223016 20.6388249,1.72326984 C20.5639435,1.76428571 20.4347425,1.83380159 20.2515287,1.93211905 C18.6845445,2.84939683 17.3928413,3.30811111 16.3759588,3.30811111 C15.9509151,3.30811111 15.5798842,3.23030159 15.2633264,3.07468254 C14.3299182,2.6242619 13.4589622,2.27200794 12.6504585,2.01807143 C11.8421082,1.76428571 10.9711523,1.63731746 10.037744,1.63731746 C9.42933311,1.63731746 8.78332807,1.71512698 8.1000358,1.87074603 C7.41659008,2.02636508 6.78101933,2.22903175 6.19347697,2.47874603 C5.60593462,2.72861111 5.13071429,2.94153175 4.7682763,3.11765873 C4.40553143,3.29378571 4.07838588,3.46765079 3.78683966,3.63970635 C3.52843765,3.80346825 3.39923664,4.02875397 3.39923664,4.3154127 L3.39923664,13.4313413 C3.39923664,13.7342857 3.53258067,13.9636429 3.79926874,14.1192619 C3.93261277,14.1848571 4.06595681,14.2174286 4.19930084,14.2174286 C4.34921697,14.2174286 4.48685748,14.1806349 4.61176202,14.1071984 C5.40354017,13.6320476 6.35566874,13.2141984 7.46830118,12.8538016 C8.58108706,12.4934048 9.5625237,12.3132063 10.4126111,12.3132063 C10.8958106,12.3132063 11.3710309,12.3705079 11.8378118,12.4852619 C12.3044392,12.5998651 12.6837561,12.7188413 12.9754558,12.8414365 C13.2668486,12.9643333 13.6794632,13.1609683 14.2129928,13.4311905 L14.5630017,13.603246 C15.1462476,13.8900556 15.8046817,14.0334603 16.5379971,14.0334603 C17.7965561,14.0334603 19.3342323,13.5584603 21.151179,12.6083095 C21.2094882,12.5757381 21.28038,12.5387937 21.3637008,12.4977778 C21.6552471,12.3503016 21.8013271,12.1170238 21.8013271,11.7974921 L21.8013271,2.42340476 C21.8014805,2.13674603 21.6722795,1.91161111 21.4141844,1.74769841 L21.4141844,1.74769841 Z"
              id="Shape"
            />
          </g>
          <path
            d="M17.3034517,8.78693423 L16.6508635,8.13434601 C16.5613387,8.04476237 16.4524955,8 16.3245694,8 C16.1965843,8 16.0877411,8.04476237 15.9982164,8.13434601 L12.8503616,11.2870303 L11.4395813,9.87142042 C11.3499977,9.78183678 11.2412134,9.73707441 11.1132872,9.73707441 C10.9853022,9.73707441 10.8765179,9.78183678 10.7869342,9.87142042 L10.134346,10.5240086 C10.0447624,10.6135923 10,10.7223766 10,10.8503616 C10,10.9782878 10.0447624,11.087131 10.134346,11.1766558 L11.8714204,12.9137302 L12.5240675,13.5663184 C12.6135923,13.655902 12.7223766,13.7006644 12.8503616,13.7006644 C12.9782878,13.7006644 13.0870721,13.6558431 13.1766558,13.5663184 L13.8293029,12.9137302 L17.3034517,9.43958135 C17.3929764,9.34999771 17.4377977,9.24121338 17.4377977,9.11322834 C17.4378566,8.9853022 17.3929764,8.87651786 17.3034517,8.78693423 L17.3034517,8.78693423 Z"
            id="Shape"
            fill="#FFFFFF"
          />
        </g>
      </g>
    </svg>
  );
};
