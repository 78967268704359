import { triageV2, triageV3 } from './triage';
import { urlWithQueryParams } from '@evidation/utils';

export const graphQueries = {
  getNodeDependencies: (study_slug, status) =>
    graph.get_node_dependencies(study_slug, status).then(({ data }) => data),
  getPublishes: (graph_id, publish_id = '') =>
    graph.getPublishes(graph_id, publish_id).then(({ data }) => data),
  getComponents: (
    graphId,
    sequenceId,
    tileId,
    experienceId,
    formId,
    pageId,
    params,
  ) =>
    graph
      .getComponents(
        graphId,
        sequenceId,
        tileId,
        experienceId,
        formId,
        pageId,
        params,
      )
      .then(({ data }) => data),
  /**
   *
   * @param {*} graphId
   * @param {*} sequenceId
   * @param {*} tileId
   * @param {*} experienceId
   * @param {*} locale
   * @returns {Promise<import('../redux/graph').ExperiencesContent>}
   */
  getExperience: (graphId, sequenceId, tileId, experienceId, locale) =>
    graph
      .getExperience(graphId, sequenceId, tileId, experienceId, locale)
      .then(({ data }) => data),
  getComponent: (
    graphId,
    sequenceId,
    tileId,
    experienceId,
    formId,
    pageId,
    componentId,
    locale,
  ) =>
    graph
      .getComponent(
        graphId,
        sequenceId,
        tileId,
        experienceId,
        formId,
        pageId,
        componentId,
        locale,
      )
      .then(({ data }) => data),
  getEditedIcf: (graphId) =>
    graph.get_edited_icf(graphId).then(({ data }) => data),
  /**
   *
   * @param {*} graphId
   * @param {*} sequenceId
   * @param {*} tileId
   * @param {*} currentLocale
   * @returns {Promise<import('../redux/graph').Tile>}
   */
  getTile: (graphId, sequenceId, tileId, currentLocale) =>
    graph
      .get_tile(graphId, sequenceId, tileId, currentLocale)
      .then(({ data }) => data),
  getPages: (
    graphId,
    sequenceId,
    tileId,
    experienceId,
    formId,
    params = {},
    options = {},
  ) =>
    pageEndpoints
      .getPages(
        graphId,
        sequenceId,
        tileId,
        experienceId,
        formId,
        options,
        params,
      )
      .then(({ data }) => data),
};

//Triage v3 preview endpoints

const previewEndpoints = {
  getPreview: (graphId, sequenceId, tileId, options = {}, params) =>
    triageV3({
      method: 'GET',
      ...options,
      url: urlWithQueryParams(
        `graphs/${graphId}/sequences/${sequenceId}/tiles/${tileId}/preview`,
        params,
      ),
    }),
};

// Triage v3 page endpoints to add a page to a tile.
const pageEndpoints = {
  getPages: (
    graphId,
    sequenceId,
    tileId,
    experienceId,
    formId,
    options = {},
    params,
  ) =>
    triageV3({
      method: 'GET',
      ...options,
      url: urlWithQueryParams(
        `graphs/${graphId}/sequences/${sequenceId}/tiles/${tileId}/experiences/${experienceId}/forms/${formId}/pages`,
        params,
      ),
    }),
  addPage: (graphId, sequenceId, tileId, experienceId, formId, data) =>
    triageV3({
      method: 'POST',
      url: `graphs/${graphId}/sequences/${sequenceId}/tiles/${tileId}/experiences/${experienceId}/forms/${formId}/pages`,
      data,
    }),
  deletePage: (
    graphId,
    sequenceId,
    tileId,
    experienceId,
    formId,
    pageId,
    params,
  ) =>
    triageV3({
      method: 'DELETE',
      url: urlWithQueryParams(
        `graphs/${graphId}/sequences/${sequenceId}/tiles/${tileId}/experiences/${experienceId}/forms/${formId}/pages/${pageId}`,
        params,
      ),
    }),
  updatePage: (
    graphId,
    sequenceId,
    tileId,
    experienceId,
    formId,
    pageId,
    data,
  ) =>
    triageV3({
      method: 'PUT',
      url: `graphs/${graphId}/sequences/${sequenceId}/tiles/${tileId}/experiences/${experienceId}/forms/${formId}/pages/${pageId}`,
      data,
    }),
  /**
   * @description Duplicate page along with components within the page
   *
   *  - This is only meant to work with the new architecture (name TBD)
   *  - The types for the identifiers is currently number or string, this
   *     because currently uuid or id can be used (This may change in the
   *     future.)
   *
   * @note We aren't currently using the data portion of the endpoint in the
   * as the current UX doesn't support it.
   *
   * @type {
   *  graphId: number | string;
   *  sequenceId: number | string;
   *  tileId: number | string;
   *  experienceId: number | string;
   *  formId: number | string;
   *  pageId: number | string;
   *  data: {} | { title: string, component_names: Object<string, string> }
   * }
   * @returns {AxiosPromise}
   */
  duplicatePage: ({
    graphId,
    sequenceId,
    tileId,
    experienceId,
    formId,
    pageId,
    data = {},
  }) =>
    triageV3({
      method: 'POST',
      url: `graphs/${graphId}/sequences/${sequenceId}/tiles/${tileId}/experiences/${experienceId}/forms/${formId}/pages/${pageId}/duplicate`,
      data,
    }),
};

// Triage v3 form component endpoints
const componentEndpoints = {
  getComponents: (
    graphId,
    sequenceId,
    tileId,
    experienceId,
    formId,
    pageId,
    params,
  ) =>
    triageV3({
      method: 'GET',
      url: urlWithQueryParams(
        `graphs/${graphId}/sequences/${sequenceId}/tiles/${tileId}/experiences/${experienceId}/forms/${formId}/pages/${pageId}/components`,
        params,
      ),
    }),
  addComponent: (
    graphId,
    sequenceId,
    tileId,
    experienceId,
    formId,
    pageId,
    data,
  ) =>
    triageV3({
      method: 'POST',
      url: `graphs/${graphId}/sequences/${sequenceId}/tiles/${tileId}/experiences/${experienceId}/forms/${formId}/pages/${pageId}/components`,
      data,
    }),
  deleteComponent: (
    graphId,
    sequenceId,
    tileId,
    experienceId,
    formId,
    pageId,
    componentId,
  ) =>
    triageV3({
      method: 'DELETE',
      url: `graphs/${graphId}/sequences/${sequenceId}/tiles/${tileId}/experiences/${experienceId}/forms/${formId}/pages/${pageId}/components/${componentId}`,
    }),
  updateComponent: (
    graphId,
    sequenceId,
    tileId,
    experienceId,
    formId,
    pageId,
    componentId,
    data,
  ) =>
    triageV3({
      method: 'PUT',
      url: `graphs/${graphId}/sequences/${sequenceId}/tiles/${tileId}/experiences/${experienceId}/forms/${formId}/pages/${pageId}/components/${componentId}`,
      data,
    }),
  getComponent: (
    graphId,
    sequenceId,
    tileId,
    experienceId,
    formId,
    pageId,
    componentId,
    locale,
  ) =>
    triageV3({
      method: 'GET',
      url: `graphs/${graphId}/sequences/${sequenceId}/tiles/${tileId}/experiences/${experienceId}/forms/${formId}/pages/${pageId}/components/${componentId}${
        locale ? `?translation_locale=${locale}` : ''
      }`,
    }),
  /**
   * @description In a multi-page tile, move a component to a new page.
   *
   * @type {({
   *  graphId: string | number;
   *  sequenceId: string;
   *  tileId: string;
   *  experienceId: number;
   *  formId: number;
   *  pageId: number;
   *  componentId: number
   *  targetPageId: number
   * }) => import('axios').AxiosResponse<unknown> }
   */
  moveComponentPage: ({
    graphId,
    sequenceId,
    tileId,
    experienceId,
    formId,
    pageId,
    componentId,
    targetPageId,
  }) =>
    triageV3({
      method: 'POST',
      url: `graphs/${graphId}/sequences/${sequenceId}/tiles/${tileId}/experiences/${experienceId}/forms/${formId}/pages/${pageId}/components/${componentId}/move`,
      data: { target_page_id: targetPageId },
    }),
};

/**
 * @description Triage v3 reminder endpoints
 * @type {{[key: string]: ({}:any) => import('axios').AxiosResponse<unknown>}}
 */
const reminderEndpoints = {
  get: ({ graphId, sequenceId, tileId, reminderId, delayId }) =>
    triageV3({
      method: 'GET',
      url: `graphs/${graphId}/sequences/${sequenceId}/tiles/${tileId}/reminders/${reminderId}?delay_id=${delayId}`,
    }),
  add: ({ graphId, sequenceId, tileId, payload }) =>
    triageV3({
      method: 'POST',
      url: `graphs/${graphId}/sequences/${sequenceId}/tiles/${tileId}/reminders`,
      data: payload,
    }),
  update: ({ graphId, sequenceId, tileId, reminderId, delayId, payload }) =>
    triageV3({
      method: 'PUT',
      url: `graphs/${graphId}/sequences/${sequenceId}/tiles/${tileId}/reminders/${reminderId}?delay_id=${delayId}`,
      data: payload,
    }),
  delete: ({ graphId, sequenceId, tileId, reminderId, delayId }) =>
    triageV3({
      method: 'DELETE',
      url: `graphs/${graphId}/sequences/${sequenceId}/tiles/${tileId}/reminders/${reminderId}?delay_id=${delayId}`,
    }),
  getExperience: ({
    graphId,
    sequenceId,
    tileId,
    reminderId,
    experienceId,
    delayExperienceId,
    locale,
  }) =>
    triageV3({
      method: 'GET',
      url: urlWithQueryParams(
        `graphs/${graphId}/sequences/${sequenceId}/tiles/${tileId}/reminders/${reminderId}/experiences/${experienceId}?delay_experience_id=${delayExperienceId}`,
        { locale },
      ),
    }),
  updateExperience: ({
    graphId,
    sequenceId,
    tileId,
    reminderId,
    experienceId,
    delayExperienceId,
    payload,
  }) =>
    triageV3({
      method: 'PUT',
      url: `graphs/${graphId}/sequences/${sequenceId}/tiles/${tileId}/reminders/${reminderId}/experiences/${experienceId}?delay_experience_id=${delayExperienceId}`,
      data: payload,
    }),
  /**
   * @description Duplicate a Masonry reminder tile
   *
   * @type {({
   *  graphId: string | number;
   *  sequence_id: string;
   *  tileId: string;
   *  reminderId: string;
   *  delay_id: string
   * }) => import('axios').AxiosResponse<unknown>}
   */
  duplicateReminder: ({ graphId, sequenceId, tileId, reminderId, delayId }) =>
    triageV3({
      method: 'POST',
      url: `graphs/${graphId}/sequences/${sequenceId}/tiles/${tileId}/reminders/${reminderId}/duplicate`,
      data: { delay_id: delayId },
    }),
};

//Triage v3 experiences endpoints
const experienceEndpoints = {
  getExperienceList: (graphId, sequenceId, tileId) =>
    triageV3({
      method: 'GET',
      url: `graphs/${graphId}/sequences/${sequenceId}/tiles/${tileId}/experiences`,
    }),
  getExperience: (graphId, sequenceId, tileId, experienceId, locale) =>
    triageV3({
      method: 'GET',
      url: `graphs/${graphId}/sequences/${sequenceId}/tiles/${tileId}/experiences/${experienceId}${
        locale ? `?translation_locale=${locale}` : ''
      }`,
    }),
  updateExperience: (graphId, sequenceId, tileId, experienceId, data) =>
    triageV3({
      method: 'PUT',
      url: `graphs/${graphId}/sequences/${sequenceId}/tiles/${tileId}/experiences/${experienceId}`,
      data,
    }),
};

//Triage v3 duplication endpoints
const duplicationEndpoints = {
  duplicateComponent: (
    graphId,
    sequenceId,
    tileId,
    experienceId,
    formId,
    pageId,
    componentId,
    data,
  ) =>
    triageV3({
      method: 'POST',
      url: `graphs/${graphId}/sequences/${sequenceId}/tiles/${tileId}/experiences/${experienceId}/forms/${formId}/pages/${pageId}/components/${componentId}/duplicate`,
      data,
    }),
};

export const graph = {
  publish: (id, data) =>
    triageV3({
      method: 'POST',
      url: `graphs/${id}/publishes`,
      data,
    }),
  getPublishes: (graph_id, publish_id = '') =>
    triageV3({
      method: 'GET',
      url: `graphs/${graph_id}/publishes/${publish_id}`,
    }),
  restoreGraph: (graph_id, publish_id) =>
    triageV3({
      method: 'PATCH',
      url: `graphs/${graph_id}/publishes/${publish_id}?is_current=true`,
    }),
  create: (data) =>
    triageV2({
      method: 'POST',
      url: 'graphs',
      data,
    }).then(({ data }) => data),
  /**
   * @param {string} [graph_id]
   * @param {string} [currentLocale]
   */
  get: (graph_id = undefined, currentLocale = undefined) =>
    triageV2({
      method: 'GET',
      url: `graphs${graph_id ? `/${graph_id}` : ''}${
        currentLocale ? `?translation_locale=${currentLocale}` : ''
      }`,
    }).then(({ data }) => data),
  export: (graph_id = undefined) =>
    triageV2({
      method: 'GET',
      url: `graphs/${graph_id}/export`,
    }).then(({ data }) => data),
  update: (graph_id, data) =>
    triageV2({
      method: 'PUT',
      url: `graphs/${graph_id}`,
      data,
    }).then(({ data }) => data),

  /// sequences
  get_sequence: (graph_id, sequence_id) =>
    triageV2({
      method: 'GET',
      url: `graphs/${graph_id}/sequences/${sequence_id}`,
    }),
  add_sequence: (graph_id, data) =>
    triageV2({
      method: 'POST',
      url: `graphs/${graph_id}/sequences`,
      data,
    }).then(({ data }) => data),
  update_sequence: (graph_id, sequence_id, data) =>
    triageV2({
      method: 'PUT',
      url: `graphs/${graph_id}/sequences/${sequence_id}`,
      data,
    }).then(({ data }) => data),
  delete_sequence: (graph_id, sequence_id) =>
    triageV2({
      method: 'DELETE',
      url: `graphs/${graph_id}/sequences/${sequence_id}`,
    }).then(({ data }) => data),
  transfer_tile: (graph_id, data) =>
    triageV2({
      method: 'PUT',
      url: `graphs/${graph_id}/sequences/transfer_tile`,
      data,
    }).then(({ data }) => data),
  /**
   * @description Duplicate a sequence within a study. Note that this only works
   * in a study with the Masonry architecture enabled.
   *
   * @param {string | number} graphId
   * @param {string} sequenceId
   * @returns {import('axios').AxiosResponse<unknown>}
   */
  duplicateSequence: (graphId, sequenceId) =>
    triageV3({
      method: 'POST',
      url: `graphs/${graphId}/sequences/${sequenceId}/duplicate`,
    }),

  /// tiles
  get_tile: (graph_id, sequence_id, tile_id, currentLocale = undefined) =>
    triageV2({
      method: 'GET',
      url: `graphs/${graph_id}/sequences/${sequence_id}/tiles/${tile_id}${
        currentLocale ? `?translation_locale=${currentLocale}` : ''
      }`,
    }),
  add_tile: (graph_id, sequence_id, data) =>
    triageV2({
      method: 'POST',
      url: `graphs/${graph_id}/sequences/${sequence_id}/tiles`,
      data,
    }).then(({ data }) => data),
  /**
   * @param {string} graph_id
   * @param {string} sequence_id
   * @param {string} tile_id
   * @param {import('src/redux/graph').Tile} data
   * @returns {import('axios').AxiosPromise<unknown>}
   */
  update_tile: (graph_id, sequence_id, tile_id, data) =>
    triageV2({
      method: 'PUT',
      url: `graphs/${graph_id}/sequences/${sequence_id}/tiles/${tile_id}`,
      data,
    }).then(({ data }) => data),
  delete_tile: (graph_id, sequence_id, tile_id) =>
    triageV2({
      method: 'DELETE',
      url: `graphs/${graph_id}/sequences/${sequence_id}/tiles/${tile_id}`,
    }).then(({ data }) => data),
  /**
   * @description Duplicate a tile within a study. Note that this only works
   * in a study with the Masonry architecture enabled.
   *
   * @param {string | number} graphId
   * @param {string} sequenceId
   * @param {string} tileId
   * @returns {import('axios').AxiosResponse<unknown>}
   */
  duplicateTile: (graphId, sequenceId, tileId) =>
    triageV3({
      method: 'POST',
      url: `graphs/${graphId}/sequences/${sequenceId}/tiles/${tileId}/duplicate`,
    }),

  /// reminders
  add_reminder: (graph_id, sequence_id, tile_id, data) =>
    triageV2({
      method: 'POST',
      url: `graphs/${graph_id}/sequences/${sequence_id}/tiles/${tile_id}/reminders`,
      data,
    }).then(({ data }) => data),
  update_reminder: (graph_id, sequence_id, tile_id, reminder_id, data) =>
    triageV2({
      method: 'PUT',
      url: `graphs/${graph_id}/sequences/${sequence_id}/tiles/${tile_id}/reminders/${reminder_id}`,
      data,
    }).then(({ data }) => data),
  delete_reminder: (graph_id, sequence_id, tile_id, reminder_id) =>
    triageV2({
      method: 'DELETE',
      url: `graphs/${graph_id}/sequences/${sequence_id}/tiles/${tile_id}/reminders/${reminder_id}`,
    }).then(({ data }) => data),
  transfer_reminder: (graph_id, sequence_id, data) =>
    triageV2({
      method: 'PUT',
      url: `graphs/${graph_id}/sequences/${sequence_id}/tiles/transfer_reminder`,
      data,
    }).then(({ data }) => data),

  // cohorts
  get_cohorts: (graph_id) =>
    triageV2({
      method: 'GET',
      url: `graphs/${graph_id}/cohorts`,
    }),
  filter_cohort: (graph_id, data, cancelToken) =>
    triageV2({
      method: 'POST',
      url: `graphs/${graph_id}/cohorts/filter`,
      cancelToken,
      data,
    }),
  search_cohort: (graph_id, data, cancelToken) =>
    triageV2({
      method: 'POST',
      url: `graphs/${graph_id}/cohorts/search`,
      data,
      cancelToken,
    }),
  search_cohort_v3: (graph_id, data, cancelToken) =>
    triageV3({
      method: 'POST',
      url: `graphs/${graph_id}/cohorts/search`,
      data,
      cancelToken,
    }),
  cohort_totals: (graph_id, queryParams) =>
    triageV2({
      method: 'GET',
      url: `graphs/${graph_id}/cohorts/total${
        queryParams ? `${queryParams}` : ''
      }`,
    }),
  delete_cohort: (graph_id, cohort_name) =>
    triageV2({
      method: 'DELETE',
      url: `graphs/${graph_id}/cohorts/${cohort_name}`,
    }),
  generate_download_params: (graph_id, data) =>
    triageV2({
      method: 'POST',
      url: `graphs/${graph_id}/cohorts/generate_download_params`,
      ...data,
    }),
  // Event_Identifiers
  // api/v1/enrollments/event_identifiers
  event_identifiers: (graph_id) =>
    triageV2({
      method: 'GET',
      url: `graphs/${graph_id}/cohorts/event_identifiers`,
    }),
  // Export translations
  exportTranslations: (graph_id, params) =>
    triageV3({
      method: 'GET',
      url: urlWithQueryParams(`graphs/${graph_id}/translations`, params),
    }),
  // Import translations
  importTranslations: (graph_id, language, data) =>
    triageV3({
      method: 'POST',
      url: `graphs/${graph_id}/translations?locale=${language}`,
      data,
      headers: { 'Content-Type': 'application/json' },
    }),
  ...previewEndpoints,
  ...pageEndpoints,
  ...componentEndpoints,
  ...experienceEndpoints,
  reminders: reminderEndpoints,
  ...duplicationEndpoints,
  // ICF
  get_edited_icf: (graph_id) =>
    triageV3({
      method: 'GET',
      url: `graphs/${graph_id}/changed_icf_tiles`,
      headers: { 'Content-Type': 'application/json' },
    }),
  // Node Dependencies
  get_node_dependencies: (study_slug, status) =>
    triageV3({
      method: 'GET',
      url: `studies/${study_slug}/node_dependencies?status=${status}`,
    }),
};
