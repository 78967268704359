import { triageV2 } from './triage';

/**
 * @param {object} params
 * @param {string} [params.token]
 * @param {string} [params.userEmail]
 * @param {import('axios').CancelToken} [params.cancelToken]
 */
export const authorized_actions = ({ token, userEmail, cancelToken }) => {
  const headers =
    token && userEmail
      ? {
          Authentication: token,
          'User-Email': userEmail,
        }
      : {};
  return triageV2({
    method: 'GET',
    url: 'authorized_actions',
    headers,
    cancelToken,
  });
};

export const delete_user = (data) =>
  triageV2({
    method: 'DELETE',
    url: '/enrollments/delete_user',
    data,
  });

// Get authorized role information
// graph_id: current study
// email: user email
export const get_role_info = (graph_id, email) =>
  triageV2({
    method: 'GET',
    url: `roles/current/?graph_id=${graph_id}&email=${email}`,
  });

export const reset_password = (study_slug, enrollment_identifier) =>
  triageV2({
    method: 'POST',
    url: `studies/${study_slug}/enrollments/${enrollment_identifier}/reset_password`,
  });

// Trigger PIN reset
// data: {
//   "graph_id": "study.graph.id",
//   "role_pin": {
//     "email": "email"
//   }
// }
export const reset_pin_email = (data) =>
  triageV2({ method: 'POST', url: 'role_pins/password', data });

//Reset PIN
// {
//   "graph_id": "study.graph.id",
//   "role_pin": {
//     "email": "email",
//     "reset_password_token": "token_string",
//     "password": "pin"
//   }
// }
export const reset_pin = (data) =>
  triageV2({ method: 'PUT', url: 'role_pins/password', data });

// Sets a PIN for the user
// data: {
//    graph_id: "some_study_graph_id"
//    pin: "123456"
// }
export const update_pin = (data) =>
  triageV2({
    method: 'PUT',
    url: 'roles/current/update_pin',
    data,
  });

// validates a PIN
// data: {
//    pin: "123456"
// }
export const validate_pin = (data, graph_id) =>
  triageV2({
    method: 'POST',
    url: `graphs/${graph_id}/roles/current/valid_pin`,
    data,
  });
