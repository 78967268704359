import {
  ReminderNodes,
  TileNodes,
  event_statuses,
  node_statuses,
} from '../../../constants';
import stripSpecialCharacters, {
  parseStudySlug,
} from './stripSpecialCharacters';
import stripHtml from '../../../utils/stripHtml';
import DownloadIcon from '../../../assets/icons/Download';
import _ from 'lodash';
import common_incoming from './common_incoming';
import common_outgoing from './common_outgoing';
import downloadPdfPreview from './downloadPdfPreview';
import onImageAdd from './onImageAdd';
import sendPreviewEmail from './sendPreviewEmail';
import { ComponentDictionary } from '@evidation/curious-panda';
import {
  schema_dictionary,
  number as numberSchema,
  possible_answers_additional_payload,
  studyDataTrigger,
  sensitiveDataFlag,
  mutually_exclusive_answers_additional_payload,
  checkbox2Validations,
  sliderValidations,
  numberInputValidations,
  dateInputValidations,
  hintText,
} from './fieldSchemas';
import {
  enforceLowercase_parse,
  enforceNoWhiteSpace_parse,
} from './common_validations';
import { alwaysOptionalFields, no_data_field } from './utils';

// for i18n in study manager
export const CHARACTERS_EXCEEDS_LENGTH = (length, maxLength) =>
  `${length} characters exceeds allowable length: ${maxLength}`;

export const convertLabelToName = (s = ``) =>
  stripSpecialCharacters(
    _.truncate(stripHtml(s.trim()), { length: 50, omission: `` }),
  );

export const use_sendgrid_boolean = 'use_sendgrid';

export const showInterfaceForSendgridId = (path, state) => {
  return shouldShowInterface(path, state, use_sendgrid_boolean);
};

export const KeywordNames = [
  'address',
  'address_1',
  'address_2',
  'address_3',
  'city',
  'state',
  'postal_code',
  'postal_code_full',
  'country',
];

export const nameValidator = (intf) => {
  if (fieldNameValidations[intf]) return fieldNameValidations[intf];
  else return validateName;
};

export const validateName = (v) => {
  if (_.includes(KeywordNames, convertLabelToName(v)))
    return `${convertLabelToName(v)} is a reserved name, please use another`;
};

export const validateAddressName = (v) => {
  if (convertLabelToName(v) !== lockedFieldNames.address)
    return `requires name: ${lockedFieldNames.address}`;
};

const fieldNameValidations = {
  address: validateAddressName,
};

export const lockedFieldNames = {
  address: `address`,
  zip_code: `zip_code`,
};

export const defaultLabels = {
  zip_code: `What is the zip code where you live most of the time?`,
};

export const heightUnitTypes = ['All', 'Imperial', 'Metric'];
export const weightUnitTypes = ['All', 'Pound', 'Stone', 'Metric'];

export const validateInt = (v) => {
  if (_.isUndefined(v) || _.isNull(v)) return undefined;
  if (!Number.isInteger(v)) return 'number must be an integer';
};

export const validateNumberGreaterThan = (n) => (v) => {
  if (_.isUndefined(v) || _.isNull(v)) return undefined;
  const parsedValue = parseFloat(v, 10);

  if (parsedValue <= n) {
    return `number has to be greater than ${n}`;
  } else if (_.isNaN(parsedValue)) {
    return `Must be a number`;
  } else {
    return undefined;
  }
};

export const validateNumber = validateNumberGreaterThan(0);

const UUID_REGEX_PATTERN =
  '[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}';
const UUID_REGEX = new RegExp(`^${UUID_REGEX_PATTERN}$`);

export const validateUuid = (value) => {
  if (value && !UUID_REGEX.test(value)) {
    return 'The value must be a valid UUID. Example: abcdef01-2345-6789-0abc-def123456789';
  }
};

export const parseSlug = (value) => {
  if (!value) {
    return value;
  }

  return parseStudySlug(value);
};

const trim = (x) => x.replace(/^\s+|\s+$/gm, '');

export const trim_string_parse = (v) => {
  if (!v) {
    return v;
  }

  return trim(v);
};

const ZENDESK_SNIPPET_REGEX = new RegExp(
  `^\\s*(?:<!-- Start of Zendesk Widget script -->)?\\s*<script\\s+id="ze-snippet"\\s+src="https://static\\.zdassets\\.com/ekr/snippet\\.js\\?key=(${UUID_REGEX_PATTERN})">\\s*</script>\\s*(?:<!-- End of Zendesk Widget script -->)?\\s*$`,
);

/**
 * If the input is in the form of a Zendesk JS widget snippet, parses out the
 *   API key and returns that.
 * @param {string} value
 */
export const parseZendeskSnippet = (value) => {
  if (value) {
    const match = value.match(ZENDESK_SNIPPET_REGEX);
    if (match) {
      return match[1];
    }
  }
  return value;
};

export const getToPath = (path, interfaceToAdd) => {
  return path
    .slice(0, -1)
    .concat(interfaceToAdd) // adding interface
    .join(`.`); // joining the array into a path string
};

export const shouldShowInterface = (path, state, interfaceToShow) => {
  if (state && path.length > 1) {
    return _.at(state.values, getToPath(path, interfaceToShow))[0];
  }
};

/**
 * Takes a function `func` and returns a new function that returns the
 * boolean opposite of whatever `func` returns.
 *
 * @param {function} func - The function to negate
 * @returns {function}
 */
export const negate =
  (func) =>
  (...args) =>
    !func(...args);

/**
 * Takes any number of functions and returns a new function that calls
 * each one and returns true if they all return true, or false
 * otherwise.
 *
 * @param {...function} funcs - The functions to call
 * @returns {function}
 */
export const combineEvery =
  (...funcs) =>
  (...args) =>
    funcs.every((func) => func(...args));

export const AllMaskOptions = [
  `11111`,
  `111-111-1111`,
  `11/11/1111`,
  `1111`,
  `111 years`,
  `111 cigarettes`,
  `111 hours`,
  `111 minutes`,
  `111 seconds`,
  `111 mm`,
  `1111 m (meters)`,
  `111 in`,
  `111 ft`,
  `111 lbs`,
  `111 beats / minute`,
  `111 mm Hg / 111 mm Hg`,
];

export { common_outgoing, common_incoming };

const generate_select_list = (list) =>
  _.keys(list).reduce((acc, key) => [...acc, _.snakeCase(key)], []);

const standardComponents = [
  { label: `ess`, value: `ess` },
  { label: `phq9`, value: `phq9` },
  { label: `gad7`, value: `gad7` },
  { label: `acq5`, value: `acq5` },
  { label: `date_of_birth`, value: `date_of_birth` },
  { label: `address`, value: `address` },
  {
    label: `gender`,
    value: `gender`,
  },
  { label: `sex`, value: `sex` },
  { label: `age`, value: `age` },
  { label: `ethnicity`, value: `ethnicity` },
  { label: `pregnancy`, value: `pregnancy` },
  { label: 'country_resident', value: 'country_resident' },
];

export const allFields = [
  { label: 'hidden', value: 'hidden' },
  { label: 'insuranceProviderId', value: 'insuranceProviderId' },
  { label: 'contentAcceptance', value: 'contentAcceptance' },
  { label: 'Error Summary', value: 'error_summary' },
  { label: 'Aside', value: 'aside' },
  { label: 'markdown', value: 'markdown' },
  { label: 'mask', value: 'mask' },
  { label: 'mask_custom', value: 'mask_custom' },
  { label: 'text', value: 'text' },
  { label: 'height_intl', value: 'height_intl' },
  { label: 'weight_intl', value: 'weight_intl' },
  { label: 'textarea', value: 'textarea' },
  { label: 'number', value: 'number' },
  { label: 'selectadvanced', value: 'selectadvanced' },
  { label: 'select', value: 'select' },
  { label: 'checkbox', value: 'checkboxWithNone' },
  { label: 'radio', value: 'radio' },
  { label: 'date', value: 'date' },
  { label: 'datetime', value: 'datetime' },
  { label: 'passwordmodern', value: 'passwordmodern' },
  { label: 'heading', value: 'heading' },
  { label: 'paragraph', value: 'paragraph' },
  { label: 'phone_intl', value: 'phone_intl' },
  { label: 'zip_code', value: 'zip_code' },
  { label: 'scrollbox', value: 'scrollbox' },
  { label: 'scrollboxLarge', value: 'scrollboxLarge' },
  { label: 'file_uploader', value: 'file_uploader' },
  { label: 'select-number-range', value: 'select-number-range' },
  { label: 'simple_matrix', value: 'simple_matrix' },
  { label: 'todays_date', value: 'todays_date' },
  { label: 'signature', value: 'signature' },
  { label: 'typing_test', value: 'typing_test' },
  { label: 'validatedText', value: 'validatedText' },
  { label: 'range_slider', value: 'range_slider' },
  { label: 'year', value: 'year' },
  { label: 'state', value: 'state' },
  { label: 'language', value: 'language' },
  { label: 'email', value: 'email' },
  { label: 'email_confirm', value: 'email_confirm' },
  { label: 'time', value: 'time' },
  { label: 'us_resident', value: 'us_resident' },

  ...standardComponents,
].sort();

export const fieldsByTile = {
  education: ['paragraph'],
  survey: [
    'checkbox',
    'date',
    'datetime',
    'file_uploader',
    'heading',
    'mask',
    'mask_custom',
    'number',
    'paragraph',
    'radio',
    'selectadvanced',
    'text',
    'textarea',
    'time (24-hour clock)',
    'todays_date',
    'year',
    'insuranceProviderId',
  ].sort(),
  follow_up_survey: [
    'checkbox',
    'date',
    'datetime',
    'file_uploader',
    'heading',
    'mask',
    'mask_custom',
    'number',
    'paragraph',
    'radio',
    'selectadvanced',
    'text',
    'textarea',
    'todays_date',
    'year',
  ].sort(),
  screener: [
    'contentAcceptance',
    'checkbox',
    'date',
    'datetime',
    'file_uploader',
    'heading',
    'mask',
    'mask_custom',
    'paragraph',
    'radio',
    'selectadvanced',
    'text',
    'textarea',
    'year',
    'zip_code',
  ].sort(),
  registration: ['email', 'paragraph', 'state', 'zip_code'].sort(),
  informed_consent: [
    'password',
    'paragraph',
    'signature',
    `scrollbox`,
    `scrollboxLarge`,
    'state',
  ].sort(),
};

const getInterfaceSchema = (intf, ...args) => {
  const schema = schema_dictionary[intf] ? schema_dictionary[intf] : [];

  return _.isFunction(schema) ? schema(...args) : schema;
};

// doesn't work in tabular
export const showForInterfaces = (intf_list = [], intf, configs = []) =>
  _.includes(intf_list, intf) ? configs : [];

// doesn't work in tabular
export const dontShowForInterfaces = (intf_list = [], intf, configs = []) =>
  !_.includes(intf_list, intf) ? configs : [];

export const ConstructFields = ({
  // includeTabular is an escape hatch to prevent a continious loop
  // since we use ConstructFields for the fields within Tabular
  IncludeDisqualificationCriteria = false,
  selected_active_field = {},
  study_slug = '',
  masonryEnabled = false,
  components = [],
}) => {
  const { interface: intf } = selected_active_field;
  let output = _.compact([
    {
      name: `interface`,
      label: `Interface`,
      interface: 'select',
      possible_answers: allFields,
      inputStyle: `muted`,
      description: `Interface in which the screener question will display as.`,
      disabled: true,
    },
    {
      name: `label`,
      label: `Label`,
      interface: `rte`,
      required: true,
      inputStyle: `sm_minimal`,
    },
    ...dontShowForInterfaces(Object.keys(lockedFieldNames), intf, [
      {
        name: `name`,
        label: `Name`,
        interface: `textinput`,
        description: `The response is saved under this name`,
        required: true,
        inputStyle: `sm_minimal`,
        parse: enforceLowercase_parse,
        validate: [nameValidator(intf)],
      },
    ]),
    ...showForInterfaces(Object.keys(lockedFieldNames), intf, [
      {
        name: `name`,
        label: `Name`,
        interface: `textinput`,
        description: `The response is saved under this name`,
        required: true,
        disabled: true,
        validate: [nameValidator(intf)],
      },
    ]),
    ...showForInterfaces(
      [
        'selectadvanced',
        'height_intl',
        'weight_intl',
        'number',
        'radio',
        'checkboxWithNone',
      ],
      intf,
      [hintText],
    ),
    ...showForInterfaces(['typing_test'], intf, [
      {
        name: `image`,
        label: `Prompt Image`,
        interface: `connected_file`,
        config: {
          onFileAdd: (data, cb) => onImageAdd({ data, study_slug }, cb),
        },
        inputStyle: `sm_minimal`,
        parse: trim_string_parse,
      },
      {
        name: `image_alt`,
        label: `Prompt Image Alt Text`,
        interface: `textinput`,
        inputStyle: `sm_minimal`,
      },
    ]),
    {
      ...possible_answers_additional_payload,

      condition: (path, state) =>
        ShowInterfaceForType({
          path,
          ...state,
          types: [
            `selectadvanced`,
            `radio`,
            `radio2`,
            `checkbox`,
            `checkboxWithNone`,
            `checkbox2`,
            `gender`,
            'ethnicity',
          ],
        }),
    },
    {
      ...mutually_exclusive_answers_additional_payload,
      condition: (path, state) =>
        ShowInterfaceForType({
          path,
          ...state,
          types: [
            `selectadvanced`,
            `radio`,
            `radio2`,
            `checkbox`,
            `checkboxWithNone`,
            `checkbox2`,
            'date',
            'datetime',
            'number',
            'range_slider',
            'mask',
            'mask_custom',
          ],
        }),
    },
    ...showForInterfaces(['checkboxWithNone'], intf, [checkbox2Validations]),
    ...showForInterfaces(['range_slider'], intf, [sliderValidations]),
    ...showForInterfaces(['number'], intf, [
      numberInputValidations(components),
    ]),
    ...showForInterfaces(['date_of_birth', 'date', 'date_picker'], intf, [
      dateInputValidations(components),
    ]),
    ..._.map(numberSchema, (number) => {
      return {
        ...number,
        condition: (path, state) =>
          ShowInterfaceForType({
            path,
            ...state,
            types: [`number`],
          }),
      };
    }),
    ...showForInterfaces(
      [
        `age`,
        `english`,
        `language`,
        'country_resident',
        `ethnicity`,
        `gender`,
        `pregnancy`,
        `sex`,
        `us_resident`,
      ],
      intf,
      [
        {
          title: 'Additional Payload',
          name: `additional_payload_std_components`,
          interface: `array`,
          items: [
            {
              label: `When user answers`,
              name: `answer`,
              interface: `selectadvanced`,
              options: _.get(ComponentDictionary, `${intf}.answers`) || [],
              required: true,
            },
            {
              label: `submit`,
              name: `value`,
              interface: `selectadvanced`,
              options: [{ label: 'skip', value: 'skipped' }],
              required: true,
            },
            {
              label: `for`,
              name: `attribute`,
              interface: `textinput`,
              parse: enforceLowercase_parse,
              required: true,
            },
          ],
        },
      ],
    ),
    ...dontShowForInterfaces(
      [...no_data_field, ...standardComponents, 'simple_matrix'],
      intf,
      [sensitiveDataFlag],
    ),
    ...getInterfaceSchema(intf, selected_active_field),
    ...dontShowForInterfaces(
      [...no_data_field, ...alwaysOptionalFields],
      intf,
      [
        {
          title: 'Options',
          label: `Optional`,
          name: `optional_field`,
          defaultValue: false,
          interface: `checkbox`,
          labelStyle: `dark`,
        },
      ],
    ),
    {
      name: `mask`,
      interface: `select`,
      inputStyle: `muted`,
      label: `Mask Type`,
      options: AllMaskOptions,
      description: `The format in which the question must be answered in.`,
      condition: (path, state) =>
        ShowInterfaceForType({
          path,
          ...state,
          types: [`mask`],
        }),
    },
    {
      ...studyDataTrigger,
    },
    ...showForInterfaces(['address'], intf, [
      {
        label: `Placeholder Label`,
        name: `placeholder`,
        interface: `text`,
        required: false,
        inputStyle: `sm_minimal`,
      },
    ]),
    ...showForInterfaces([`signature`, 'mask_custom'], intf, [
      {
        label: `Placeholder Label`,
        name: `default_select_value`,
        interface: `text`,
        required: false,
        inputStyle: `sm_minimal`,
      },
    ]),
  ]);

  if (IncludeDisqualificationCriteria) {
    output = [...output, ...disqualification_criteria(masonryEnabled)];
  }

  return output;
};

const defaultEvaluationStrategies = [
  'present',
  'not_present',
  'in_list',
  'not_in_list',
  'in_range',
  'not_in_range',
  'equal_to_value',
  'less_than_value',
  'greater_than_value',
  'less_than_or_equal_to_value',
  'greater_than_or_equal_to_value',
];

const dateEvaluationStrategies = [
  'in_range_date_days',
  'in_range_date_months',
  'not_in_range_date_days',
  'not_in_range_date_months',
];

const matrixEvaluationStrategies = [
  'matrix_sum_equal_to_value',
  'matrix_sum_less_than_value',
  'matrix_sum_greater_than_value',
  'matrix_sum_less_than_or_equal_to_value',
  'matrix_sum_greater_than_or_equal_to_value',
];

const checkboxEvaluationStrategies = [
  'checkbox_none_of_the_above_selected',
  'checkbox_none_of_the_above_not_selected',
  'checkbox_less_than_minimum_selected',
  'checkbox_more_than_maximum_selected',
  'checkbox_specific_answers_selected',
  'checkbox_specific_answers_not_selected',
];

const ageEvaluationStrategies = ['age_less_than', 'age_greater_than'];

export const singleValueEvaluationStrategies = [
  'present',
  'not_present',
  'equal_to_value',
  'less_than_value',
  'greater_than_value',
  'less_than_or_equal_to_value',
  'greater_than_or_equal_to_value',
  'checkbox_more_than_maximum_selected',
  'checkbox_less_than_minimum_selected',
  ...matrixEvaluationStrategies,
  ...ageEvaluationStrategies,
];

export const listValuesEvaluationStrategies = [
  'in_list',
  'not_in_list',
  'checkbox_none_of_the_above_selected',
  'checkbox_none_of_the_above_not_selected',
  'checkbox_specific_answers_selected',
  'checkbox_specific_answers_not_selected',
];

export const rangeValuesEvaluationStrategies = [
  'in_range',
  'not_in_range',
  ...dateEvaluationStrategies,
];

export const getStrategyType = (evaluationStrategy) =>
  singleValueEvaluationStrategies.includes(evaluationStrategy)
    ? 'singleValue'
    : listValuesEvaluationStrategies.includes(evaluationStrategy)
    ? 'listValues'
    : rangeValuesEvaluationStrategies.includes(evaluationStrategy)
    ? 'rangeValues'
    : null;

const evaluationStrategiesRelatedKeys = {
  singleValue: ['value', 'evaluation_strategy'],
  rangeValues: ['upper_bound', 'lower_bound', 'evaluation_strategy'],
  listValues: ['list', 'evaluation_strategy'],
};

export const strategiesToRemoveUnrelatedValues = {
  singleValue: (disqualificationCriteria) => {
    return _.pick(
      disqualificationCriteria,
      evaluationStrategiesRelatedKeys.singleValue,
    );
  },
  listValues: (disqualificationCriteria) => {
    return _.pick(
      disqualificationCriteria,
      evaluationStrategiesRelatedKeys.listValues,
    );
  },
  rangeValues: (disqualificationCriteria) => {
    return _.pick(
      disqualificationCriteria,
      evaluationStrategiesRelatedKeys.rangeValues,
    );
  },
};

/**
 * Remove unrelated keys for a specific disqualification strategy
 * @param {*} data
 */
export const formatDisqualificationCriteria = (data) => {
  const payloadData = JSON.parse(JSON.stringify(data));
  if (payloadData.disqualification_criteria) {
    for (const [index, dq] of payloadData.disqualification_criteria.entries()) {
      const strategy = getStrategyType(dq.evaluation_strategy);
      if (strategy) {
        payloadData.disqualification_criteria[index] =
          strategiesToRemoveUnrelatedValues[strategy](dq);
      }
      return payloadData;
    }
  } else {
    return payloadData;
  }
};

export const disqualification_criteria_items = (
  addButtonLabel,
  deleteButtonIcon,
) => {
  return [
    {
      name: `evaluation_strategy`,
      interface: `select`,
      inputStyle: `muted`,
      label: `Evaluation Strategy`,
      options: [
        ...dateEvaluationStrategies,
        ...defaultEvaluationStrategies,
        ...ageEvaluationStrategies,
        ...checkboxEvaluationStrategies,
        ...matrixEvaluationStrategies,
      ].sort(),
    },
    {
      name: `value`,
      interface: `textinput`,
      label: `Value`,
      inputStyle: `sm_minimal`,
      condition: (path, { values }) => {
        if (values) {
          const toPath = getToPath(path, `evaluation_strategy`);

          return singleValueEvaluationStrategies.includes(
            _.at(values, toPath)[0],
          );
        }
      },
    },
    {
      name: `lower_bound`,
      interface: `textinput`,
      label: `Lower Bound`,
      inputStyle: `sm_minimal`,
      condition: (path, { values }) => {
        if (values) {
          const toPath = getToPath(path, `evaluation_strategy`);

          return rangeValuesEvaluationStrategies.includes(
            _.at(values, toPath)[0],
          );
        }
      },
    },
    {
      name: `upper_bound`,
      interface: `textinput`,
      label: `Upper Bound`,
      inputStyle: `sm_minimal`,
      condition: (path, { values }) => {
        if (values) {
          const toPath = getToPath(path, `evaluation_strategy`);

          return rangeValuesEvaluationStrategies.includes(
            _.at(values, toPath)[0],
          );
        }
      },
    },
    {
      name: `list`,
      interface: `flatarray`,
      label: `List`,
      inputStyle: `sm_minimal`,
      addButtonLabel,
      deleteButtonIcon,
      condition: (path, { values }) => {
        if (values) {
          const toPath = getToPath(path, `evaluation_strategy`);
          return listValuesEvaluationStrategies.includes(
            _.at(values, toPath)[0],
          );
        }
      },
    },
    {
      name: 'sendgrid_template',
      interface: 'textinput',
      label: 'SendGrid Template for Messaging (optional)',
    },
  ];
};

export const disqualification_criteria = (masonryEnabled = false) => {
  const condition = (path, { values }) => {
    // state returns all available forms, there could be more than one,
    // they would be keyed off their "form" name
    if (values) {
      const toPath = getToPath(path, `has_dq_criteria`);
      return _.at(values, toPath)[0];
    }
  };

  const dqCriteria = {
    name: `disqualification_criteria`,
    interface: `array`,
    label: `Disqualification Criteria`,
    items: disqualification_criteria_items(),
  };

  if (masonryEnabled) {
    return [{ title: 'Disqualification Criteria', ...dqCriteria }];
  }

  return [
    {
      name: `has_dq_criteria`,
      label: `Has Disqualification Criteria`,
      interface: `checkbox`,
    },
    {
      ...dqCriteria,
      condition,
    },
  ];
};

export const emit_event = {
  name: `event_emission`,
  interface: `array`,
  title: 'Emit Event',
  items: [
    {
      name: `node_status`,
      label: `When this step becomes`,
      interface: `select`,
      required: true,
      options: node_statuses,
    },
    {
      name: `event_slug`,
      label: `emit an event with slug`,
      interface: `textinput`,
      required: true,
      inputStyle: `sm_minimal`,
    },
    {
      name: `event_status`,
      label: `and status`,
      interface: `select`,
      required: true,
      options: event_statuses,
    },
  ],
};

export const type = {
  name: `type`,
  label: `Tile Type`,
  interface: `select`,
  inputStyle: `muted`,
  options: _.uniq([
    ...generate_select_list(TileNodes),
    ...generate_select_list(ReminderNodes),
  ]).sort(),
  required: true,
};

export const reminderType = {
  name: `type`,
  label: `Tile Type`,
  interface: `select`,
  inputStyle: `muted`,
  options: generate_select_list(ReminderNodes).sort(),
  required: true,
};

export const title = {
  name: 'title',
  label: 'Title',
  interface: 'textinput',
  required: true,
  inputStyle: 'sm_minimal',
};

export const title_rte = {
  name: 'title',
  label: 'Title',
  interface: 'rte',
  required: true,
  inputStyle: 'sm_minimal',
};

export const sequence_in_archipelago = {
  name: `title`,
  interface: `text_horizontal`,
  label: 'First Sequence in Alternative Route',
  description: `This is the name of the initial sequence in your new alternative route.`,
  required: true,
};

export const archipelago_identifier = {
  name: `archipelago_identifier`,
  interface: `text_horizontal`,
  label: 'Title of Alternative Route ',
  description: `The title of your new alternative route.`,
  required: true,
};

export const archipelago_type = {
  name: `is_additive`,
  interface: `buttongroup_horizontal`,
  label: 'Alternative Route Type',
  description: `Additive Alternative Routes will add these tiles to the study when triggered. These tiles will be available in parallel with the standard study tiles. Redirect Alternative Routes will cancel the standard study tiles and move the participant to the new Alternative Route for the remainder of the study.`,
  required: true,
  possible_answers: [
    { value: true, label: `Additive` },
    { value: false, label: `Redirect` },
  ],
};

export const experience_attribute = {
  name: `experience_attribute`,
  label: `Experience Attribute`,
  interface: `textinput`,
  required: false,
  inputStyle: `sm_minimal`,
};

export const label = {
  name: `label`,
  label: `Label`,
  interface: `textarea`,
  required: true,
  inputStyle: `sm_minimal`,
};

export const merge_data = {
  name: `merge_data`,
  interface: `array`,
  title: 'Merge Data',
  items: [
    {
      name: `key`,
      label: `Key`,
      interface: `textinput`,
      required: true,
      inputStyle: `sm_minimal`,
    },
    {
      name: `value`,
      label: `Value`,
      interface: `textinput`,
      required: true,
      inputStyle: `sm_minimal`,
    },
  ],
};

export const validatePersonalizations = (v) => {
  if (v && new Set(v.map((item) => item.key)).size < v.length) {
    return 'personalization keys must be unique';
  }

  return undefined;
};

const reserved = [
  'first_name',
  'last_name',
  'email',
  'phone_number',
  'button_link',
];

export const validatePersonalizationKey = (v) => {
  if (!v) return undefined;

  if (reserved.indexOf(v) >= 0) {
    return `"${v}" is reserved for use by Triage`;
  }

  if (!v.match(/^[a-zA-Z0-9]+$/)) {
    return 'keys must be alphanumeric';
  }

  return undefined;
};

export const email_personalizations = {
  title: 'Personalizations',
  description: `The following personalizations are automatically added by Triage: ${reserved.join(
    ', ',
  )}`,
  name: 'sendgrid_personalizations',
  interface: 'array',
  condition: negate(showInterfaceForSendgridId),
  validate: [validatePersonalizations],
  limitLength: 5,
  items: [
    {
      name: `key`,
      label: `Key`,
      interface: `textinput`,
      required: true,
      inputStyle: `sm_minimal`,
      validate: [validatePersonalizationKey],
    },
    {
      name: `value`,
      label: `Value`,
      interface: `textinput`,
      required: true,
      inputStyle: `sm_minimal`,
    },
  ],
};

export const use_sendgrid = {
  label: 'Use SendGrid',
  name: 'use_sendgrid',
  interface: 'checkbox',
};

export const sendgrid_template_id = {
  label: 'SendGrid Dynamic Template ID',
  name: 'sendgrid_template_id',
  interface: 'textinput',
  required: showInterfaceForSendgridId,
  condition: showInterfaceForSendgridId,
  parse: enforceNoWhiteSpace_parse,
};

export const sendgrid_declined_template_id = {
  label: 'SendGrid Declined ICF Template ID',
  name: 'sendgrid_declined_template_id',
  interface: 'textinput',
  required: showInterfaceForSendgridId,
  condition: showInterfaceForSendgridId,
  parse: enforceNoWhiteSpace_parse,
};

export const survey_url = {
  name: `survey_url`,
  label: 'Survey URL',
  placeholder: `https://foo.com?some_query_string`,
  interface: `textinput`,
  inputStyle: `sm_minimal`,
  required: true,
};

export const external_url = {
  name: `external_url`,
  label: 'External URL',
  placeholder: `https://foo.com?some_query_string`,
  interface: `textinput`,
  inputStyle: `sm_minimal`,
};

export const external_title = {
  name: `external_title`,
  label: `External Title`,
  interface: `textinput`,
  inputStyle: `sm_minimal`,
};

export const description = {
  name: 'description',
  label: 'Description',
  interface: 'rte',
  inputStyle: 'sm_minimal',
};

export const description_rte = {
  name: 'description',
  label: 'Description',
  interface: 'rte',
  required: true,
  inputStyle: 'sm_minimal',
};

export const completion_message = {
  name: `completion_message`,
  label: `Completion Message`,
  interface: `textinput`,
  inputStyle: `sm_minimal`,
};

export const action_title = {
  name: `action_title`,
  label: `Action Title`,
  interface: `textinput`,
  inputStyle: `sm_minimal`,
};

export const validateURL = (url) => {
  if (url) {
    try {
      new URL(url);
    } catch (error) {
      return 'Must be a valid URL';
    }
  }
};

export const validateRichTextLength = (maxLength) => (v) => {
  if (v) {
    const noRichText = v.replace(/(<([^>]+)>)/gi, '');
    if (noRichText.length > maxLength) {
      return CHARACTERS_EXCEEDS_LENGTH(noRichText.length, maxLength);
    }
  }
};

export const tooltip = {
  name: 'tooltip',
  label: 'Tooltip',
  interface: 'rte',
  inputStyle: 'sm_minimal',
  validate: [validateRichTextLength(256)],
};

export const subject = {
  name: `subject`,
  label: `Subject`,
  interface: `textinput`,
  required: true,
  inputStyle: `sm_minimal`,
};

export const body = {
  name: 'body',
  title: 'Body paragraphs',
  interface: 'array',
  required: true,
  isOrdered: true,
  items: [
    {
      name: 'section_button',
      label: 'Body Content',
      interface: 'textinput',
      required: true,
      inputStyle: 'sm_minimal',
      condition: (path, { values }) => {
        const toPath = getToPath(path, 'labels');
        return ['button'].includes(_.at(values, toPath)[0]);
      },
    },
    {
      name: 'section',
      label: 'Body Content',
      interface: 'rte',
      dataTest: 'email-body-content',
      required: true,
      inputStyle: 'sm_minimal',
      className: 'email-setting-body-content',
      condition: (path, { values }) => {
        const toPath = getToPath(path, 'labels');
        return ['greeting', 'body', 'signature'].includes(
          _.at(values, toPath)[0],
        );
      },
    },
    {
      name: 'labels',
      interface: 'select',
      inputStyle: 'muted',
      label: 'Labels',
      options: ['button', 'greeting', 'body', 'signature'],
      required: true,
    },
  ],
};

export const sms_messages = ({ masonryEnabled = false }) => ({
  name: 'sms_messages',
  title: 'SMS content',
  interface: 'array',
  required: true,
  isOrdered: true,
  items: [
    {
      name: masonryEnabled ? 'message' : 'message_body',
      label: 'SMS Message',
      interface: 'textarea',
    },
  ],
});

export const enabled = {
  name: `enabled`,
  interface: `checkboxinput`,
  label: `Enabled`,
};

export const optional = {
  name: `optional`,
  interface: `checkboxinput`,
  label: `Optional Step`,
};

export const on_dashboard = {
  name: `on_dashboard`,
  label: `Display on dashboard`,
  interface: `checkboxinput`,
};

export const possible_answers = {
  name: `possible_answers`,
  interface: `array`,
  movable: true,
  title: `Possible Answers`,
  isOrdered: true,
  items: [
    {
      label: `Label`,
      name: `label`,
      interface: `textarea`,
      description: `The answer displayed to the user on the screen.`,
      inputStyle: `sm_minimal`,
    },
    {
      label: `Answer`,
      name: `answer`,
      interface: `textinput`,
      description: `The value sent to the back end.`,
      parse: enforceLowercase_parse,
      required: true,
      inputStyle: `sm_minimal`,
    },
  ],
};

export const none_label = {
  label: `"None" display label`,
  name: `noneLabel`,
  description: `The label used for the "none of the above" selection in the list of possible choices.`,
  interface: `textinput`,
  inputStyle: `sm_minimal`,
};

export const transmittable_attributes = {
  name: `transmittable_attributes`,
  interface: `flatArray`,
  label: `Transmittable Attributes`,
  inputStyle: `sm_minimal`,
};

export const mask = {
  name: `mask`,
  interface: `select`,
  inputStyle: `muted`,
  label: `Input Field Type`,
  options: AllMaskOptions,
  description: `The format in which the question must be answered in.`,
};

export const user_data = {
  name: `user_data`,
  interface: `array`,
  label: `User Data`,
  items: [
    {
      name: `user_data`,
      label: `Data`,
      interface: `textinput`,
      inputStyle: `sm_minimal`,
    },
  ],
};

export const form = {
  name: `form`,
  title: `Form`,
  interface: `array`,
  movable: true,
  items: [
    {
      name: `interface`,
      label: `Interface`,
      interface: `select`,
      inputStyle: `muted`,
      options: [
        `text`,
        `number`,
        `selectadvanced`,
        `checkbox`,
        `radio`,
        `date`,
        `email`,
        `tel`,
        `range`,
        `password`,
        `heading`,
        `paragraph`,
        `zip_code`,
        `state`,
        `scrollbox`,
        `scrollboxLarge`,
      ].sort(),
    },
    {
      name: `label`,
      label: `Label`,
      interface: `textarea`,
      inputStyle: `sm_minimal`,
    },
    {
      name: `name`,
      label: `Name`,
      interface: `textinput`,
      inputStyle: `sm_minimal`,
      parse: enforceLowercase_parse,
    },
  ],
};

export const file_config_options = [
  {
    name: `key`,
    label: `Key`,
    interface: `select`,
    inputStyle: `muted`,
    options: [`maxFileSize`, `maxFiles`, `buttonText`, `imgExtension`].sort(),
    required: true,
  },
  {
    name: `value`,
    label: `value`,
    interface: `textinput`,
    required: true,
  },
];

export const multi_page_form = {
  name: `form`,
  title: `Pages`,
  interface: 'array',
  movable: true,
  items: [
    {
      title: `Page`,
      name: `page`,
      interface: `array`,
      movable: true,
      required: true,
      items: ConstructFields({}),
    },
  ],
};

export const offset = {
  name: `offset`,
  interface: `numberinput`,
  label: `Offset`,
  inputStyle: `sm_minimal`,
};

export const units = {
  name: `units`,
  interface: `select`,
  inputStyle: `muted`,
  label: `Units`,
  options: [`seconds`, `minutes`, `hours`, `days`],
};

export const cancellation__depends_on = {
  title: `Cancellation Depends On`,
  name: `cancellation__depends_on`,
  interface: 'array',
  inputStyle: 'sm_minimal',
  items: [
    {
      name: 'cancellation_key_event',
      label: 'Event slug',
      interface: 'textinput',
      inputStyle: 'sm_minimal',
      required: true,
    },
    {
      name: 'cancellation_key_status',
      label: 'status',
      interface: 'select',
      inputStyle: 'sm_minimal',
      required: true,
      options: event_statuses,
    },
  ],
};

export const stale__depends_on = {
  name: `stale__depends_on`,
  interface: `flatArray`,
  label: `Stale Depends On`,
  inputStyle: `sm_minimal`,
};

export const availability__depends_on = {
  name: `availability__depends_on`,
  title: `Availability Depends On`,
  interface: 'array',
  inputStyle: 'sm_minimal',
  items: [
    {
      name: 'availability_key_event',
      label: 'Event slug',
      interface: 'textinput',
      inputStyle: 'sm_minimal',
      required: true,
    },
    {
      name: 'availability_key_status',
      label: 'status',
      interface: 'select',
      inputStyle: 'sm_minimal',
      required: true,
      options: event_statuses,
    },
  ],
};

export const visibility__depends_on = {
  name: 'visibility__depends_on',
  interface: 'array',
  title: 'Visibility Depends On',
  items: [
    {
      name: 'visibility_key_event',
      label: 'Event slug',
      interface: 'textinput',
      inputStyle: 'sm_minimal',
      required: true,
    },
    {
      name: 'visibility_key_status',
      label: 'status',
      interface: 'select',
      inputStyle: 'sm_minimal',
      required: true,
      options: event_statuses,
    },
  ],
};

export const disqualification__depends_on = {
  name: `disqualification__depends_on`,
  interface: `flatArray`,
  label: `Disqualification Depends On`,
  inputStyle: `sm_minimal`,
};

export const completion__depends_on = {
  name: `completion__depends_on`,
  interface: `flatArray`,
  label: `Completion Depends On`,
  inputStyle: `sm_minimal`,
};

export const batch_size = {
  name: `batch_size`,
  interface: `numberinput`,
  label: `Batch Size`,
  config: {
    minimum: 1,
  },
  inputStyle: `sm_minimal`,
};

export const arms = {
  name: `arms`,
  interface: `array`,
  label: `Arms`,
  items: [
    {
      name: `label`,
      interface: `textarea`,
      label: `Label`,
      inputStyle: `sm_minimal`,
    },
    {
      name: `cap`,
      interface: `numberinput`,
      label: `Cap`,
      config: {
        minimum: 0,
      },
      inputStyle: `sm_minimal`,
    },
  ],
};

export const attachments = {
  name: `attachments`,
  interface: `array`,
  label: `Attachments`,
  items: [
    {
      name: `file_name`,
      interface: `textinput`,
      label: `File Name`,
      inputStyle: `sm_minimal`,
      required: true,
    },
    {
      name: `node_slug`,
      interface: `textinput`,
      label: `Node Slug`,
      inputStyle: `sm_minimal`,
      required: true,
    },
  ],
};

export const specify_recipients = {
  name: `recipients`,
  interface: `checkboxgroup`,
  label: `Send Email To`,
  possible_answers: [
    { answer: `email`, label: `Participant` },
    { answer: `crc_email`, label: `CRC` },
    { answer: `caretaker_email`, label: `Caretaker` },
  ],
};

export const generate_billboard_display_options = (type) => ({
  name: `layout`,
  label: `Layout type`,
  interface: `radio`,
  possible_answers: [
    { label: `Dashboard (default)`, value: `dashboard` },
    { label: `Non-dashboard`, value: `${type}` },
  ],
});

export const user_data_key = {
  name: `user_data_key`,
  label: `User Data Key`,
  interface: `textinput`,
  required: false,
  inputStyle: `sm_minimal`,
};

export const followUpRegEx = /\[(.*)\]/;
export const auth_token_boolean = `__auth_token_required`;

export const showInterfaceForAuth = (path, state) => {
  return shouldShowInterface(path, state, auth_token_boolean);
};

export const getFollowUpPath = (path) => {
  return path.find((value) => value.startsWith('follow_up'));
};

/**
 * Get follow up options for the Tile based on the parent Sequence
 *
 * @param {object} application_state
 * @param {string} tileId
 * @param {boolean} masonryEnabled
 * @returns {array} array of tile option hashes
 */
export const getFollowUpTileOptions = (
  application_state,
  tileId,
  masonryEnabled = false,
) => {
  const sequence = _.find(application_state.sequences, (sequence) => {
    return sequence.tile_ids.includes(tileId);
  });
  const tiles = application_state.tiles;
  const tileOptions = [];

  if (sequence) {
    for (let key in tiles) {
      if (sequence.tile_ids.includes(key)) {
        const content = tiles[key]?.['content'];
        const experiences = masonryEnabled ? content : content?.['experiences'];

        let label;
        if (experiences) {
          label = experiences['default']['title'] || key;
        } else {
          label = key;
        }

        tileOptions.push({
          value: key,
          label: label,
        });
      }
    }
  }
  return tileOptions;
};

/**
 * Get follow up options for the Tile based on the parent Sequence
 *
 * @param {array} tile options
 * @returns {array} follow up form configuration
 */
export const followUp = (tileOptions = []) => {
  return [
    {
      name: 'follow_up',
      title: 'Follow Up Answers',
      interface: 'array',
      items: [
        {
          label: 'Answer Value',
          name: 'answer_value',
          interface: 'textinput',
          inputStyle: 'muted',
        },
        {
          label: 'Resource Type',
          name: 'resource_type',
          interface: 'select',
          options: [
            { label: 'node', value: 'node' },
            { label: 'none (success message only)', value: 'none' },
            { label: 'web', value: 'web' },
          ],
          inputStyle: 'muted',
        },
        {
          label: 'Resource Value',
          name: 'resource_value_web',
          interface: 'textinput',
          inputStyle: 'muted',
          condition: (path, state) => {
            const followUpPath = getFollowUpPath(path);
            const resource_type =
              state.values.content.follow_up[
                followUpPath.match(followUpRegEx)[1]
              ]?.resource_type;

            return resource_type === 'web';
          },
        },
        {
          label: 'Resource Value',
          name: 'resource_value_node',
          interface: 'select',
          inputStyle: 'muted',
          options: tileOptions,
          placeholder: 'Survey Tile UUID',
          condition: (path, state) => {
            const followUpPath = getFollowUpPath(path);
            const resource_type =
              state.values.content.follow_up?.[
                followUpPath.match(followUpRegEx)[1]
              ]?.resource_type;

            return resource_type === 'node';
          },
        },
      ],
    },
    {
      label: 'Auth Token Expiration',
      name: 'auth_token_header',
      interface: 'heading',
    },
    {
      label: 'Requires Auth Token',
      name: auth_token_boolean,
      interface: 'checkbox',
    },
    {
      label: 'Amount',
      name: 'auth_token_expiration_amount',
      interface: 'number',
      inputStyle: 'muted',
      placeholder: 'expiration amount',
      parse: (v) => parseInt(v, 10),
      required: true,
      condition: showInterfaceForAuth,
    },
    {
      label: 'Units',
      name: 'auth_token_expiration_units',
      interface: 'select',
      inputStyle: 'muted',
      options: ['seconds', 'minutes', 'hours', 'days'],
      required: true,
      condition: showInterfaceForAuth,
    },
  ];
};

export const DoNotShowInterfaceForType = ({
  path,
  values,
  types = [],
  adjacent_key = `interface`,
}) => {
  if (values) {
    const toPath = getToPath(path, adjacent_key);
    const atLocation = _.at(values, toPath)[0];
    return !types.includes(atLocation);
  }
};

export const ShowInterfaceForType = ({
  path,
  values,
  types = [],
  adjacent_key = `interface`,
}) => {
  if (values) {
    const toPath = getToPath(path, adjacent_key);
    const atLocation = _.at(values, toPath)[0];
    return types.includes(atLocation);
  }
};

const PDF_marginSizes = [
  `0.25in`,
  `0.5in`,
  `0.75in`,
  `1in`,
  `1.25in`,
  `1.5in`,
  `1.75in`,
  `2in`,
  `2.25in`,
  `2.5in`,
  `2.75in`,
  `3in`,
];

export const pdfConfiguration = ({ study_slug }) => [
  {
    name: `header_image_url`,
    label: `Header Image`,
    interface: `connected_file`,
    parse: trim_string_parse,
    config: {
      onFileAdd: (data, cb) => onImageAdd({ data, study_slug }, cb),
    },
    inputStyle: `sm_minimal`,
  },
  {
    name: `margin_top`,
    label: `Header height`,
    interface: `selectadvanced`,
    inputStyle: `muted`,
    options: PDF_marginSizes,
  },
  {
    name: `footer_image_url`,
    label: `Footer Image`,
    interface: `connected_file`,
    parse: trim_string_parse,
    config: {
      onFileAdd: (data, cb) => onImageAdd({ data, study_slug }, cb),
    },
    inputStyle: `sm_minimal`,
  },
  {
    name: `margin_bottom`,
    label: `Footer height`,
    interface: `selectadvanced`,
    inputStyle: `muted`,
    options: PDF_marginSizes,
  },
];

export const pdfPreviewConfiguration = (props) => ({
  name: `Download`,
  interface: `button`,
  label: () => (
    <span>
      <DownloadIcon width="15px" height="15px" color="#2e6aff" /> Download as
      PDF
    </span>
  ),
  onClick: () => downloadPdfPreview(props),
  buttonStyle: `sm_default_blue`,
});

export const send_email_preview = (props) => ({
  name: 'Preview',
  interface: 'button',
  label: 'Preview',
  onClick: () => sendPreviewEmail(props),
  buttonStyle: `sm_default_blue`,
});

export const one_click_contribution_keys = {
  name: `one_click_contribution_keys`,
  interface: `flatArray`,
  label: `One Click Contribution Keys`,
  inputStyle: `sm_minimal`,
  parse: enforceLowercase_parse,
};

export const requires_reauth = {
  name: `reauth`,
  interface: `checkbox`,
  label: `Require re-authentication before submitting?`,
};

export const review_before_submit = {
  name: `review_before_submit`,
  interface: `checkbox`,
  label: `Require Review Before Submission`,
};

export const review_banner = {
  name: `review_banner`,
  interface: `rte`,
  inputStyle: `sm_minimal`,
  label: `Review Banner`,
};

export const completion_event = [
  {
    name: `default_completion_event_slug`,
    interface: `checkboxinput`,
    label: `Default (Complete Tile)`,
    initialValue: true,
  },
  {
    name: `completion_event_slug`,
    label: `Custom Event to Complete`,
    interface: `textinput`,
    inputStyle: `sm_minimal`,
    required: true,
    parse: enforceLowercase_parse,
    condition: (path, { values }) => {
      const toPath = getToPath(path, 'default_completion_event_slug');
      return !_.get(values, toPath);
    },
  },
];

// Before we fully move to mustache as our template engine we are giving the users an option to maintain backwards compatibility.
export const html_formatter = {
  name: 'html_formatter',
  label: 'HTML formatter',
  interface: 'selectadvanced',
  options: [
    { value: 'mustache', label: '{{}} syntax' },
    { value: 'triage', label: '$() syntax' },
  ],
  initialValue: { value: 'mustache', label: '{{}} syntax' },
  required: true,
};

export const assessment_platform = {
  label: `Assessment Platform`,
  name: `assessment_platform`,
  interface: `select`,
  omitPlaceholder: true,
  defaultValue: 'both',
  possible_answers: [
    { value: `web`, label: `Web` },
    { value: `mobile`, label: `Mobile` },
    { value: `both`, label: `Both` },
  ],
};

export const platform_error_message = {
  label: `Platform Error Message`,
  name: `error_message`,
  interface: `textarea`,
};

export const displayGroupNameDefaultOption = {
  label: '(No Display Group)',
  value: 'no_display_group',
};

/**
 * @description Function that generates a select configuration object with the name of 'display_group_id'
 * @param {String[]} names - Array of display group names
 * @returns Display group names select tile configuration object
 */
export const display_group_name = (names) => {
  const options = [displayGroupNameDefaultOption];

  if (Array.isArray(names) && names.length) {
    options.push(
      ...names.map((n) => ({
        label: n,
        value: n,
      })),
    );
  }

  return {
    interface: 'select',
    name: 'display_group_id',
    label: 'Display Group Name',
    options,
    defaultValue: displayGroupNameDefaultOption.value,
    omitPlaceholder: true,
  };
};

/**
 * @description Function to generate a external title for an experience. This is restricted by
 *  the `masonryEnabled` feature flag in any schema that uses this function. This is meant
 *  for use in Billboard-facing tiles within the new components architecture.
 * @param {Boolean} masonryEnabled - default to false
 * @returns {Object} external title hash if new components are enabled, otherwise standard
 *  content slate title
 */
export const experienceExternalTitle = (masonryEnabled = false) => {
  if (masonryEnabled) {
    return {
      name: `external_title`,
      label: `External Title`,
      interface: 'rte',
      inputStyle: 'sm_minimal',
    };
  }
  return title;
};

export const experienceExternalDescription = (masonryEnabled = false) => {
  return masonryEnabled
    ? { ...description, interface: 'rte', label: 'External Descripton' }
    : { ...description, interface: 'rte' };
};

/**
 * @param {Boolean} masonryEnabled - default to false
 * @returns {Object} slate title hash if new components are enabled, otherwise empty hash
 */
export const experienceTileTitle = (masonryEnabled = false) => {
  return masonryEnabled ? { ...title, label: 'Tile Title' } : null;
};

/**
 * @param {Boolean} masonryEnabled - default to false
 * @returns {Object} slate description hash if new components are enabled, otherwise empty hash
 */
export const experienceTileDescription = (masonryEnabled = false) => {
  return masonryEnabled
    ? {
        name: 'description',
        interface: 'textarea',
        inputStyle: 'sm_minimal',
        label: 'Tile Description',
      }
    : null;
};

export const dependencyCriteria = ({
  emitEvent = false,
  availability = false,
  visibility = false,
  completion = false,
  cancellation = false,
  disqualification = false,
  stale = false,
}) => {
  const criteria = [];

  if (emitEvent) {
    criteria.push(emit_event);
  }
  if (availability) {
    criteria.push(availability__depends_on);
  }
  if (visibility) {
    criteria.push(visibility__depends_on);
  }
  if (completion) {
    criteria.push(completion__depends_on);
  }
  if (cancellation) {
    criteria.push(cancellation__depends_on);
  }
  if (disqualification) {
    criteria.push(disqualification__depends_on);
  }
  // TBD: stale might be deprecated
  if (stale) {
    criteria.push(stale__depends_on);
  }

  return criteria;
};

export const genderDefaultValues = {
  interface: 'checkboxWithNone',
  possible_answers: [
    {
      label: 'Female',
    },
    { label: 'Male' },
    { label: 'Non-binary / Gender Queer' },
    { label: 'Transgender Female/Trans Woman/MtF' },
    { label: 'Transgender Male/Trans Man/FtM' },
    { label: 'A gender not listed here' },
  ],
  mutually_exclusive_answers: [
    {
      label: 'I prefer not to answer',
    },
  ],
  label: 'What is your current gender? Please select all that apply.',
};

export const ethnicityDefaultValues = {
  label:
    'Which racial or ethnic group(s) do you identify with? Please select all that apply.',
  interface: 'checkboxWithNone',
  possible_answers: [
    {
      label: 'American Indian or Alaskan Native',
    },
    { label: 'Asian' },
    { label: 'Black or African American' },
    { label: 'Hispanic, Latino, or Spanish origin' },
    { label: 'Middle Eastern or North African' },
    { label: 'Native Hawaiian or Other Pacific Islander' },
    { label: 'White' },
    { label: 'Another race or ethnicity' },
  ],
  mutually_exclusive_answers: [
    {
      label: 'I prefer not to answer',
    },
  ],
};

export const sexDefaultValues = {
  label:
    'What sex were you assigned at birth (for example, what was initially listed on your birth certificate)?',
  interface: 'radio',
  possible_answers: [
    {
      label: 'Female',
    },
    { label: 'Intersex' },
    { label: 'Male' },
    { label: 'None of these describe me ' },
  ],
  mutually_exclusive_answers: [
    {
      label: 'I prefer not to answer',
    },
  ],
};

export const pregnancyDefaultValues = {
  label:
    'Are you currently pregnant or plan to become pregnant in the next year?',
  interface: 'radio',
  possible_answers: [
    {
      label: 'Yes, I am currently pregnant',
    },
    {
      label: 'Yes, I am planning to become pregnant in the next year',
    },
    { label: 'No' },
    { label: 'I don’t know ' },
  ],
  mutually_exclusive_answers: [
    {
      label: 'I prefer not to answer',
    },
  ],
};

export const setDefaultValues = (baseValues) => {
  switch (baseValues.interface) {
    case 'gender':
      return { ...baseValues, ...genderDefaultValues };
    case 'ethnicity':
      return { ...baseValues, ...ethnicityDefaultValues };
    case 'sex':
      return { ...baseValues, ...sexDefaultValues };
    case 'pregnancy':
      return { ...baseValues, ...pregnancyDefaultValues };
    case 'hidden':
      return { ...baseValues, ...{ required: false } };
    case 'insurance_provider_id':
      return { ...baseValues, ...{ interface: 'insuranceProviderId' } };
    case 'scrollbox_large':
      return { ...baseValues, ...{ interface: 'scrollboxLarge' } };
    case 'select_number_range':
      return { ...baseValues, ...{ interface: 'select-number-range' } };
    case 'select_advanced':
      return { ...baseValues, ...{ interface: 'selectadvanced' } };
    case 'validated_text':
      return { ...baseValues, ...{ interface: 'validatedText' } };
    case 'checkbox':
      return { ...baseValues, ...{ interface: 'checkboxWithNone' } };
    default:
      return baseValues;
  }
};
