import React from 'react';
import { connect } from 'react-redux';
import { Application, SlideOutPanelButtonWrapper } from './components';
import {
  clearAllGraphData,
  loadGraph,
  loadGraphPreview,
} from '../../actions/combination';
import GlobalHeader from '../GlobalHeader';
import Button from '../inputs/Button';
import SecondaryMenu from '../SecondaryMenu';
import SlideOutPanel from '../SlideOutPanel';
import StudyLoader from '../studyLoader';
import Sidebar from '../StudyMenu';
import VersionHistory from './components/VersionHistory';
import CrcModal from './components/CrcModal';
import ExportLangModal from './components/ExportStudyTranslate';
import ImportLangModal from './components/ImportStudyTranslate';
import RevertModal from './components/RevertModal';
import VersionPreview from './components/VersionPreview';
import { toastr } from 'react-redux-toastr';
import { Redirect } from 'react-router-dom';
import useStudyLayout from './useStudyLayout';
import { clearPublishErrors } from '../../actions/publish_errors_actions';
import ExportAuditTrailModal from '../../routes/edit/panels/ExportAuditTrailModal';

export { Application } from './components';

const VERSION_HISTORY_CLOSE_BUTTON_TEXT = 'CLOSE';
const VERSION_HISTORY_CLOSE_BUTTON_PREVIEW_TEXT = 'CANCEL PREVIEW & CLOSE';

export function StudyWrapper(props) {
  const {
    checkPermissions,
    PathToStudy,
    sidebarMenuOptions,
    isSlate,
    isConfigure,
    actions,
    hasSideNav,
    hasTopNav,
    versionHistorySidebar,
    loading,
    cra,
    exportImportLang,
    exportAuditTrail,
  } = useStudyLayout({ ...props, clearPublishErrors });
  const { location, match, graph, children } = props;
  if (loading) {
    return <StudyLoader />;
  }
  if (!checkPermissions(location.pathname, PathToStudy, actions)) {
    toastr.error(
      `This content is not available, or is only visible to an audience you're not in.`,
    );
    return <Redirect to={PathToStudy} />;
  }
  return (
    <>
      <Application
        pathname={location.pathname}
        hasTopNav={hasTopNav}
        isConfigure={isConfigure}
        hasSideNav={hasSideNav}
      >
        <GlobalHeader
          canEdit={false}
          match={match}
          isSlate={isSlate}
          disabled={versionHistorySidebar.preview}
          isConfigure={isConfigure}
          openExportLangFile={exportImportLang.toggleExportModal}
          openImportLangFile={exportImportLang.toggleImportModal}
          toggleVersionHistory={versionHistorySidebar.toggleVersionHistoryModal}
          toggleExportAuditTrail={exportAuditTrail.toggleExportAuditTrailModal}
        />
        {hasSideNav && (
          <Sidebar
            menuOptions={sidebarMenuOptions}
            disabled={versionHistorySidebar.preview}
          />
        )}
        {hasTopNav ? (
          <React.Fragment>
            <SecondaryMenu {...props} />
            {children}
          </React.Fragment>
        ) : (
          children
        )}
        {versionHistorySidebar.preview && (
          <VersionPreview loading={versionHistorySidebar.previewLoading} />
        )}
        <SlideOutPanel
          open={versionHistorySidebar.versionHistoryModal}
          from="right"
          onClickOutside={versionHistorySidebar.handleOnClickOutside}
        >
          <VersionHistory
            graph={graph}
            onClose={versionHistorySidebar.clearPreview}
            versions={versionHistorySidebar.versions}
            loading={versionHistorySidebar.previewLoading}
            onRevert={versionHistorySidebar.enableRevertAndSetSelected}
            canRevert={versionHistorySidebar.canUserRevertPublishes}
            onSetPreview={versionHistorySidebar.loadPreview}
          />
          <SlideOutPanelButtonWrapper>
            <Button
              innerType="button"
              type="primary"
              onClick={() =>
                versionHistorySidebar.preview
                  ? versionHistorySidebar.clearPreview({ closeModal: true })
                  : versionHistorySidebar.toggleVersionHistoryModal()
              }
            >
              {versionHistorySidebar.preview
                ? VERSION_HISTORY_CLOSE_BUTTON_PREVIEW_TEXT
                : VERSION_HISTORY_CLOSE_BUTTON_TEXT}
            </Button>
          </SlideOutPanelButtonWrapper>
        </SlideOutPanel>
      </Application>
      {cra.craModal && (
        <CrcModal
          graphId={match.params.graph_id}
          roleId={cra.roleId}
          onClose={cra.toggleCraModal}
        />
      )}
      {versionHistorySidebar.revertModal &&
        versionHistorySidebar.selectedVersion && (
          <RevertModal
            onRevert={versionHistorySidebar.revertStudy}
            selectedVersion={versionHistorySidebar.selectedVersion}
            onClose={versionHistorySidebar.toggleRevertModal}
          />
        )}
      {exportImportLang.exportModal && (
        <ExportLangModal onClose={exportImportLang.toggleExportModal} />
      )}
      {exportImportLang.importModal && (
        <ImportLangModal onClose={exportImportLang.toggleImportModal} />
      )}
      {exportAuditTrail.exportAuditTrailModal && (
        <ExportAuditTrailModal
          onClose={exportAuditTrail.toggleExportAuditTrailModal}
          url={match?.url}
        />
      )}
    </>
  );
}

export default connect(
  ({ graph, tiles, user: { authorization, username } }) => ({
    authorization,
    username,
    graph,
    hasVisitPortal: false,
  }),
  {
    loadGraph,
    loadGraphPreview,
    clearAllGraphData,
  },
)(StudyWrapper);
