import { type FeatureFlag } from 'src/api/workspaces';

export const filterFeatureFlagsByComponents = (
  features?: FeatureFlag[],
): FeatureFlag[] => {
  if (!features) return [];
  return features.filter(
    (feature) => feature.category === 'component' && Boolean(feature.status),
  );
};

export const filterFeatureFlagsByTiles = (
  features?: FeatureFlag[],
): FeatureFlag[] => {
  if (!features) return [];

  return features.filter(
    (feature) => feature.category === 'tile' && Boolean(feature.status),
  );
};

export const filterFeatureFlagsByFeature = (
  features?: FeatureFlag[],
): FeatureFlag[] => {
  if (!features) return [];

  return features.filter(
    (feature) => feature.category === 'feature' && Boolean(feature.status),
  );
};
