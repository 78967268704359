import React, { PureComponent } from 'react';
import { number, string } from 'prop-types';

import GetArms from '../../../selectors/arms';
import Schemas from '../forms';
import _ from 'lodash';
import clone from '../../../utils/objectClone';
import { connect } from 'react-redux';
import generateInitialValues from '../utils/generateInitialValues';
import getSchema, { buildTranslationSchema } from '../utils/getSchema';
import getType from '../utils/getType';
import { modify_resource } from '../../../actions/slate_actions';
import { setExperiences } from '../../../actions/configure';
import getExternalIds from '../../../utils/getExternalIds';
import { apiGraph } from '../../../api';
import {
  arrayTranslationFields,
  graphTranslationFields,
} from '../utils/translationFieldConstants';

const panelDictionary = {
  display_participants: `Display & Participants`,
};

const titleCase = (str) =>
  str
    .toLowerCase()
    .split(' ')
    .map((word) => word.replace(word[0], word[0].toUpperCase()))
    .join(' ');
const snakeToTitle = (s) => titleCase(s.replace(/_/g, ' '));

/**
 * @description Takes incoming primary locale form values compares to
 * allowed translation fields, then combine the primary locale values with
 * the expected secondary values. This allows us to populate the forms
 * with values for schemas modified for i18n.
 *
 * @param {Object}
 * @returns {Object}
 */
export const i18nCombineInitialValues = ({
  primaryLocaleValues,
  currentLocaleValues,
  translationFields,
  currentLocale,
}) => {
  const primaryLocaleKeys = Object.keys(primaryLocaleValues);
  return primaryLocaleKeys.reduce((acc, fieldKey) => {
    if (translationFields.includes(fieldKey) && currentLocaleValues[fieldKey]) {
      return {
        ...acc,
        [fieldKey]: primaryLocaleValues[fieldKey],
        [`${fieldKey}_${currentLocale}`]: currentLocaleValues[fieldKey],
      };
    } else if (arrayTranslationFields.hasOwnProperty(fieldKey)) {
      const mergedValues = primaryLocaleValues[fieldKey].reduce(
        (acc, field) => {
          const findSecondaryField = currentLocaleValues[fieldKey].find(
            (sec) => sec.identifier === field.identifier,
          );

          if (findSecondaryField) {
            const secondaryKeys = Object.keys(findSecondaryField);

            const newSecondaryValues = secondaryKeys.reduce(
              (acc, key) => ({
                ...acc,
                [`${key}_${currentLocale}`]: findSecondaryField[key],
              }),
              {},
            );

            return [...acc, { ...field, ...newSecondaryValues }];
          }

          return [...acc, field];
        },
        [],
      );

      return { ...acc, [fieldKey]: mergedValues };
    }

    return { ...acc, [fieldKey]: primaryLocaleValues[fieldKey] };
  }, {});
};

// TODO: This needs to be refactored to be specifically for modals
// currently it has legacy code that supported tile editing as well
class PanelEditWrapper extends PureComponent {
  state = {
    panel_forms: undefined,
    active_panel: `Content`,
    current_page: 0,
    panel_list: [],
    loading: true,
    modifiedGraphValues: undefined,
  };

  componentDidMount() {
    let location = this.state.active_panel;

    if (this.props.active_panel) {
      let activePanelFromProps = snakeToTitle(this.props.active_panel);

      if (_.has(panelDictionary, this.props.active_panel)) {
        activePanelFromProps = panelDictionary[this.props.active_panel];
      }

      location = activePanelFromProps;
    }

    if (this.props.shouldGetGraphTranslations) {
      this.handleI18nGraphValues();
    }

    this.form_setup(location);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.configure.active_experience !==
        prevProps.configure.active_experience ||
      Object.keys(this.props.languages).length !==
        Object.keys(prevProps.languages).length
    ) {
      this.setState({ loading: true }, () => {
        this.form_setup(this.state.active_panel);
      });
    }

    if (
      this.props.active_panel !== prevProps.active_panel ||
      this.props.currentLocale !== prevProps.currentLocale
    ) {
      // hack to force curious panda to update when it receives new this.props
      // TODO: fix Curious Panda for new schema props
      let activePanelFromProps = snakeToTitle(this.props.active_panel);

      if (_.has(panelDictionary, this.props.active_panel)) {
        activePanelFromProps = panelDictionary[this.props.active_panel];
      }

      if (this.props.shouldGetGraphTranslations) {
        this.handleI18nGraphValues();
      } else {
        this.setState({ modifiedGraphValues: undefined });
      }

      this.form_setup(activePanelFromProps);
    }
  }

  // This is currently written to only handle the content elements of the graph
  handleI18nGraphValues = async () => {
    const { initialValues, currentLocale } = this.props;
    const response = await apiGraph.get(initialValues.id, currentLocale);

    const modifiedGraphValues = {
      ...initialValues,
      content: i18nCombineInitialValues({
        primaryLocaleValues: initialValues.content,
        currentLocaleValues: response.content,
        currentLocale,
        translationFields: graphTranslationFields,
      }),
    };

    this.setState({ modifiedGraphValues });
  };

  addPage = () => {
    let {
      configure: { active_experience },
    } = this.props;
    let initialValues = clone(this.props.initialValues);
    const numberOfPages =
      initialValues.content.experiences[active_experience].form.length;
    const path = `content.experiences[${active_experience}].form[${numberOfPages}].page`;
    _.set(initialValues, path, [
      {
        interface: `paragraph`,
        label: `Page ${numberOfPages + 1}`,
        name: `page_${numberOfPages + 1}`,
      },
    ]);
    this.onSubmit(initialValues);
  };

  removePage = (index) => {
    let {
      configure: { active_experience },
    } = this.props;
    let initialValues = clone(this.props.initialValues);
    initialValues.content.experiences[active_experience].form.splice(index, 1);
    this.onSubmit(initialValues);
  };

  onSubmit = (data) => {
    const {
      resource,
      modify_resource,
      currentLocale,
      primaryLocale,
      i18n_enabled,
    } = this.props;
    const type = getType(data, resource);

    // Currently this added this logic to pass locale to outgoing for just the graph.
    // At some point we may generalize this to work with more types of outgoing formatters,
    // but that will take a larger effort.
    const isTranslatedGraph =
      type === 'graph' && i18n_enabled && currentLocale && primaryLocale;
    const dataToFormat = isTranslatedGraph
      ? { ...clone(data), currentLocale, primaryLocale }
      : clone(data);

    const payload = Schemas[type].outgoing(dataToFormat);
    modify_resource({ data: payload, props: this.props });
  };

  changeActivePanel = (active_panel, current_page = undefined) => {
    this.form_setup(active_panel, current_page);
  };

  changeCurrentPage = (current_page = undefined) => {
    this.form_setup(this.state.active_panel, current_page);
  };

  form_setup = async (active_panel, current_page) => {
    const {
      type,
      initialValues,
      configure: { active_experience },
      languages,
      i18n_enabled,
      masonryEnabled,
      currentLocale,
      primaryLocale,
    } = this.props;

    const isGraph = type === 'graph';

    const panel_forms = getSchema(
      isGraph
        ? {
            content: {
              type,
              roles: this.props?.roles,
              languages,
            },
            i18n_enabled,
            masonryEnabled,
          }
        : initialValues,
    );

    this.props.setExperiences(_.keys(initialValues.content.experiences));
    const panel_list = _.keys(panel_forms);

    const schema = panel_forms[active_panel]({
      ...this.props,
      experience: active_experience,
      current_page,
    });

    const Component = schema?.Component;

    // Currently this is only needed for modifying the settings in the graph,
    // to make this more generalized we just have to remove the isGraph logic
    const modifiedGraphSchema =
      isGraph &&
      i18n_enabled &&
      currentLocale &&
      primaryLocale &&
      currentLocale !== primaryLocale
        ? buildTranslationSchema({ currentLocale, schema, tileType: type })
        : schema;

    // Adding `data_fetched_for_panel` to the state since this component never un-mounts and is shared among all the
    // panels in the settings tab, this is done so we do not re-fetch data form the server.
    this.setState({
      current_page,
      panel_forms,
      panel_list,
      active_panel,
      schema: modifiedGraphSchema,
      loading: false,
      Component,
      shouldShowSaveButton: !!(Component && schema.shouldShowSaveButton),
    });
  };

  render() {
    const {
      changeActivePanel,
      onSubmit,
      props: {
        children,
        resource,
        identifier,
        renderPanelTabNavigation,
        initialValues,
        ...props
      },
      state: { current_page, modifiedGraphValues, ...state },
    } = this;

    if (!state.panel_forms || state.loading) {
      return <div>Loading...</div>;
    }

    const preparedProps = {
      ...state,
      ...props,
      initialValues: modifiedGraphValues ?? initialValues,
      changeActivePanel,
      onSubmit,
    };

    return (
      <React.Fragment>
        {renderPanelTabNavigation && renderPanelTabNavigation(preparedProps)}
        {children(preparedProps)}
      </React.Fragment>
    );
  }
}

PanelEditWrapper.defaultProps = {
  form: `edit_tile`,
  fieldSpacing: 20,
};

PanelEditWrapper.propTypes = {
  fieldSpacing: number.isRequired,
  form: string.isRequired,
  resource: string.isRequired,
};

export default connect(
  (state, props) => {
    const isGraph = props.resource === 'graph';
    const {
      type,
      initialValues,
      externalIds = [],
    } = isGraph
      ? {
          type: `graph`,
          initialValues: Schemas[`graph`].incoming(clone(state.graph)),
          externalIds: getExternalIds(
            state.tiles,
            state.graph?.content?.external_clients,
          ),
        }
      : generateInitialValues(props, state);

    const authorization = state.user.authorization[state.graph.id];
    const roles = authorization ? authorization.roles : [];
    const languages = state.locale?.languages ?? {};
    const i18n_enabled = state.graph?.i18n_enabled;
    const masonryEnabled = state.graph?.masonry_enabled;
    const currentLocale = state.locale?.currentLocale;
    const primaryLocale = state.graph?.primary_locale;

    const shouldGetGraphTranslations =
      isGraph &&
      i18n_enabled &&
      currentLocale &&
      primaryLocale &&
      primaryLocale !== currentLocale;

    return {
      configure: state.configure,
      resource: props.resource,
      arms: GetArms(state.sequences),
      type,
      initialValues,
      roles,
      externalIds,
      languages,
      i18n_enabled,
      masonryEnabled,
      currentLocale,
      primaryLocale,
      shouldGetGraphTranslations,
    };
  },
  { modify_resource, setExperiences },
)(PanelEditWrapper);
