import reduce from 'lodash/reduce';

// Tells curious panda which fields are editable - used in preview tile view
export const editableInterface = {
  rte: false,
  file: `rte`,
  markdown: `rte`,
  array: false,
  flatarray: false,
  scrollbox: `rte`,
  scrollboxsmall: `rte`,
  scrollboxlarge: `rte`,
  label: `rte`,
  heading: `rte`,
  hidden: false,
  paragraph: `rte`,
  radioinput: `rte`,
  radio: `rte`,
  checkbox: `rte`,
  checkboxinput: `rte`,
  checkboxgroup: `rte`,
  checkboxgroupwithnone: `rte`,
  checkboxwithnone: `rte`,
  text: `rte`,
  textinput: `rte`,
  autosize: `rte`,
  textareaautosize: `rte`,
  maskinput: `rte`,
  mask: `rte`,
  numberinput: `rte`,
  textareainput: `rte`,
  state: `rte`,
  select: `rte`,
  selectinput: `rte`,
  selectadvanced: `rte`,
  heightinput: `rte`,
  signature: `rte`,
  signiturepad: `rte`,
  telinput: `rte`,
  emailinput: `rte`,
  passwordonly: `rte`,
  password: `rte`,
  date: `rte`,
  insuranceproviderid: false,
  zip: `rte`,
  contentacceptance: `rte`,
  time: `rte`,
  typeahead: `rte`,
  todays_date: `rte`,
  buttonGroup: `rte`,
};

// fields allowed to be `editable`
export const editableFields = reduce(
  editableInterface,
  (acc, value, key) => (!!value ? [...acc, key.toLowerCase()] : acc),
  [],
);
