import {
  Button,
  Input,
  Label,
  Select,
  Toggle,
  FieldGroup,
  Banner,
  color,
} from '@evidation/ui';
import { CloseCircle } from '@evidation/ui/lib/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import {
  FormState,
  get,
  useFieldArray,
  useForm,
  UseFormReturn,
} from 'react-hook-form';
import EventEmission, {
  eventEmissionValidations,
} from 'src/routes/edit/components/platform-modern/event-emission';
import * as yup from 'yup';

import styled from 'styled-components';
import {
  AvailabilityDependsOn,
  CancellationDependsOn,
  Grid,
  VisibilityDependsOn,
} from 'src/routes/edit/components/platform-modern';

const Container = styled.div`
  & div:empty {
    display: block !important;
  }
  & form {
    margin-bottom: 2rem;
  }
  & fieldset {
    border-radius: 4px;
    background: #f8f8f8;
    padding: 1rem !important;
  }

  legend {
    font-weight: 600;
    color: #555;
  }

  & .http_header_field {
    border-bottom: 1px solid #e7e7e7;
    width: '100%';

    & * {
      margin-right: 0.2em;
    }
  }
`;

const formValidations = yup.object().shape({
  content: yup.object().shape({
    title: yup.string().required('Title is required.'),
    description: yup.string(),
    http_url: yup.string().required('URL is required'),
    http_verb: yup.string().required('HTTP Method is required'),
    retries: yup.string().required('Retries required'),
    retry_delay: yup.string().required('Retry delay required'),
    event_emission: eventEmissionValidations,
    request_body: yup.string().when('http_verb', {
      is: (val: string) => val !== 'GET',
      then: (schema) => schema.required('Request Body is required'),
      otherwise: (schema) => schema.optional(),
    }),
  }),
});

export default function ApiRequestForm({ initialValues, onSubmit }: any) {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: { ...(initialValues || {}) },
    resolver: yupResolver(formValidations),
  });

  const [showSecretAlert, setShowSecretAlert] = useState(false);
  const handleFormSubmit = (values: any) => {
    for (const header of values?.content?.http_headers) {
      if (!showSecretAlert && header.is_secret && header.value !== false) {
        setShowSecretAlert(true);
      }
    }
    onSubmit(values);
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <fieldset>
          <legend>General Settings</legend>
          <Grid cols={2}>
            <Input
              id={'id_title'}
              {...register('content.title')}
              required
              error={get(errors, 'content.title.message')}
              label="Title"
            />
            <Input
              id="id_description"
              {...register('content.description')}
              label="Description"
            />
          </Grid>
        </fieldset>
        <fieldset>
          <legend>HTTP Settings</legend>
          <Grid cols={2} gap={16}>
            <Input
              id="id_http_url"
              {...register('content.http_url', { required: true })}
              label="HTTP URL"
              error={get(errors, 'content.http_url.message')}
              required
            />
            <Select
              label="HTTP VERB"
              required
              value={watch('content.http_verb')}
              onChange={(option) =>
                setValue('content.http_verb', option.value, {
                  shouldValidate: true,
                })
              }
              error={get(errors, 'content.http_verb.message')}
              options={[
                { label: 'POST', value: 'POST' },
                { label: 'GET', value: 'GET' },
                { label: 'PATCH', value: 'PATCH' },
                { label: 'PUT', value: 'PUT' },
                { label: 'DELETE', value: 'DELETE' },
              ]}
            />
          </Grid>
          <Grid cols={2}>
            <Input
              id="id_retries"
              required
              error={get(errors, 'content.retries.message')}
              {...register('content.retries', { required: true })}
              label="HTTP Retries"
            />
            <Input
              id="id_retry_delay"
              error={get(errors, 'content.retry_delay.message')}
              required
              {...register('content.retry_delay', { required: true })}
              label="Retry Delay"
            />
          </Grid>

          <FieldGroup>
            <Label required>Request Body</Label>
            <textarea
              {...register('content.request_body', {
                required: watch('content.http_method') !== 'GET',
              })}
              rows={10}
              id="id_request_body"
              style={{
                width: '100%',
                border: get(errors, 'content.request_body.message')
                  ? `2px solid ${color.borders.error}`
                  : '1px solid #5e5e5e',
                borderRadius: 4,
                backgroundColor: '#555',
                color: '#2dfe54',
              }}
            />
            {get(errors, 'content.request_body.message') && (
              <p style={{ fontSize: 12, color: color.status.error }}>
                {get(errors, 'content.request_body.message')}
              </p>
            )}
          </FieldGroup>
        </fieldset>
        <HttpHeaders
          control={control}
          register={register}
          watch={watch}
          setValue={setValue}
          showSecretAlert={showSecretAlert}
          errors={errors}
        />
        <EventEmission
          errors={errors}
          control={control}
          register={register}
          setValue={setValue}
          watch={watch}
        />
        <AvailabilityDependsOn control={control} />
        <VisibilityDependsOn control={control} />
        <CancellationDependsOn control={control} />
        <Button
          type="submit"
          style={{ width: '100%', justifyContent: 'center' }}
        >
          Save API Request
        </Button>
      </form>
    </Container>
  );
}

function HttpHeaders({
  control,
  register,
  watch,
  setValue,
  showSecretAlert,
}: Partial<UseFormReturn> & {
  showSecretAlert: boolean;
  errors: FormState<any>['errors'];
}) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'content.http_headers',
  });
  if (!register || !watch || !setValue) {
    return null;
  }
  return (
    <fieldset>
      <legend style={{ marginTop: '.4em' }}>HTTP Headers</legend>
      <Banner show={showSecretAlert} variant="error">
        <strong>Warning</strong> You have saved a secret and will be unable to
        view it again after refreshing the page.
      </Banner>
      {fields.map((field, id) => {
        const value = watch(`content.http_headers.${id}.value`);
        const isSecret = watch(`content.http_headers.${id}.is_secret`);
        return (
          <div className="http_header_field" key={field.id}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '.5em',
              }}
            ></div>
            <div>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'flex-end',
                }}
              >
                <Grid cols={2}>
                  <Input
                    flow
                    label="Key"
                    {...register(`content.http_headers.${id}.key`, {
                      required: true,
                    })}
                    id={`id_http_headers.${id}.key`}
                  />
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'end',
                      width: '100%',
                    }}
                  >
                    <EditSecretInput
                      register={register}
                      setValue={setValue}
                      id={id}
                      value={value}
                      isSecret={isSecret}
                    />
                    <Button
                      type="button"
                      onClick={() => remove(id)}
                      size="small"
                      variant="light"
                      style={{ alignSelf: 'flex-start' }}
                      title={`Remove Header`}
                    >
                      <CloseCircle />
                    </Button>
                  </div>
                  <div style={{ alignSelf: 'center', display: 'flex' }}>
                    <div
                      style={{ marginRight: 'auto', flex: '1 1 auto' }}
                    ></div>
                  </div>
                </Grid>
              </div>
            </div>
            <div style={{ marginTop: '.5em', marginBottom: '.5em' }} />
            <Toggle
              label="Is Secret"
              checked={watch(`content.http_headers.${id}.is_secret`)}
              onChange={(v) =>
                setValue(`content.http_headers.${id}.is_secret`, v)
              }
            />
          </div>
        );
      })}
      <Button
        variant="secondary"
        type="button"
        style={{ marginTop: '.5em' }}
        onClick={() => append({ value: '', key: '', is_secret: false })}
      >
        Add HTTP Header
      </Button>
    </fieldset>
  );
}

function EditSecretInput({ id, register, isSecret, value }: any) {
  const [isEditing, setIsEditing] = useState<boolean | string>(
    isSecret ? value : true,
  );
  return (
    <div style={{ display: 'flex', width: '100%' }}>
      {isEditing ? (
        <Input
          label="Value"
          flow
          {...register(`content.http_headers.${id}.value`, {
            required: true,
          })}
          placeholder="*********"
          id={`id_http_headers.${id}.key`}
        />
      ) : (
        <p style={{ marginRight: 'auto', alignSelf: 'end' }}>
          Secret set (********)
        </p>
      )}
      {value === false && isSecret && (
        <Button
          type="button"
          variant="secondary"
          style={{ alignSelf: 'flex-end', maxWidth: 65 }}
          onClick={() => setIsEditing(isEditing ? false : true)}
        >
          Edit
        </Button>
      )}
    </div>
  );
}
