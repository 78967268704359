export default {
  label: 'QA',
  description: (
    <p>
      The 'QA' status means the major project version is configured for testing
      and verification. Configuration changes are expected, and Billboard can
      only be previewed using a preview token. The project is not accessible
      without a preview token.
    </p>
  ),
  arrow: (
    <ul>
      <li>Landing page opens for all participants to create enrollments.</li>
      <li>
        All Enrollments will be marked as FAKE, since data should be test data
      </li>
      <ul>
        <li>
          All enrollments will then be transitioned to completed status to avoid
          bumping up numbers in reports
        </li>
      </ul>
      <li>Cap Counts are Reset to zero</li>
      <li>Ordinal events will be generated for Fitbit data</li>
    </ul>
  ),
};
