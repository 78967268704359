import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import DropdownMenu from 'react-dd-menu';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import * as Auth from 'aws-amplify/auth';
import { Link } from 'react-router-dom';
import { logout } from 'src/actions/user_actions';

const StyledAvatar = styled.img.attrs({
  alt: (props) => props.username,
  src: (props) => props.image_url,
  title: (props) => props.username,
})`
  cursor: pointer;
  border-radius: 15px;
  height: 30px;
  margin: 0 1rem;
`;

const UserName = styled.span`
  cursor: pointer;
  display: flex;
  vertical-align: center;

  span {
    font-size: 8px;
    line-height: 26px;
    margin-left: 10px;
  }
`;

class UserAvatar extends PureComponent {
  state = { isMenuOpen: false };

  toggle = () => this.setState({ isMenuOpen: !this.state.isMenuOpen });
  close = () => this._isMounted && this.setState({ isMenuOpen: false });

  UNSAFE_componentWillMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      user: { username, ...user },
      logout,
      history,
      showWorkspaceSettings,
    } = this.props;

    const menuOptions = {
      isOpen: this.state.isMenuOpen,
      close: this.close,
      toggle: (
        <React.Fragment>
          {user.image_url && <StyledAvatar onClick={this.toggle} {...user} />}
          <UserName onClick={this.toggle} data-test="headingUserName">
            {username} <span>▼</span>
          </UserName>
        </React.Fragment>
      ),
      align: 'right',
    };
    const onClick = (event) => {
      event.preventDefault();
      Auth.signOut().then(() => {
        logout();
        history.push('/');
      });
    };

    return (
      <DropdownMenu {...menuOptions}>
        {showWorkspaceSettings && (
          <li>
            <Link to="/workspaces/settings/general">Workspace Settings</Link>
          </li>
        )}
        <li>
          <a onClick={onClick} href="/" id="t-navLink_log_out">
            Log Out
          </a>
        </li>
      </DropdownMenu>
    );
  }
}

/*
showEmail: (boolean) whether the avatar should show the user's email to the right of the image
user: (object) the user to be displayed. has name, email, and image_url fields
 */

UserAvatar.propTypes = {
  user: PropTypes.object.isRequired,
};

export default withRouter(connect(null, { logout })(UserAvatar));
