import { Button, Input } from '@evidation/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { get, useForm } from 'react-hook-form';
import EventEmission from 'src/routes/edit/components/platform-modern/event-emission';
import * as yup from 'yup';

import styled from 'styled-components';
import {
  AvailabilityDependsOn,
  CancellationDependsOn,
  Grid,
  VisibilityDependsOn,
} from 'src/routes/edit/components/platform-modern';

const Container = styled.div`
  & div:empty {
    display: block !important;
  }
  & form {
    margin-bottom: 2rem;
  }
  & fieldset {
    border-radius: 4px;
    background: #f8f8f8;
    padding: 1rem !important;
  }

  legend {
    font-weight: 600;
    color: #555;
  }
`;

const formValidations = yup.object().shape({
  content: yup.object().shape({
    title: yup.string().required('Title is required.'),
    description: yup.string(),
    redirect_url: yup.string().required('Redirect URL'),
  }),
});

export default function RedirectUserForm({ initialValues, onSubmit }: any) {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: { ...(initialValues || {}) },
    resolver: yupResolver(formValidations),
  });

  const handleFormSubmit = (values: any) => {
    onSubmit(values);
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <fieldset>
          <legend>General Settings</legend>
          <Grid cols={2}>
            <Input
              id={'id_title'}
              {...register('content.title')}
              required
              error={get(errors, 'content.title.message')}
              label="Title"
            />
            <Input
              id="id_description"
              {...register('content.description')}
              label="Description"
            />
          </Grid>
        </fieldset>
        <fieldset>
          <legend>HTTP Settings</legend>
          <Grid cols={2} gap={16}>
            <Input
              id="id_redirect_url"
              {...register('content.redirect_url', { required: true })}
              label="Redirect URL"
              error={get(errors, 'content.redirect_url.message')}
              required
            />
          </Grid>
        </fieldset>
        <EventEmission
          errors={errors}
          control={control}
          register={register}
          setValue={setValue}
          watch={watch}
        />
        <AvailabilityDependsOn control={control} />
        <VisibilityDependsOn control={control} />
        <CancellationDependsOn control={control} />
        <Button
          type="submit"
          style={{ width: '100%', justifyContent: 'center' }}
        >
          Save Redirect User
        </Button>
      </form>
    </Container>
  );
}
