import * as C from '../common';

import { default_experience_key } from '../../../../constants';
import { getInputsForExperience } from '../utils';

export default ({
  arms,
  initialValues,
  nodeSlugs,
  experience = default_experience_key,
  displayGroupNames,
}) => {
  return [
    [
      {
        label: 'Content',
        name: `content.experiences.${experience}`,
        input: [
          C.title,
          C.description,
          C.completion_message,
          C.action_title,
          C.tooltip,
          {
            name: `go_back_enabled`,
            interface: `checkboxinput`,
            label: `Enable back button`,
          },
        ],
      },
      {
        label: 'Platform Settings',
        name: 'content',
        input: [C.assessment_platform, C.platform_error_message],
      },
      {
        label: 'Settings',
        name: 'content',
        input: getInputsForExperience({
          experience,
          mandatoryInputs: [
            C.optional,
            C.display_group_name(displayGroupNames),
            C.experience_attribute,
          ],
          optionalInputs: [
            ...C.dependencyCriteria({
              emitEvent: true,
              availability: true,
              visibility: true,
              cancellation: true,
            }),
            {
              name: 'layout',
              label: 'Layout',
              interface: 'select',
              options: [
                {
                  label: 'Pre-Dashboard',
                  value: 'survey',
                },
                {
                  label: 'Dashboard',
                  value: 'dashboard',
                },
              ],
              omitPlaceholder: true,
              defaultValue: 'dashboard',
            },
          ],
        }),
      },
    ],
  ];
};
