import { Sequence } from '../../../redux/graph';
import { SequenceFormValues } from './Form';

const defaultValues: Sequence = {
  content: {
    title: '',
    branching: {
      arm_names: [],
      strategy: '',
      condition: '',
      arms_by_condition_value: {},
      default_arm: '',
    },
    settings: {
      option: '',
    },
    sequential_surveys: false,
    unified_progress_bar: false,
  },
  tile_ids: [],
  branch_arms: [],
  id: '',
};

export function transformIncoming(
  sequence: Sequence = defaultValues,
): SequenceFormValues {
  const {
    content: {
      title = '',
      branching = { ...defaultValues.branching },
      archipelago_identifier = undefined,
      sequential_surveys = { ...defaultValues.sequencial_surveys },
      unified_progress_bar = { ...defaultValues.unified_progress_bar },
    },
    branch_arms,
  } = sequence;

  const hasSequentialSurveys =
    typeof sequential_surveys === 'object' &&
    sequential_surveys !== null &&
    Object.keys(sequential_surveys).length === 0
      ? false
      : sequential_surveys;
  const hasUnifiedProgressBar =
    typeof unified_progress_bar === 'object' &&
    unified_progress_bar !== null &&
    Object.keys(unified_progress_bar).length === 0
      ? false
      : unified_progress_bar;
  const hasBranching = branching?.arm_names?.length > 0;
  const strategy = branching?.strategy;
  const on_cap_overflow = branching?.on_cap_overflow;
  const condition = branching?.condition;
  const default_arm = branching?.default_arm;
  let branches: any[] = [];
  if (branching?.arms_by_condition_value) {
    branches = Object.keys(branching?.arms_by_condition_value).map(
      (key: string) => ({
        name: branching?.arms_by_condition_value?.[key],
        condition: key,
        cap: branching?.caps_by_arm?.[
          branching?.arms_by_condition_value?.[key]
        ],
      }),
    );
  } else if (branching?.arm_names?.length > 0) {
    branches = branching.arm_names.map((name: string) => ({
      name,
      condition: '',
      cap: branching?.caps_by_arm?.[name],
    }));
  }
  return {
    title,
    branches,
    branching: hasBranching,
    strategy,
    condition,
    default_arm,
    // there should only ever be 1 branch per sequence.
    add_to_existing_branch: branch_arms?.[0] ? true : false,
    branch_arms: branch_arms?.[0],
    on_cap_overflow,
    archipelago_identifier,
    sequential_surveys: hasSequentialSurveys,
    unified_progress_bar: hasUnifiedProgressBar,
  };
}

export function transformOutgoing(
  formValues: SequenceFormValues,
  extras: Record<string, any> = {},
): Partial<Sequence> {
  const {
    title,
    branches,
    strategy,
    condition,
    default_arm,
    on_cap_overflow,
    archipelago_identifier = undefined,
    add_to_existing_branch,
    branching,
    sequential_surveys,
    unified_progress_bar,
  } = formValues;

  const arm_names = branches?.map((b: any) => b.name);
  /**
   * Its important to the backend that this value is null
   * if theres no caps we should make sure in the reduce.
   */
  const caps_by_arm = branches
    ?.filter((b: any) => b.cap)
    .reduce((acc: any, curr: any) => {
      if (!acc) acc = {};
      acc[curr.name] = curr?.cap;
      return acc;
    }, null);

  const arms_by_condition_value =
    strategy === 'conditional'
      ? branches?.reduce((acc: any, curr: any) => {
          acc[curr.condition] = curr.name;
          return acc;
        }, {})
      : undefined;

  const payload = {
    type: 'sequence',
    branch_arms:
      formValues?.branch_arms && add_to_existing_branch
        ? [formValues.branch_arms]
        : [],
    title,
    content: {
      archipelago_identifier,
      ...extras,
      sequential_surveys,
      unified_progress_bar,
      branching:
        branching && strategy
          ? {
              strategy,
              arm_names,
              caps_by_arm,
              condition,
              arms_by_condition_value,
              default_arm,
              on_cap_overflow,
            }
          : undefined,
      title,
      type: 'sequence',
    },
  };
  return payload;
}
