import { Application } from '../../../components/StudyLayout';
import React, { useEffect, useState } from 'react';
import format from 'date-fns/format';
import { TextareaAutosize } from '@evidation/form-elements';
import TileWrapper from './tile-wrapper';
import _ from 'lodash';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { apiGraph } from '../../../api';
import { logError } from 'src/utils/errorLogger';
import { toastr } from 'react-redux-toastr';
import LoadingIndicator from '../../../components/LoadingIndicator';
import ErrorPage from '../../../components/ErrorPage';

const text = {
  exportJSON: 'Export JSON',
  loading: 'Loading',
  problem: (id) => `There was a problem exporting graph ${id}`,
};

const ExportContainer = styled.div`
  grid-column-start: content;
  grid-column-end: settings;
`;

const ContentCentered = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Export = ({ graph, ...props }) => {
  const { id } = graph;

  const [pageState, setPageState] = useState({
    loadingState: 'loading',
  });

  useEffect(() => {
    apiGraph
      .export(id)
      .then((data) => {
        const {
          content: { slug },
        } = data;
        const date = format(new Date(), 'ddMMyyyy_HHmmss');
        setPageState({
          value: data,
          buttonProps: {
            children: 'Download',
            download: `${slug || 'study'}_${date}.json`,
            href: `data:text/json;charset=utf-8,${encodeURIComponent(
              JSON.stringify(data),
            )}`,
          },
          loadingState: 'loaded',
        });
      })
      .catch((error) => {
        setPageState({ loadingState: 'error', error: error });
        logError(error);
        toastr.error(text.problem(id));
      });
  }, [id]);

  return (
    <Application.Content>
      <ExportContainer>
        {pageState.loadingState === 'loading' ? (
          <ContentCentered>
            <LoadingIndicator />
          </ContentCentered>
        ) : pageState.loadingState === 'error' ? (
          <ErrorPage title={text.problem(id)} error={pageState.error} />
        ) : (
          <TileWrapper
            {...props}
            buttonProps={pageState.buttonProps}
            heading={text.exportJSON}
          >
            <TextareaAutosize
              inputStyle="achievement"
              onChange={_.noop}
              value={JSON.stringify(pageState.value)}
            />
          </TileWrapper>
        )}
      </ExportContainer>
    </Application.Content>
  );
};

export default connect(
  ({ graph }) => ({
    graph,
  }),
  {},
)(Export);
