import React from 'react';

export default (props) => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      role="graphics-symbol"
    >
      <title>icons/tile-types/2fa</title>
      <desc>Created with Sketch.</desc>
      <defs />
      <g
        id="icons/tile-types/2fa"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <rect
          id="Container"
          fillOpacity="0"
          fill="#FFFFFF"
          x="0"
          y="0"
          width="24"
          height="24"
        />
        <path
          d="M12.1930182,15.6305994 L12.1930182,16.6438091 C12.1930182,16.8195361 12.1217908,16.978627 12.0066316,17.0937861 C11.8914724,17.2089453 11.7323815,17.2801727 11.5566545,17.2801727 L9.63636364,17.2801727 L9.63636364,19.1892636 C9.63636364,19.3649906 9.56513621,19.5240815 9.44997704,19.6392407 C9.33481787,19.7543998 9.17572697,19.8256273 9,19.8256273 L7.09090909,19.8256273 L7.09090909,21.7347182 C7.09090909,21.9104451 7.01968166,22.0695361 6.9045225,22.1846952 C6.78936333,22.2998544 6.63027242,22.3710818 6.45454545,22.3710818 L2.63636364,22.3710818 C2.2849097,22.3710818 2,22.0861721 2,21.7347182 L2,19.1892636 C2,19.0135543 2.07121312,18.8544777 2.18635191,18.7393213 L10.1284714,10.7959772 C9.81263383,10.0328423 9.64756364,9.20991831 9.64756364,8.36363636 C9.64756364,4.97786384 12.2912878,2.20942636 15.6280664,2.01133993 C15.7510793,2.00381673 15.8750939,2 16,2 C16.0037326,2.00000322 16.0074659,2 16.0112,2 C19.5255999,2 22.3748364,4.84967381 22.3748364,8.36363636 C22.3748364,11.8784539 19.5260176,14.7272727 16.0112,14.7272727 C15.1641202,14.7272727 14.3408017,14.5618405 13.5776715,14.2454789 L12.1930182,15.6305994 Z M18.5510545,7.09090909 C18.5510545,6.39272196 17.9846487,5.82378182 17.2839273,5.82378182 C16.5832059,5.82378182 16.0168,6.39272196 16.0168,7.09090909 C16.0168,7.79163047 16.5832059,8.35803636 17.2839273,8.35803636 C17.9846487,8.35803636 18.5510545,7.79163047 18.5510545,7.09090909 Z"
          id="Combined-Shape"
          fill="#000000"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};
