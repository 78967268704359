import { Button, Input } from '@evidation/ui';
import { CloseCircle } from '@evidation/ui/lib/icons';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import styled from 'styled-components';

export const Grid = styled.div<{
  cols?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  gap?: string | number;
}>`
  display: grid;
  grid-template-columns: ${(props) =>
    `repeat(${props?.cols || 1}, minmax(0, 1fr))`};
  grid-gap: ${(props) => props?.gap || 16}px;
  width: 100%;
`;

export const CancellationDependsOn = ({
  control,
}: {
  control: UseFormReturn['control'];
}) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: 'content.cancellation__depends_on',
  });
  const name = `content.cancellation__depends_on`;
  return (
    <fieldset>
      <legend>Cancellation Depends On</legend>
      {fields.map((field, id) => (
        <div key={field.id} style={{ display: 'flex', alignItems: 'center' }}>
          <Input
            {...control.register(`${name}[${id}]`)}
            label=""
            flow
            id={`id_${name}_${id}`}
          />
          <Button
            type="button"
            variant="light"
            onClick={() => remove(id)}
            size="small"
            style={{ marginLeft: '.5rem' }}
          >
            <CloseCircle />
          </Button>
        </div>
      ))}
      <Button
        type="button"
        onClick={() => append('')}
        style={{ marginTop: '.5rem' }}
        variant="secondary"
      >
        Add Cancellation Depends On
      </Button>
    </fieldset>
  );
};

export const AvailabilityDependsOn = ({
  control,
}: {
  control: UseFormReturn['control'];
}) => {
  const name = 'content.availability__depends_on';
  const { fields, remove, append } = useFieldArray({
    control,
    name,
  });
  return (
    <fieldset>
      <legend>Availability Depends On</legend>
      {fields.map((field, id) => (
        <div key={field.id} style={{ display: 'flex', alignItems: 'center' }}>
          <Input
            flow
            {...control.register(`${name}[${id}]`)}
            label=""
            id={`id_${name}_${id}`}
          />
          <Button
            type="button"
            variant="light"
            onClick={() => remove(id)}
            size="small"
            style={{ marginLeft: '.5rem' }}
          >
            <CloseCircle />
          </Button>
        </div>
      ))}
      <Button
        type="button"
        onClick={() => append('')}
        style={{ marginTop: '.5rem' }}
        variant="secondary"
      >
        Add Availibility Depends On
      </Button>
    </fieldset>
  );
};

export const VisibilityDependsOn = ({
  control,
}: {
  control: UseFormReturn['control'];
}) => {
  const name = 'content.visibility__depends_on';
  const { fields, remove, append } = useFieldArray({
    control,
    name,
  });
  return (
    <fieldset>
      <legend>Visibility Depends On</legend>
      {fields.map((field, id) => (
        <div key={field.id} style={{ display: 'flex', alignItems: 'center' }}>
          <Input
            flow
            {...control.register(`${name}.${id}.visibility_key`)}
            label=""
            id={`id_${name}_${id}`}
          />
          <Button
            type="button"
            variant="light"
            onClick={() => remove(id)}
            size="small"
            style={{ marginLeft: '.5rem' }}
          >
            <CloseCircle />
          </Button>
        </div>
      ))}
      <Button
        type="button"
        onClick={() => append('')}
        style={{ marginTop: '.5rem' }}
        variant="secondary"
      >
        Add Visibility Depends On
      </Button>
    </fieldset>
  );
};
