import types from '../constants';
import _ from 'lodash';
import { Graph, Reminders, Sequences, Tile, Tiles } from '../redux/graph';
import { AnyAction, Dispatch } from 'redux';

const formatElement = (id: string, tokens: Array<string>) => ({
  value: id,
  label: tokens.filter((token) => token !== '').join(' - '),
});

const formatTile = (tile: Tile, branch_mapping: any) => {
  const select_elements: Array<{ value: string; label: string }> = [];
  const branch = branch_mapping[tile.id];
  if (!_.isUndefined(tile.content.experiences)) {
    Object.keys(tile.content.experiences).forEach((experience_name) => {
      const experience = tile.content.experiences?.[experience_name];
      const title =
        experience_name === 'default'
          ? experience?.title
          : `${experience?.title} (${experience_name})`;
      select_elements.push(
        formatElement(tile.id, [tile.content.type, title, branch, tile.id]),
      );
    });
  } else {
    select_elements.push(
      formatElement(tile.id, [
        tile.content.type,
        tile.content.title,
        branch,
        tile.id,
      ]),
    );
  }
  return select_elements;
};

// TODO: potentially put this in a reducer. Need to ensure that it changes correctly when tiles change
const mapBranches = ({
  tiles,
  sequences,
}: {
  tiles: Tiles;
  sequences: any;
}) => {
  const mapping: { [key: string]: any } = {};

  Object.keys(sequences).forEach((sequence_id) => {
    const sequence = sequences[sequence_id];
    const branch = sequence.branch_arms.join('::');

    sequence.tile_ids.forEach((tile_id: string) => {
      const tile = tiles[tile_id];

      mapping[tile_id] = branch;

      if (tile.reminder_ids.length > 0) {
        tile.reminder_ids.forEach((reminder_id) => {
          mapping[reminder_id] = branch;
        });
      }
    });
  });

  return mapping;
};

const formatEventSelectors = (
  graph: Graph,
  sequences: Sequences,
  tiles: Tiles,
  reminders: Reminders,
) => {
  const branched_event_selectors: { [key: string]: any } = {};
  const branch_mapping = mapBranches({ tiles, sequences });

  graph.sequence_ids.forEach((sequence_id: string) => {
    (sequences[sequence_id].tile_ids as string[]).forEach((tile_id) => {
      const branch_name = branch_mapping[tile_id];
      if (_.isUndefined(branched_event_selectors[branch_name]))
        branched_event_selectors[branch_name] = [];
      const tile = tiles[tile_id];

      branched_event_selectors[branch_name].push(
        formatTile(tile, branch_mapping),
      );

      if (tile.reminder_ids.length > 0) {
        tile.reminder_ids.forEach((reminder_id) => {
          const reminder = reminders[reminder_id];
          branched_event_selectors[branch_name].push(
            formatTile(reminder, branch_mapping),
          );
        });
      }
    });
  });

  const event_selectors: Array<any> = [];

  Object.keys(branched_event_selectors).forEach((branch_name) => {
    branched_event_selectors[branch_name].forEach((branch: any) => {
      event_selectors.push(branch);
    });
  });

  return _.flatten(event_selectors);
};

export const setEventSelectors =
  (graph: Graph, sequences: Sequences, tiles: Tiles, reminders: Reminders) =>
  (dispatch: Dispatch<AnyAction>) => {
    const event_selectors = formatEventSelectors(
      graph,
      sequences,
      tiles,
      reminders,
    );
    dispatch({ type: types.event_selectors.set, payload: event_selectors });
  };

export const clearEventSelectors = () => ({
  type: types.event_selectors.clear,
});
