import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Button from '../../../components/inputs/Button';
import Modal, { ContentContainer } from '../../../components/modal/SimpleModal';
import { StyledForm } from '../../../components/Sequences/AddEdit/components';
import { Banner, Flex, Input, Toggle } from '@evidation/ui';

const text = {
  title: 'Export Audit Trail',
  btnText: 'Export',
  dateMessageError: 'To date should be greater than From date.',
};

type Option = {
  value: string;
  label: string;
};

const auditTrailOptions: Option[] = [
  { value: 'enrollments', label: 'Participant Events' },
  { value: 'publishes', label: 'Publishes' },
  { value: 'study_changes', label: 'Study Changes' },
];

export type OptionsFormValues = {
  enrollments: boolean;
  publishes: boolean;
  study_changes: boolean;
  from_date: string;
  to_date: string;
  [key: string]: any;
};

export type ExportAuditTrailModalProps = {
  defaultValues: any;
  onClose: () => void;
  url: string;
};

const ExportAuditTrailModal: React.FC<ExportAuditTrailModalProps> = ({
  defaultValues,
  onClose = () => {},
  url,
}) => {
  const [isDateRangeInvalid, setIsDateRangeInvalid] = useState(false);
  const history = useHistory();

  // Set initial values
  const { register, handleSubmit, watch, setValue, formState } =
    useForm<OptionsFormValues>({
      defaultValues: {
        ...defaultValues,
        enrollments: false,
        publishes: true,
        study_changes: true,
        to_date: '',
        from_date: '',
      },
    });

  const getError = (key: keyof OptionsFormValues) =>
    formState?.errors?.[key] && formState?.errors?.[key]?.message?.toString();

  const onSubmit = (data: OptionsFormValues) => {
    const { from_date, to_date } = data;

    //Don't test the range unless both fields are set
    if (from_date && to_date) {
      const fromDate = new Date(from_date);
      const toDate = new Date(to_date);

      if (fromDate > toDate) {
        setIsDateRangeInvalid(true);
        return;
      }
    }
    history.push(`${url}/export_audit_trail`, { exportOptions: data });
    onClose();
    setIsDateRangeInvalid(false);
  };

  return (
    <Modal onClose={onClose} center showX modalTitle={text.title}>
      <ContentContainer>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <Flex direction="column" spaceY={8}>
            <Banner show={isDateRangeInvalid} variant="error">
              {text.dateMessageError}
            </Banner>
            {auditTrailOptions.map((option) => (
              <Toggle
                key={option.value}
                onChange={(v) => setValue(option.value, v)}
                checked={watch(option.value)}
                label={option.label}
              />
            ))}
            <Flex spaceX={8}>
              <Input
                {...register('from_date')}
                label="From"
                id="fromDate"
                error={getError('from_date')}
                type="date"
              />
              <Input
                {...register('to_date')}
                label="To"
                id="toDate"
                error={getError('to_date')}
                type="date"
              />
            </Flex>
          </Flex>
          <Flex justify="flex-end" spaceY={8}>
            <Button type="primary">{text.btnText}</Button>
          </Flex>
        </StyledForm>
      </ContentContainer>
    </Modal>
  );
};

export default ExportAuditTrailModal;
