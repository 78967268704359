import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import onDeleteHOC from '../hoc/onDeleteHOC';
import _ from 'lodash';
import DropdownMenu from 'react-dd-menu';
import { Islands } from '.';
import { AuthWrap } from '../../../components/auth';
import { AuthActions } from '../../../constants';

class ArchipelagoDropdown extends PureComponent {
  state = { isMenuOpen: false };

  toggle = () => this.setState({ isMenuOpen: !this.state.isMenuOpen });
  close = () => this.setState({ isMenuOpen: false });

  render() {
    const {
      archipelago_name: archipelago_identifier,
      match,
      archipelago,
      onDeleteMulti,
    } = this.props;

    const archipelagoIslands = _.keys(archipelago).reduce(
      (acc, sequence_uuid) => [...acc, { sequence_uuid, resource: 'sequence' }],
      [],
    );

    const menuOptions = {
      isOpen: this.state.isMenuOpen,
      close: this.close,
      toggle: (
        <Islands.Heading headingColor="#84898F" fontWeight={400}>
          {archipelago_identifier}
          <button
            onClick={this.toggle}
            style={{ background: 'transparent', border: 'none' }}
            id={`t-alternative_route_${_.snakeCase(archipelago_identifier)}`}
          >
            •••
          </button>
        </Islands.Heading>
      ),
      align: 'left',
    };

    return (
      <AuthWrap requiredActions={[AuthActions.graphs.publish]}>
        <DropdownMenu {...menuOptions}>
          <AuthWrap requiredActions={[AuthActions.sequences.destroy]}>
            <li>
              <button
                id="t-alternative_route_delete"
                onClick={() => {
                  this.close();
                  onDeleteMulti(archipelagoIslands);
                }}
                style={{ cursor: 'pointer', fontWeight: 'inherit' }}
              >
                Delete Alternative Route
              </button>
            </li>
          </AuthWrap>
          <AuthWrap requiredActions={[AuthActions.sequences.update]}>
            <li>
              <Link
                id="t-alternative_route_add"
                to={{
                  pathname: `/study/${match.params.graph_id}/edit/slate/add-new`,
                  state: {
                    resource: `sequence`,
                    content: { archipelago_identifier },
                  },
                }}
              >
                Add Sequence to Alternative Route
              </Link>
            </li>
          </AuthWrap>
        </DropdownMenu>
      </AuthWrap>
    );
  }
}

export default onDeleteHOC(ArchipelagoDropdown);
