import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { TagList } from '.';
import GetArms from '../../../selectors/arms';
import { armsFilter, typeFilter } from '../../../actions/slate_actions';
import { Button } from '@evidation/ui';

const filterIsActive = (filters = [], tag = undefined) => {
  if (filters.length === 0 && _.isUndefined(tag)) {
    return true;
  }
  return _.intersection(filters, [tag]).length > 0;
};

const BranchTagsList = (props) => {
  const { arms, available_filters, armsFilter, typeFilter } = props;
  return (
    <TagList>
      {arms.length > 1 && (
        <li className={`${filterIsActive(available_filters) ? `active` : ``}`}>
          <Button
            onClick={() => {
              armsFilter(false);
              typeFilter(false);
            }}
            variant="ghost"
          >
            All
          </Button>
        </li>
      )}
      {arms.length > 1 &&
        arms.map(({ name: arm }) => (
          <li
            className={`${
              filterIsActive(available_filters, arm) ? `active` : ``
            }`}
            key={arm}
          >
            <Button variant="ghost" onClick={() => armsFilter(arm)}>
              {arm}
            </Button>
          </li>
        ))}
    </TagList>
  );
};

export default connect(
  (state) => ({
    arms: GetArms(state.sequences),
    available_filters: [...state.slate.filter_arms],
  }),
  { armsFilter, typeFilter },
)(BranchTagsList);
