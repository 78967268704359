import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { toastr } from 'react-redux-toastr';
import Modal, { ContentContainer } from '../../../modal/SimpleModal';
import CuriousPanda from '../../../fields/CuriousPanda';
import Button from '../../../inputs/Button';
import { userUpdatePin } from '../../../../api';
import { logError } from '../../../../utils/errorLogger';

export const validations = [
  (value) =>
    value && /^[0-9]*$/.test(value) ? null : `PIN should only contain numbers.`,
  (value) => (value && value.length < 6 ? `PIN must be 6 numbers.` : null),
];

export const craSchema = [
  {
    className: `setCraPin`,
    input: [
      {
        name: `cra_pin`,
        label: `PIN`,
        placeholder: `ex.: 123456`,
        maxLength: 6,
        interface: `textinput`,
        validate: validations,
      },
      {
        name: `cra_pin_confirm`,
        label: `Re-Type PIN`,
        placeholder: `ex.: 123456`,
        maxLength: 6,
        interface: `textinput`,
        validate: validations,
      },
      {
        name: `cra_pin_instruction`,
        label: `Your PIN should not be shared with anyone. Please treat it like you would any password.`,
        interface: `paragraph`,
      },
    ],
  },
];

export const MatchWarning = styled.p`
  padding: 15px 15px 0;
  color: #b21f36;
  font-size: 14px;
`;

export const setPin = (pin, graphId, close) => {
  const payload = { pin, graph_id: graphId };
  userUpdatePin(payload)
    .then(() => {
      toastr.success('Your PIN is set.');
      close();
    })
    .catch((error) => {
      if (error.response.status === 422) {
        toastr.confirm(
          `The PIN you entered is too common and easy to guess. Please choose a more secure PIN`,
          { disableCancel: true },
        );
      } else {
        logError(error);
        toastr.error('There was a problem setting your pin.');
        console.error(error);
      }
    });
};

const CrcModal = ({ onClose, graphId }) => {
  const [pinWarning, setPinWarning] = useState(undefined);

  return (
    <Modal
      onClose={onClose}
      modalTitle="Set your 6-digit Personal Identification Number (PIN)"
    >
      {pinWarning && <MatchWarning>{pinWarning}</MatchWarning>}
      <ContentContainer>
        <CuriousPanda
          onSubmit={({ cra_pin, cra_pin_confirm }) => {
            if (cra_pin === cra_pin_confirm) {
              setPinWarning(undefined);
              setPin(cra_pin, graphId, onClose);
            } else
              setPinWarning('Please make sure your PIN and Re-Type PIN match.');
          }}
          form={`cra_set_pin`}
          schema={craSchema}
        >
          {(props) => (
            <Button disabled={!props.valid} type="primary">
              Save
            </Button>
          )}
        </CuriousPanda>
      </ContentContainer>
    </Modal>
  );
};

CrcModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  roleId: PropTypes.number.isRequired,
  graphId: PropTypes.string.isRequired,
};

export default CrcModal;
