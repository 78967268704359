import * as C from '../common';

import { default_experience_key } from '../../../../constants';
import { getInputsForExperience } from '../utils';

/**
 * {@deprecated}See Custom Form defined in api-request-form
 * */
export const ApiRequestTileSettings = ({ experience, masonryEnabled }) => ({
  label: 'Settings',
  name: 'content',
  input: getInputsForExperience({
    experience,
    mandatoryInputs: [
      C.experienceTileTitle(masonryEnabled),
      C.experienceTileDescription(masonryEnabled),
      C.optional,
    ],
    optionalInputs: [
      ...C.dependencyCriteria({
        emitEvent: true,
        visibility: true,
        cancellation: true,
      }),
    ],
  }),
});
/**
 * {@deprecated}See Custom Form defined in api-request-form
 */
export default ({ experience = default_experience_key, dataForTile }) => {
  const {
    api_request: { masonryEnabled = false },
  } = dataForTile;

  const schema = [
    {
      label: 'Content',
      name: masonryEnabled
        ? `content.experiences.${default_experience_key}.settings`
        : 'content',
      input: [
        C.experienceExternalTitle(masonryEnabled),
        C.experienceExternalDescription(masonryEnabled),
        {
          name: 'http_url',
          label: 'HTTP URL',
          interface: 'textinput',
          accepts_data: true,
          value: 'https://aoeu.aoeu',
          //required: true,
        },
        {
          name: 'http_verb',
          interface: 'select',
          label: 'HTTP Verb',
          options: [
            { label: 'POST', value: 'POST' },
            { label: 'GET', value: 'GET' },
            { label: 'PATCH', value: 'PATCH' },
            { label: 'PUT', value: 'PUT' },
            { label: 'DELETE', value: 'DELETE' },
          ],
          omitPlaceholder: true,
          required: true,
        },
        {
          name: 'http_headers',
          interface: `array`,
          title: 'HTTP Headers',
          items: [
            {
              name: 'key',
              label: 'Key',
              interface: 'textinput',
              required: true,
              inputStyle: `sm_minimal`,
            },
            {
              name: 'value',
              label: 'value',
              interface: `textinput`,
              placeholder: '*********',
              inputStyle: `sm_minimal`,
            },
            {
              name: 'is_secret',
              label: 'Secret',
              interface: 'checkbox',
            },
          ],
        },
        {
          name: `request_body`,
          label: `Request Body`,
          interface: `textarea`,
          required: true,
        },
        {
          name: `async`,
          label: `Async Request`,
          interface: `boolean`,
          required: true,
        },
        {
          name: `retries`,
          label: `Retries`,
          interface: `textinput`,
          required: true,
        },
        {
          name: `retry_delay`,
          label: `Retry delay`,
          interface: `textinput`,
          required: true,
        },
      ],
    },
  ];

  if (!masonryEnabled) {
    schema.push(
      ApiRequestTileSettings({
        experience,
        masonryEnabled,
      }),
    );
  }
  return schema;
};
