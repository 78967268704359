import { useCallback, useEffect, useMemo, useState } from 'react';
import { graphQueries, apiGraph } from '../../../../api';
import { toastr } from 'react-redux-toastr';
import hasAuthAction from '../../../../utils/hasAuthAction';
import { logError } from '../../../../utils/errorLogger';
import { useQuery } from 'react-query';
import { AuthActions, queryStores } from '../../../../constants';

const REVERT_VERSION_SUCCESS_MSG = 'Successfully reverted to Version:';
const REVERT_VERSION_FAILURE_MSG = 'Error reverting to this publish.';
const REVERT_VERSION_NOT_FOUND_MSG =
  'Something went wrong reverting to this study. No versions or graph id not found.';
const LOAD_STUDY_FAILURE_MSG = 'Failed to load versions.';

/**
 * @description Manages all aspects of the version history sidebar,
 *  revert functionality, and preview functionality.
 * @param {object} props
 * @returns
 */
export default function useVersionHistory({
  location,
  graph_id,
  authorization,
  clearPublishErrors,
  load,
  graph,
  loadGraphPreview,
}) {
  const [loading, setLoading] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [preview, setPreview] = useState(false);
  const [versionHistoryModal, setVersionHistoryModal] = useState(false);
  const [revertModal, setRevertModal] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState(null);

  const { id } = graph;
  const { pathname } = location;

  const validateRoute = (pathname) => {
    return ['/edit/slate', '/version'].find((a) => pathname.includes(a))
      ? true
      : false;
  };

  const canFetchVersions = useMemo(() => {
    return (
      hasAuthAction({
        authorization,
        graphId: graph_id,
        actionName: AuthActions.publishes.index,
      }) && validateRoute(pathname)
    );
  }, [authorization, graph_id, pathname]);

  const { data: versions, error } = useQuery(
    [queryStores.studyVersions, String(graph_id)],
    () => graphQueries.getPublishes(graph_id),
    {
      enabled: graph_id && canFetchVersions,
      refetchOnWindowFocus: false
    },
  );

  useEffect(() => {
    if (error) {
      toastr.error(LOAD_STUDY_FAILURE_MSG);
    }
  }, [error]);

  const toggleVersionHistoryModal = () =>
    setVersionHistoryModal(!versionHistoryModal);

  const handleOnClickOutside = () => {
    if (!preview && !revertModal && !location.pathname.includes('/version')) {
      setVersionHistoryModal(false);
    }
  };

  const canUserRevertPublishes = useMemo(() => {
    if (
      hasAuthAction({
        authorization,
        graphId: graph_id,
        actionName: 'publishes/update',
      })
    ) {
      return true;
    }
    return false;
  }, [authorization, graph_id]);

  const enableRevertAndSetSelected = (selectedVersion) => {
    setRevertModal(true);
    setSelectedVersion(selectedVersion);
  };

  const toggleRevertModal = () => {
    if (revertModal) {
      setSelectedVersion(null);
    }
    setRevertModal(!revertModal);
  };
  /**
   *  revertStudy reverts the study to a given version
   * @param {number} publish_id
   */
  const revertStudy = useCallback(
    async (publish_id) => {
      if (!id || !versions) {
        toastr.error(REVERT_VERSION_NOT_FOUND_MSG);
        return;
      }
      const version = versions?.find((version) => version.id === publish_id);
      if (!version) {
        toastr.error(REVERT_VERSION_NOT_FOUND_MSG);
        return;
      }
      try {
        setLoading(true);
        await apiGraph.restoreGraph(id, publish_id);
        await load(id);
        clearPublishErrors();
        setRevertModal(false);
        setSelectedVersion(null);
        setPreview(false);
        toastr.success(`${REVERT_VERSION_SUCCESS_MSG} ${version.version_id}`);
      } catch (err) {
        logError(err);
        toastr.error(REVERT_VERSION_FAILURE_MSG);
      } finally {
        setLoading(false);
      }
    },
    [
      setLoading,
      load,
      clearPublishErrors,
      setRevertModal,
      setSelectedVersion,
      id,
      versions,
    ],
  );

  const loadPreview = useCallback(
    async (publish_id) => {
      if (!preview) {
        setPreview(true);
      }
      if (loading) {
        return;
      }
      try {
        setPreviewLoading(true);
        const { data } = await apiGraph.getPublishes(id, publish_id);
        await loadGraphPreview(data.graph);
      } catch (err) {
        logError(err);
        toastr.error(`Something went wrong previewing this version.`);
      } finally {
        setPreviewLoading(false);
      }
    },
    [loading, id, loadGraphPreview, setPreviewLoading, preview],
  );

  const clearPreview = useCallback(
    async ({ closeModal = false } = {}) => {
      if (preview) {
        setLoading(true);
        await load(graph_id);
        setLoading(false);
        setPreview(false);
        setPreviewLoading(false);
        setSelectedVersion(null);
        if (closeModal) {
          setVersionHistoryModal(false);
        }
      }
    },
    [setLoading, load, setPreview, graph_id, preview, setPreviewLoading],
  );
  return {
    versions,
    versionHistoryModal,
    handleOnClickOutside,
    toggleVersionHistoryModal,
    canUserRevertPublishes,
    revertStudy,
    toggleRevertModal,
    enableRevertAndSetSelected,
    loading,
    revertModal,
    preview,
    previewLoading,
    clearPreview,
    loadPreview,
    selectedVersion,
    setPreview,
    setPreviewLoading,
    setVersionHistoryModal,
  };
}
