import 'react-router-modal/css/react-router-modal.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'react-dd-menu/dist/react-dd-menu.css';
import './App.css';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '@evidation/logger';
import { createRoot } from 'react-dom/client';

import AppContainer from './components/AppContainer';
import AuthEntry from './components/AuthEntry';
import { Provider } from 'react-redux';
import React from 'react';
import store from './reducers';
import ErrorBoundary from './components/ErrorBoundary';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import ReduxToastr from 'react-redux-toastr';
// go ahead and import the logger to ensure initialization happens early.
import './utils/errorLogger';

const queryClientDefaultConfiguration = {
  queries: {
    refetchOnMount: false,
  },
};

const queryClient = new QueryClient({
  defaultOptions: {
    ...queryClientDefaultConfiguration,
  },
});

const app = document.getElementById('root');
const root = createRoot(app as HTMLElement);

root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        {window.env.NODE_ENV === 'development' && (
          <ReactQueryDevtools initialIsOpen={false} />
        )}
        <AuthEntry>
          <AppContainer />
        </AuthEntry>
        <ReduxToastr newestOnTop timeOut={5000} position="bottom-center" />
      </QueryClientProvider>
    </Provider>
  </ErrorBoundary>,
);
