import React, { useState } from 'react';
import styled from 'styled-components';
import { toastr } from 'react-redux-toastr';
import * as Auth from 'aws-amplify/auth';
import { useQuery } from 'react-query';
import LoadingIndicator from './LoadingIndicator';
import EvidationLogo from '../assets/EvidationLogo';
import { getBrandMeta } from 'src/api/meta';

const LogoContainer = styled.div`
  text-align: center;
`;

const LoginContainer = styled.div`
  border-radius: 10px;
  padding: 60px 32px 60px 32px;
  background: white;
  box-shadow: 0px 1px 4px 0px #0000001f;
  width: 450px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const PageContainer = styled.div`
  height: 100vh;
  background: #f9fafb;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Message = styled.p`
  color: black;
  font-size: 1.5rem;
  font-weight: 500;
`;

const Buttons = styled.div`
  button {
    display: block;
    width: 100%;
    font-size: 1rem;
    padding: 0.75em 1em;
    position: relative;
    border: 0;
    border-radius: 10px;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    background: #162d6d;
    box-shadow: none;
    transition: background 0.1s;
    &:hover {
      background: #3956a7;
    }
    &:focus {
      background: #3956a7;
      outline: 4px double #3956a7;
    }
    &:active {
      background: #19274c;
    }
  }
`;

export function LoginForm({
  federatedSignIn = Auth.signInWithRedirect,
  showErrorMessage = toastr.error,
}) {
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const {
    data: settings,
    isLoading: isLoadingSettings,
    error: settingsError,
  } = useQuery(['getLandingSettings'], () => getBrandMeta(), {
    retry: false,
    // The auth config is not bound to change, so we don't need to refetch.
    refetchOnWindowFocus: false,
  });

  const logIn = async () => {
    try {
      setIsLoggingIn(true);
      await federatedSignIn();
      setIsLoggingIn(false);
    } catch (error) {
      showErrorMessage(error.toString());
      setIsLoggingIn(false);
    }
  };

  return (
    <PageContainer id="t-loginPanel">
      {!isLoadingSettings && !settingsError ? (
        <>
          <LoginContainer>
            {settings.data.logo ? (
              <LogoContainer>
                <img src={settings.data.logo} alt="Research Manager Logo" />
              </LogoContainer>
            ) : (
              <LogoContainer>
                <EvidationLogo color="#FFF" width="100%" height="40" />
              </LogoContainer>
            )}
            <>
              <Message>{settings.data.welcome_text}</Message>
              {isLoggingIn ? (
                <LoadingContainer>
                  <LoadingIndicator />
                </LoadingContainer>
              ) : (
                <Buttons>
                  <button onClick={logIn}>
                    {settings.data.login_button_text}
                  </button>
                </Buttons>
              )}
            </>
          </LoginContainer>
        </>
      ) : settingsError ? (
        <Message>Error: Unable to fetch Manager Settings</Message>
      ) : (
        <LoadingContainer>
          <LoadingIndicator />
        </LoadingContainer>
      )}
    </PageContainer>
  );
}

export default LoginForm;
