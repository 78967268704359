import _ from 'lodash';
import { apiGraph } from '../api';
import { toastr } from 'react-redux-toastr';
import types from '../constants';
import { logError } from '../utils/errorLogger';
import { AnyAction, Dispatch } from 'redux';
import { GetState } from '../redux/actions-types';

export const setPEUserDataEvents = (user_data_events: Array<any>) => ({
  type: types.pe_user_data_events.set,
  payload: user_data_events,
});

export const clearPEUserDataEvents = () => ({
  type: types.pe_user_data_events.clear,
});

export const loadPEUserDataEvents =
  (id: string) => (dispatch: Dispatch<AnyAction>, getState: GetState) => {
    const { graph, pe_user_data_events } = getState();
    if (!_.isEmpty(pe_user_data_events)) return;

    if (!id) {
      id = graph.id;
    }

    apiGraph
      .event_identifiers(id)
      .then(({ data }) => data.map((row: any) => row))
      .then((results) => {
        const user_data_events = results.filter(
          (event: any) =>
            !event.value.match(/-/) && !event.value.match(/_paid/),
        );
        dispatch(setPEUserDataEvents(user_data_events));
      })
      .catch((err) => {
        logError(err);
        const message =
          'There was an error fetching Contribution Events via the API.';
        console.error(message, err);
        toastr.error(message, '');
      });
  };
