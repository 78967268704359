import Settings from './settings';
import incoming from './incoming';
import outgoing from './outgoing';
import LogicSummary from 'src/features/logic/summary';

export default {
  form: {
    Components: () => [],
    Logic: (tile, graph_id) => ({
      Component: <LogicSummary graphId={graph_id} />,
    }),
    Settings,
  },
  incoming,
  outgoing,
};
