import { createDefaultLogger } from '@evidation/logger';

let datadogInitOpts: any = undefined;

if (
  window.env.DATADOG_CLIENT_TOKEN &&
  window.env.DATADOG_APPLICATION_ID &&
  window.env.DATADOG_ENV
) {
  datadogInitOpts = {
    // RUM could be enabled in study manager here there is already a project setup.
    enableRUM: false,
    env: window.env.DATADOG_ENV,
    clientToken: window.env.DATADOG_CLIENT_TOKEN,
    // SET ENV VAR & Uncomment if using RUM in study manager.
    //applicationId: window.env.DATADOG_APPLICATION_ID,
    service: 'study-manager',
    version: process.env.REACT_APP_STUDY_MANAGER_REVISION,
  };
}

export const createStudyManagerLogger = () =>
  createDefaultLogger({
    middlewares: [],
    datadogInitOpts,
  });

const studyManagerLogger = createStudyManagerLogger();

window.logger = studyManagerLogger;

export default studyManagerLogger;

/**
 * Exported for convenience
 */
export const logError = (message: unknown) => {
  studyManagerLogger.error(message);
};
