import React from 'react';

export default (props) => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      style={{ margin: 10 }}
      role="graphics-symbol"
      {...props}
    >
      <g
        id="Symbols:-Icons"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <g id="icons/tile-types/black/branches">
          <g
            id="Group"
            transform="translate(3.000000, 3.000000)"
            stroke="#ffffff"
            strokeWidth={2}
          >
            <circle id="Oval-2" cx={9} cy={3} r={3} />
            <circle id="Oval-2" cx={3} cy="14.14" r={3} />
            <circle id="Oval-2" cx="14.2857143" cy="13.9959997" r={3} />
            <path
              d="M3.96666667,10.6333333 L6.83608782,5.85067094"
              id="Line"
              strokeLinecap="square"
            />
            <path
              d="M10.6333333,10.8548893 L13.1451205,5.87666667"
              id="Line"
              strokeLinecap="square"
              transform="translate(11.889227, 8.365778) scale(1, -1) translate(-11.889227, -8.365778) "
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
